import {api} from "../api/callApi";
import {urlServer} from "../api/v1";
import {IPoint, IRoute} from "../varibles/interface";

const urlBase = urlServer + '/router';
const urlEdit = urlBase + '/setting';

interface IGetAutoRoute {
    departure: {
        position: string,
        port: any
    },
    "arrival": {
        position: string,
        port: any
    },
    "mode": string | "Fast"
    request_id: string
    connection_id: string
}

interface IEditRoute {
    router_id?: string
    source_id: string
    destination_id: string
    default: boolean
    type: string
    points: IPoint[]
}

export const RouteService = {
    gets: () => api.get<IRoute[]>(urlBase + '/plan'),
    getByLocation: (source_id: string, destination_id: string) => api.post<IRoute[]>(urlBase, {
        source_id,
        destination_id
    }),
    create: (body: IEditRoute) => api.post<IRoute>(urlEdit, body),
    update: (body: IEditRoute) => api.put<IRoute>(urlEdit, body),
    setDefault: (router_id: string) => api.put<IRoute>(urlBase, {router_id}),
    delete: (router_id: string) => api.delete<{ default_id?: string }>(urlEdit, {router_id}),
    getLocations: () => api.get(urlBase + "/location"),
    callAutoRoute: (body: IGetAutoRoute) => api.post(urlBase + '/auto', body),
}