import React, {useCallback, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {handleNext, IOption, options, updatePositionMenu, WEATHER_EL} from "../../util";
import {renderLabel} from "./Method";
import {TAddEl, toggleMenu} from "./index";
import {getElementByRole} from "../../constants";

interface IProps {
    role: WEATHER_EL

    selected?: number

    addEl: TAddEl

    openEditMode?: Function
}

const Menu: React.FC<IProps> = (props) => {
    const {addEl, openEditMode} = props;
    const [selected, setSelected] = useState<number>(props.selected || 0);
    const [role, setRole] = useState(props.role);
    const [opts, setOpts] = useState<IOption[]>([]);

    const selectItem = useCallback((e: React.MouseEvent | KeyboardEvent, currentRole: WEATHER_EL, opt: IOption) => {
        e.stopPropagation();
        e.preventDefault();
        if (openEditMode)
            openEditMode();

        const {value, label, unit} = opt;
        const el = getElementByRole(currentRole);
        if (el) {
            el.setAttribute('data-value', value);
            el.textContent = renderLabel(currentRole, label);
            const {nextRole, nextEl} = handleNext[currentRole](el, {addEl, unit});
            setRole(nextRole)
            if (options[nextRole]) {
                updatePositionMenu(nextEl);
            } else {
                toggleMenu()
            }
        }
    }, [openEditMode, addEl]);

    useEffect(() => {
        const opts = options[role] || [];
        setOpts(opts);

        const onKeyDown = (e: KeyboardEvent) => {
            const flag = new Set(['ArrowUp', 'ArrowDown', 'Enter'])

            if (flag.has(e.key)) {
                e.stopPropagation();
                e.preventDefault()
            }
        };
        const onKeyUp = (e: KeyboardEvent) => {
            let keyCaught = false;
            switch (e.key) {
                case 'ArrowDown':
                    setSelected(prev => Math.min(prev + 1, opts.length - 1))
                    keyCaught = true
                    break
                case 'ArrowUp':
                    setSelected(prev => Math.max(prev - 1, 0))
                    keyCaught = true
                    break
                case 'Enter':
                    setSelected(prev => {
                        selectItem(e, role, opts[prev]);
                        return prev;
                    });
                    break
                case 'Backspace':

                    break
            }

            if (keyCaught) {
                e.stopPropagation();
                e.preventDefault()
            }
        }
        document.addEventListener('keydown', onKeyDown)
        document.addEventListener('keyup', onKeyUp)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
            document.removeEventListener('keyup', onKeyUp)
        }
    }, [role, selectItem]);

    return <>
        {opts.map((item: IOption, i: number) => <div
            key={item.value}
            className={styles.option}
            onMouseDown={(e) => selectItem(e, role, item)}
            data-selected={i === selected}
        >
            {item.label}
        </div>)}
    </>;
};

export default Menu;
