import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import {Select} from "antd";
import {filterOption, renderOpts} from "../../../util/varibles/global";
import RouteTable from "../Component/RouteTable";
import {ILocation} from "../../../util/varibles/interface";
import {routeActions} from "../reducer";
import {RouteMode} from "../constatns";
import {AppState} from "../../../util/store/store";
import {colors} from "../../../contexts/WorldMapContext/Polyline";

const mapStateToProps = (state: AppState) => ({
    params: state.route.params,
    routes: state.route.routes,
    locations: state.route.locations,
    loading: state.route.loadingLocations
});

interface IProps {
    params: any
    routes: any
    locations: ILocation[]
    loading: boolean

    changeMode(payload: { mode: RouteMode, route: any }): void

    updateParams(payload: any): void

    initialData(payload: any): void
}

class RouteView extends Component<IProps> {

    handleCreate = () => {
        const {params, routes} = this.props;
        const {source, destination} = params;
        const values = {
            id: '-',
            name: 'New route',
            color: colors[routes.length % 10],
            points: [
                {...source.position, name: source.name},
                {...destination.position, name: destination.name}
            ],
            index: routes.length,
            default: Object.keys(routes).length === 0,
        }
        this.props.changeMode({mode: RouteMode.EDIT, route: values});
    }

    handleChange = (key: any, value: any) => {
        const {params} = this.props;
        const data = {...params || {}, [key]: value};
        const {source_id = null, destination_id = null} = data;
        if (source_id && destination_id) {
            this.props.initialData({source_id, destination_id})
        } else {
            const {locations} = this.props;
            if (key === 'source_id')
                data.source = locations.find(item => item.id === value);
            else
                data.destination = locations.find(item => item.id === value);

            this.props.updateParams(data);
        }
    }

    render() {
        const {locations, params, loading} = this.props;
        const {source = {id: null}, destination = {id: null}} = params;
        const {source_id = null, destination_id = null} = params || {};
        const valueA = source_id && source_id === source.id ? source_id : null;
        const valueB = destination_id && destination_id === destination.id ? destination_id : null;
        const options = renderOpts(locations);
        return <>
            <div className={styles.title}>Route</div>
            <div className={styles.body}>
                <div className={styles.description}>
                    Select Point A, Point B to show route
                </div>
                <div className={styles['container-select']}>
                    <label htmlFor="site">Point A</label>
                    <Select {...{
                        className: styles.select,
                        showSearch: true,
                        loading,
                        value: valueA,
                        placeholder: 'Select location for starting point',
                        onChange: (value: any) => this.handleChange('source_id', value),
                        filterOption: filterOption,
                        options
                    }}/>

                </div>
                <div className={styles['container-select']}>
                    <label htmlFor="smolt-site">Point B</label>
                    <Select {...{
                        className: styles.select,
                        showSearch: true,
                        loading,
                        value: valueB,
                        placeholder: 'Select location for destination',
                        onChange: (value: any) => this.handleChange('destination_id', value),
                        filterOption: filterOption,
                        options
                    }}/>
                </div>
            </div>

            <div className={styles.footer}>
                <button {...{
                    className: 'bt-primary h-36',
                    disabled: !source_id || !destination_id,
                    onClick: this.handleCreate
                }}>
                    Create route
                </button>
            </div>
            <RouteTable/>
        </>;
    }
}

export default connect(mapStateToProps, {
    changeMode: routeActions.changeMode,
    updateParams: routeActions.updateParams,
    initialData: routeActions.initialDataRequest
})(RouteView);
