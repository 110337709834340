import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Empty, Select} from "antd";
import styles from './style.module.scss';
import {MAP_ELEMENT} from "../../../../contexts/WorldMapContext/constants";
import {dashboardActions} from "../../reducer";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {useMap} from "@vis.gl/react-google-maps";
import {selectDashboard, selectOneSetting} from "../../../../util/store/selectors";

const {Option, OptGroup} = Select;

interface IProps {
}

const SearchMarker: React.FC<IProps> = () => {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const map = useMap();
    const vessels = useSelector(selectDashboard.vessels)
    const sites = useSelector(selectDashboard.sites)
    const viewMode = useSelector(selectOneSetting(PreferenceSetting.VIEW_MODE))
    const dispatch = useDispatch();

    const onSearch = (searchText: string) => {
        const value = searchText.toLowerCase();
        const data = [
            {label: 'Vessels', source: vessels, type: MAP_ELEMENT.VESSEL},
            {label: 'Sites', source: sites, type: MAP_ELEMENT.SITE}
        ]
        const options: any = [];
        data.forEach(({label, source, type}) => {
            const opts = source.reduce((list: any, item: any) => {
                if (item.name.toLowerCase().includes(value))
                    list = [...list, {label: item.name, value: [item.id + '|' + type].join('|')}];
                return list;
            }, [])
            if (opts.length > 0)
                options.push({label, options: opts})
        })
        setValue('');
        setOptions(options);
    };

    const onChange = (value: any) => {
        if (value) {
            const [id, type] = value.split('|');
            const targetType = +type;
            if (targetType === MAP_ELEMENT.VESSEL) {
                const vessel = vessels.find((item: any) => item.id === id);
                if (vessel) {
                    dispatch(dashboardActions.showInfo({
                        target: {
                            vessel_id: vessel.id,
                            vessel,
                            operation: {},
                            isInterval: true,
                            targetType
                        }
                    }));
                    const {Latitude, Longitude} = vessel.gps;
                    map?.setCenter({lat: Latitude, lng: Longitude});
                    map?.setZoom(12)
                }
            } else {
                const site = sites.find((item: any) => item.id === id);
                if (site) {
                    dispatch(dashboardActions.showInfo({target: {data: site, targetType}}));
                    const {latitude, longitude} = site.position;
                    map?.setCenter({lat: latitude, lng: longitude});
                    map?.setZoom(12)
                }
            }
        } else {
            dispatch(dashboardActions.showInfo({}));
            setValue('');
            setOptions([]);
        }
    }

    const onBlur = () => {
        if (value.length === 0)
            setOptions([]);
    }

    if (viewMode !== VIEW_MODE.PLANNER)
        return null;

    return <Select {...{
        className: styles['search-box'],
        showSearch: true,
        suffixIcon: null,
        allowClear: true,
        filterOption: false,
        onSearch,
        onChange,
        onBlur,
        placeholder: "Search for vessel, site by name",
        notFoundContent: value ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : null
    }}>
        {options.map((group: any) => <OptGroup
            key={group.label}
            label={<span className='font-w5 font-s4'>{group.label}</span>}
        >
            {group.options.map((item: any) =>
                <Option key={item.value} value={item.value}>{item.label}</Option>)}
        </OptGroup>)}
    </Select>
};


export default SearchMarker
