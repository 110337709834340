import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {IOperation, IPropsChildren} from "../../../../util/varibles/interface";
import styles from "./style.module.scss";
import stylesContainer from "../../style.module.scss";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupOpNote from "../../../../components/Popup/PopupOpNote";
import {opDetailActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    data: state.operationDetail.data,
    loading: state.operationDetail.loading,
});

interface IProps extends IPropsChildren {
    isNote: boolean
    data: IOperation
    loading: any
}

const Operation: React.FC<IProps> = (props) => {
    const {data} = props;
    const dispatch = useDispatch();
    const openNode = () => {
        const {operation_code} = data.operation;
        const el = openPopup(<PopupOpNote {...{
            isShow: true,
            loading: false,
            info: {code: "#" + operation_code},
            operation: data.operation,
            note: '',
            handleSave: (note: string) => {
                dispatch(opDetailActions.updateOp([{...data, operation: {...data.operation, note}}]))
            },
            handleClose: () => el.remove()
        }}/>)
    };

    if (!data.operation) return null;

    return <div className={stylesContainer.section}>
        <div className={stylesContainer['header-box']}>
            <div className='d-flex align-items-center justify-content-between'>
                <div>Operation info.</div>
                {props.isNote && <div className={styles.action} onClick={openNode}>Add note</div>}
            </div>
        </div>
        {props.children}
    </div>;
};

export default connect(mapStateToProps, {})(Operation);
