import React, {useState} from 'react';
import stylesPage from "../style.module.scss";
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../components/Icon";
import {operationModes} from "../../../util/varibles/constants";
import {Radio} from 'antd';
import {updateDensitySettingFetch} from "../../../util/services/tenant";
import {showErrorResponse} from "../../../util/varibles/global";
import LoadingBox from "../../../components/LoadingBox";
import {useSelector} from "../../../util/store/store";
import {selectOpMode} from "../../../util/store/selectors";

const OperationMode: React.FC = () => {
    const operation_mode = useSelector(selectOpMode);
    const [loading, setLoading] = useState(false);
    const onChange = (e: any) => {
        if (loading)
            return;
        const {value} = e.target;
        setLoading(true);
        const body = {
            operation_mode: value
        }
        setLoading(true);
        new Promise(resolve => resolve(updateDensitySettingFetch(body)))
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Update failed', error);
            })
    }

    return <div className={stylesPage.section}>
        <div className={stylesPage.header}>
            <div className={[stylesPage.title, 'mt-10 pb-10'].join(' ')}>
                <Icon icon={ICON_NAME.CLOCK_2} size='large'/>
                <div>Showing operation when on plan calendar by</div>
            </div>
        </div>
        <Radio.Group className={styles.body} onChange={onChange} value={operation_mode}>
            {operationModes.map(item => <Radio key={item.id} value={item.id}>{item.name}</Radio>)}
            {loading && <div className={styles.loading}>
                <LoadingBox loading={true}/>
            </div>}
        </Radio.Group>
        <div className={stylesPage.subTitle}>
            By selecting this, when on plan calendar, operations will be shown by corresponding selected option.
        </div>
    </div>;
};

export default OperationMode
