import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ACTION_TYPE, propsModal, USER_ROLE, userRole} from "../../../util/varibles/constants";
import {Button, Drawer, Form, Input, Select} from "antd";
import styles from './style.module.scss';
import {connect} from "react-redux";
import {filterOption, showErrorResponse} from "../../../util/varibles/global";
import {IFactory, ISite} from "../../../util/varibles/interface";
import {ACTION} from "../../../util/varibles/permission";
import Header from "../../../components/Popup/Component/Header";
import {formValidation} from "../../../util/varibles/validation";
import {IUser} from "../../LoginPage/constants";
import {userActions} from "../reducer";
import {UserService} from "../../../util/services/user";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {FormInstance} from "antd/es/form";
import {AppState} from "../../../util/store/store";

const rulesOfForm = {
    email: [formValidation.require('Email')],
    tenant_id: [formValidation.require('Customer')],
    password: [formValidation.require('Password')],
    confirm_password: [
        formValidation.require('Confirm password'),
        formValidation.confirmPassword()
    ],
    regions: [formValidation.require('Regions')],
    roles: [formValidation.require('Roles')]
}

const renderOptions = (list: any) => {
    return list.map((item: any) => ({value: item.id, label: item.name})) || []
}

const renderOption = (item: any) => {
    return {value: item.id, label: item.name}
}

const getSitesAndFactories = (props: {
    sites: ISite[],
    factories: IFactory[]
}, currentTenantId: string, isSuperAdmin: boolean) => {
    const {sites, factories} = props;
    return {
        sites: isSuperAdmin
            ? sites.reduce((list: any, item: any) => item.tenant_id === currentTenantId ? [...list, renderOption(item)] : list, [])
            : renderOptions(sites),
        factories: isSuperAdmin
            ? factories.reduce((list: any, item: any) => item.tenant_id === currentTenantId ? [...list, renderOption(item)] : list, [])
            : renderOptions(factories)
    }
}

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    permission: state.login.user.permission,
    sites: state.user.sites,
    factories: state.user.factories,
    customers: state.user.customers,
    loadingSites: state.user.loadingSites,
    loadingFactories: state.user.loadingFactories,
});

interface IProps {
    visible?: boolean
    user?: IUser
    editMode: ACTION_TYPE
    permission: any
    userTenantId: string
    sites: ISite[]
    factories: IFactory[]
    customers: any[]
    loadingSites: boolean
    loadingFactories: boolean

    onClose?(): void

    addUser(payload: IUser): void

    updateUser(payload: IUser): void
}

const PopupEditUser: React.FC<IProps> = (props) => {
    const formRef = useRef<FormInstance>(null);
    const [visible, setVisible] = useState(props.visible);
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState({
        vessels: [],
        regions: [],
        sites: [],
        factories: [],
    });

    const {editMode, sites, factories, userTenantId, customers, user = {email: '', tenant_id: null}, onClose} = props
    const {[USER_ROLE.SUPER_ADMIN]: old, ...args} = userRole;
    const isSuperAdmin = props.permission[ACTION.TENANT.CREATE];
    const listOfRoles = isSuperAdmin ? userRole : args;
    const {tenant_id: itemTenantId} = user;

    const handleChangeTenant = useCallback((currentTenantId: any) => {
        let vessels = [], regions = [];
        const sitesFactories = getSitesAndFactories({sites, factories}, currentTenantId, isSuperAdmin);
        const customer = customers.find((item: any) => item.tenant_id === currentTenantId)

        if (customer) {
            vessels = renderOptions(customer.vessels || []);
            regions = renderOptions(customer.regions || []);
        }

        setSource({vessels, regions, ...sitesFactories});
    }, [customers, factories, isSuperAdmin, sites])

    useEffect(() => {
        if (!isSuperAdmin)
            handleChangeTenant(userTenantId);
        else if (editMode === ACTION_TYPE.EDIT) {
            handleChangeTenant(itemTenantId);
        }
    }, [visible, editMode, isSuperAdmin, userTenantId, itemTenantId, handleChangeTenant])

    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose()
    }

    const handleSubmit = (values: any) => {
        const {
            name = '-',
            profile = '-',
            vessels = [],
            regions = [],
            email,
            sites = [],
            factories = [],
            tenant_id,
            ...rest
        } = values

        const user = {
            ...rest,
            name,
            profile,
            email: (email || '').replace(' ', ''),
            vessels,
            regions,
            tenant_id: String(tenant_id),
            sites,
            factories
        }

        if (!isSuperAdmin) {
            user.tenant_id = props.userTenantId;
        }

        setLoading(true);
        if (editMode === ACTION_TYPE.EDIT) {
            const {user_id, username, email} = props.user || {};
            user.username = username;
            user.user_id = user_id;
            user.email = email;
            new Promise(resolve => resolve(UserService.update(user)))
                .then((rs: any) => {
                    setLoading(false);
                    notify.success(NotifyCode.S2)([user.email]);
                    props.updateUser(rs);
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Update failed', error);
                })
        } else {
            delete user.confirm;
            new Promise(resolve => resolve(UserService.create(user)))
                .then((rs: any) => {
                    setLoading(false);
                    notify.success(NotifyCode.S1)([user.email]);
                    props.updateUser(rs);
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Create failed', error);
                })
        }
    }

    return <Drawer
        {...propsModal}
        destroyOnClose
        title={<Header
            title={editMode === ACTION_TYPE.CREATE ? 'Add user' : `Update user ${user.email}`}
            onClose={handleClose}
        />}
        className={styles['popup-edit-user']}
        open={visible}
        width='30%'
        height='100%'
        placement='right'
        onClose={handleClose}
    >
        <Form ref={formRef} initialValues={user} onFinish={handleSubmit} layout='vertical'>
            {editMode === ACTION_TYPE.CREATE
                ? <>
                    <Form.Item
                        label={<span className='text-[15px]'>Email</span>}
                        name="email"
                        rules={rulesOfForm.email}
                    >
                        <Input className='input' placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        label={<span className='text-[15px]'>Password</span>}
                        name="password"
                        rules={rulesOfForm.password}
                    >
                        <Input.Password className='input' placeholder="Password" autoComplete='new-password'/>
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={rulesOfForm.confirm_password}
                    >
                        <Input.Password className='input' autoComplete='new-password' placeholder="Confirm Password"/>
                    </Form.Item>
                </>
                : <div className={styles['line-info']}>
                    <label>Email</label>
                    <div>{user.email}</div>
                </div>}

            {isSuperAdmin && <Form.Item
                label={<span className='text-[15px]'>Customer</span>}
                name="tenant_id"
                rules={rulesOfForm.tenant_id}
            >
                <Select
                    onChange={handleChangeTenant}
                    className='select-single'
                    placeholder='Customer'
                    filterOption={filterOption}
                    options={customers.map(item => ({value: item.tenant_id, label: item.name}))}
                />
            </Form.Item>}

            <Form.Item label={<span className='text-[15px]'>Vessels</span>} name="vessels"
            >
                <Select
                    className='select-mode-tag'
                    mode="multiple"
                    tokenSeparators={[',']}
                    placeholder='Vessels'
                    filterOption={filterOption}
                    options={source.vessels}
                />
            </Form.Item>
            <Form.Item
                label={<span className='text-[15px]'>Regions</span>}
                name="regions"
                rules={rulesOfForm.regions}
            >
                <Select
                    className='select-mode-tag'
                    mode="multiple"
                    tokenSeparators={[',']}
                    placeholder='Regions'
                    filterOption={filterOption}
                    options={source.regions}
                />
            </Form.Item>
            <Form.Item
                label={<span className='text-[15px]'>Roles</span>}
                name="roles"
                rules={rulesOfForm.roles}
            >
                <Select
                    className='select-mode-tag'
                    mode="multiple"
                    tokenSeparators={[',']}
                    placeholder='Roles'
                    filterOption={filterOption}
                    options={Object.keys(listOfRoles).map(key => ({value: key, label: listOfRoles[key].name}))}
                />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev: any, current: any) =>
                JSON.stringify(prev.roles) !== JSON.stringify(current.roles)}>
                {({getFieldValue}) => {
                    const roles = getFieldValue('roles') || [];
                    const isSite = roles.includes(USER_ROLE.SITE_MANAGER);
                    const isFactory = roles.includes(USER_ROLE.FACTORY_MANAGER);

                    return <>
                        {isSite && <Form.Item
                            label={<span className='text-[15px]'>Sites</span>}
                            name='sites'
                        >
                            <Select
                                className='select-mode-tag'
                                mode="multiple"
                                tokenSeparators={[',']}
                                placeholder='Sites'
                                filterOption={filterOption}
                                loading={props.loadingSites}
                                options={source.sites}
                            />
                        </Form.Item>}
                        {isFactory && <Form.Item
                            label={<span className='text-[15px]'>Factories</span>}
                            name='factories'
                        >
                            <Select
                                className='select-mode-tag'
                                mode="multiple"
                                tokenSeparators={[',']}
                                placeholder='Factories'
                                filterOption={filterOption}
                                loading={props.loadingFactories}
                                options={source.factories}
                            />
                        </Form.Item>}
                    </>
                }}
            </Form.Item>
            <div className={styles.footer}>
                <Button className='bt-default' onClick={handleClose}>Cancel</Button>
                <Button loading={loading} htmlType='submit' className='bt-primary'>Save</Button>
            </div>
        </Form>
    </Drawer>;
};

export default connect(mapStateToProps, {
    addUser: userActions.addUser,
    updateUser: userActions.updateUser
})(PopupEditUser);
