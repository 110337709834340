import React, {useEffect, useRef, useState} from 'react';
import * as echarts from 'echarts';
import {EChartsType} from 'echarts';
import {colorOfYAxis} from "../constants";
import {ECBasicOption} from "echarts/types/dist/shared";

interface IProps {
    className?: string

    data: {
        count: number
        line: any
        area: any
        yLine: any
        yArea: any
    }

    ops: any
    rowMax: number
}

const Charts: React.FC<IProps> = (props) => {
    const lineRef = useRef<HTMLDivElement>(null);
    const areaRef = useRef<HTMLDivElement>(null);
    const opsRef = useRef<HTMLDivElement>(null);
    const lineChart = useRef<EChartsType>();
    const areaChart = useRef<EChartsType>();
    // const [lineChart, setLineChart] = useState<EChartsType>()
    // const [areaChart, setAreaChart] = useState<EChartsType>()
    const [xAxis, setXAxis] = useState<any>({})
    const {data: {count, ...data}, ops, rowMax} = props;

    useEffect(() => {
        setXAxis({
            axisTick: {
                show: false
            },
            type: 'category',
            data: Array.from({length: count}, (_, i) => `W${i + 1}`),
        })
    }, [count]);

    useEffect(() => {
        if (lineRef.current) {
            const existInstance = echarts.getInstanceByDom(lineRef.current)
            if (existInstance) echarts.dispose(lineRef.current)
            lineChart.current = echarts.init(lineRef.current)
        }
    }, [lineRef]);

    useEffect(() => {
        if (areaRef.current) {
            const existInstance = echarts.getInstanceByDom(areaRef.current)
            if (existInstance) echarts.dispose(areaRef.current)
            areaChart.current = echarts.init(areaRef.current)
        }
    }, [areaRef])

    useEffect(() => {
        if (opsRef.current) {
            const existInstance = echarts.getInstanceByDom(opsRef.current)
            if (existInstance) echarts.dispose(opsRef.current)
            const opsChart = echarts.init(opsRef.current);
            const option: ECBasicOption = {
                tooltip: {
                    trigger: 'item',
                    position: 'top',
                },
                grid: {
                    top: 0,
                    right: '20px',
                    bottom: '5px'
                },
                xAxis: {
                    ...xAxis,
                    show: false,
                },
                yAxis: {
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    name: '',
                },
                series: ops
            };
            opsChart.setOption(option, true);
        }
    }, [opsRef, ops, xAxis]);

    useEffect(() => {
        const {line, area, yLine, yArea} = data;
        if (!!lineChart) {
            const isOne = area.length === 0;
            let i = 0;
            const option: ECBasicOption = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    top: '5px',
                    right: '20px',
                    bottom: '30px'
                },
                xAxis: {
                    ...xAxis,
                    axisLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                },
                yAxis: Object.keys(yLine).length > 0 ? Object.keys(yLine).map((key) => {
                    const value = {
                        type: 'value',
                        name: isOne ? key : '',
                        position: 'left',
                        offset: colorOfYAxis[key] ? (50 * i) : 0,
                        alignTicks: true,
                        axisLabel: {
                            formatter: (value: number) => Math.round(value * 1000) / 1000
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: colorOfYAxis[key]
                            }
                        }
                    };
                    if (colorOfYAxis[key])
                        i++;
                    return value
                }) : {type: 'value'},
                series: line
            };
            lineChart.current?.setOption(option, true);
        }
        if (!!areaChart) {
            let i = 0;
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            width: 0
                        }
                    }
                },
                grid: {
                    height: Object.keys(yLine).length * 18,
                    right: '20px',
                    bottom: '0',
                },
                xAxis: {
                    ...xAxis,
                    show: false,
                },
                yAxis: [
                    {
                        lineHeight: 30,
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        data: Object.keys(yArea).map(key => key),
                    },
                    ...Object.keys(yLine).map((key) => {
                        const value = {
                            type: 'value',
                            name: key,
                            position: 'left',
                            offset: colorOfYAxis[key] ? (50 * i) : 0,
                            alignTicks: true,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: colorOfYAxis[key]
                                }
                            },
                        }
                        if (colorOfYAxis[key])
                            i++;
                        return value
                    })
                ],
                series: area
            };
            areaChart.current?.setOption(option, true);
        }
    }, [data, xAxis]);

    return <>
        <div ref={areaRef} style={{height: '120px', width: '100%', marginBottom: '-5px'}}/>
        <div
            ref={opsRef}
            style={{height: `${rowMax * 24}px`, width: '100%'}}
        />
        <div ref={lineRef} className={props.className}/>
    </>;
};

export default Charts
//
// const Charts: React.FC<IProps> = (props) => {
//     const lineRef = useRef<HTMLDivElement>(null);
//     const areaRef = useRef<HTMLDivElement>(null);
//     const opsRef = useRef<HTMLDivElement>(null);
//     const lineChart = useRef<EChartsType>();
//     const areaChart = useRef<EChartsType>();
//     const opsChart = useRef<EChartsType>();
//     const [xAxis, setXAxis] = useState<any>({})
//     const {data: {count, ...data}, ops, rowMax} = props;
//
//     useEffect(() => {
//         setXAxis({
//             axisTick: {
//                 show: false
//             },
//             type: 'category',
//             data: Array.from({length: count}, (_, i) => `W${i + 1}`),
//         })
//     }, [count]);
//
//     useEffect(() => {
//         if (lineRef.current) {
//             const existInstance = echarts.getInstanceByDom(lineRef.current)
//             if (existInstance) echarts.dispose(lineRef.current)
//             lineChart.current = echarts.init(lineRef.current);
//         }
//     }, [lineRef]);
//
//     useEffect(() => {
//         if (areaRef.current) {
//             const existInstance = echarts.getInstanceByDom(areaRef.current)
//             if (existInstance) echarts.dispose(areaRef.current)
//             areaChart.current = echarts.init(areaRef.current);
//         }
//     }, [areaRef])
//
//     useEffect(() => {
//         if (opsRef.current) {
//             const existInstance = echarts.getInstanceByDom(opsRef.current)
//             if (existInstance) echarts.dispose(opsRef.current)
//             opsChart.current = echarts.init(opsRef.current);
//             const option: ECBasicOption = {
//                 tooltip: {
//                     trigger: 'item',
//                     position: 'top',
//                 },
//                 grid: {
//                     top: 0,
//                     right: '20px',
//                     bottom: '5px'
//                 },
//                 xAxis: {
//                     show: false,
//                 },
//                 yAxis: {
//                     axisLabel: {
//                         show: false
//                     },
//                     axisTick: {
//                         show: false
//                     },
//                     type: 'category',
//                     axisLine: {
//                         show: false,
//                     },
//                     name: '',
//                 },
//                 series: ops
//             };
//             opsChart.current.setOption(option, true);
//         }
//     }, [opsRef, ops]);
//
//     useEffect(() => {
//         opsChart.current?.setOption({xAxis: {...xAxis, show: false}}, true);
//         lineChart.current?.setOption({xAxis: {...xAxis, axisLine: {lineStyle: {type: 'dashed'}}}}, true);
//         areaChart.current?.setOption({xAxis: {...xAxis, show: false}}, true);
//     }, [xAxis]);
//
//     useEffect(() => {
//         const {line, area, yLine, yArea} = data;
//         if (!!lineChart) {
//             const isOne = area.length === 0;
//             let i = 0;
//             const option: ECBasicOption = {
//                 tooltip: {
//                     trigger: 'axis',
//                 },
//                 grid: {
//                     top: '5px',
//                     right: '20px',
//                     bottom: '30px'
//                 },
//                 xAxis: {
//                     axisLine: {
//                         lineStyle: {
//                             type: 'dashed'
//                         }
//                     },
//                 },
//                 yAxis: Object.keys(yLine).length > 0 ? Object.keys(yLine).map((key) => {
//                     const value = {
//                         type: 'value',
//                         name: isOne ? key : '',
//                         position: 'left',
//                         offset: colorOfYAxis[key] ? (50 * i) : 0,
//                         alignTicks: true,
//                         axisLabel: {
//                             formatter: (value: number) => Math.round(value * 1000) / 1000
//                         },
//                         axisLine: {
//                             show: false,
//                             lineStyle: {
//                                 color: colorOfYAxis[key]
//                             }
//                         }
//                     };
//                     if (colorOfYAxis[key])
//                         i++;
//                     return value
//                 }) : {type: 'value'},
//                 series: line
//             };
//             lineChart.current?.setOption(option, true);
//         }
//         if (!!areaChart) {
//             let i = 0;
//             const option = {
//                 tooltip: {
//                     trigger: 'axis',
//                     axisPointer: {
//                         lineStyle: {
//                             width: 0
//                         }
//                     }
//                 },
//                 grid: {
//                     height: Object.keys(yLine).length * 18,
//                     right: '20px',
//                     bottom: '0',
//                 },
//                 xAxis: {
//                     show: false,
//                 },
//                 yAxis: [
//                     {
//                         lineHeight: 30,
//                         axisLabel: {
//                             show: false
//                         },
//                         axisTick: {
//                             show: false
//                         },
//                         type: 'category',
//                         axisLine: {
//                             show: false,
//                         },
//                         data: Object.keys(yArea).map(key => key),
//                     },
//                     ...Object.keys(yLine).map((key) => {
//                         const value = {
//                             type: 'value',
//                             name: key,
//                             position: 'left',
//                             offset: colorOfYAxis[key] ? (50 * i) : 0,
//                             alignTicks: true,
//                             axisLine: {
//                                 show: false,
//                                 lineStyle: {
//                                     color: colorOfYAxis[key]
//                                 }
//                             },
//                         }
//                         if (colorOfYAxis[key])
//                             i++;
//                         return value
//                     })
//                 ],
//                 series: area
//             };
//             areaChart.current?.setOption(option, true);
//         }
//     }, [data, xAxis]);
//
//     return <>
//         <div ref={areaRef} style={{height: '120px', width: '100%', marginBottom: '-5px'}}/>
//         <div
//             ref={opsRef}
//             style={{height: `${rowMax * 24}px`, width: '100%'}}
//         />
//         <div ref={lineRef} className={props.className}/>
//     </>;
// };
//
// export default Charts;