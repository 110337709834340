import {
    DashboardSetting,
    GlobalSetting,
    OverviewSetting,
    PlanSetting,
    PreferenceSetting,
    RouteSetting
} from "./userSetting";
import {initAutoPlanConfig, KEY_TAB, PriorityTime, THEME_COLOR} from "./constants";
import {VIEW_MODE} from "./permission";
import {MAP_STYLE} from "../../contexts/WorldMapContext/constants";

const defaultSetting = {
    [DashboardSetting.SECTION_FILTER]: ['1'],
    [DashboardSetting.VESSELS]: [],
    [DashboardSetting.ACTIVE_SITE]: false,
    [DashboardSetting.FACTORIES]: true,
    [DashboardSetting.PRODUCT_AREA]: true,
    [DashboardSetting.PRODUCT_AREAS]: [],
    [DashboardSetting.DISEASED_ZONE]: {},

    [PlanSetting.BIOLOGY_TAB]: KEY_TAB.PRODUCTION,
    [PlanSetting.VESSEL_FILTER]: [],

    [PreferenceSetting.SHOW_CERTAINTY]: true,
    [PreferenceSetting.AUTO_PLAN]: initAutoPlanConfig,
    [PreferenceSetting.CUSTOMIZE_HARVEST]: [],
    [PreferenceSetting.VIEW_MODE]: VIEW_MODE.PLANNER,
    [PreferenceSetting.PRIORITY_TIME]: PriorityTime.FACTORY,
    [PreferenceSetting.SITES_TIME]: {},
    [PreferenceSetting.FACTORIES_TIME]: {},
    [PreferenceSetting.VIEW_MODE]: VIEW_MODE.PLANNER,
    [PreferenceSetting.THEME_COLOR]: THEME_COLOR.LIGHT,

    [OverviewSetting.VESSEL_FILTER]: [],
    [OverviewSetting.WEEK_PER_PAGE]: 4,

    [RouteSetting.AUTO_ROUTE]: {
        mode: 'Exact',
        safe_depth: 10,
        airDraft: 10
    },
    [GlobalSetting.WEATHER]: [],
    [GlobalSetting.MAP_STYLE]: MAP_STYLE.AUBERGINE,
    [GlobalSetting.MAIL]: {},
}

export const getSetting = (setting: any, key: string) => {
    const {[key]: value = defaultSetting[key]} = setting || {};
    return value;
}

export const getMultiSetting = (setting: any, keys: string[]) => {
    return keys.reduce((rs: any, key) => {
        rs[key] = getSetting(setting, key);
        return rs;
    }, {})
}

export default defaultSetting