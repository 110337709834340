import React from 'react';
import {useDispatch} from 'react-redux';
import styles from './style.module.scss';
import {MESSAGE_TYPE} from "../../../../../pages/MessagePage/constants";
import MessageTime from "./MessageTime";
import {messageActions} from "../../../../../pages/MessagePage/reducer";
import {MESSAGE_STATUS} from "../../../../../util/varibles/constants";
import Menu from "../../../../Menu";
import {selectUsername} from "../../../../../util/store/selectors";
import {useSelector} from "../../../../../util/store/store";

interface IProps {
    data: any
    avatar: any
    isSelected?: boolean
    title: any

    onClick?(): void
}

const Message: React.FC<IProps> = ({data, avatar, title, isSelected = false, onClick}) => {
    const {status = 'read'} = data || {}
    const {timestamp = '-'} = data.message || {};
    const currentUser = useSelector(selectUsername);
    const menu = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ]

    const dispatch = useDispatch();

    const renderSubtitle = () => {
        if (!data.message)
            return '';

        const {username, is_edited, message, message_type} = data.message;
        const isOwn = username === currentUser;
        const subTitle = [];
        if (isOwn) {
            subTitle.push('You')
        } else {
            const [nameText] = username.split('@');
            subTitle.push(nameText)
        }

        switch (message_type) {
            case MESSAGE_TYPE.ADD: {
                const {members} = data.message;
                const userB = members.map((item: any) => {
                    const [name] = (item.username || '').split('@');
                    return name === currentUser ? 'you' : name
                })
                subTitle.push(' added ' + userB + ' to the group');
                break;
            }
            case MESSAGE_TYPE.DELETE: {
                const {members} = data.message;
                const userB = members.map((item: any) => {
                    const [name] = (item.username || '').split('@');
                    return name === currentUser ? 'you' : name
                })
                subTitle.push(' removed ' + userB + ' from the group');
                break
            }
            case MESSAGE_TYPE.LEAVE: {
                subTitle.push(' left the group');
                break;
            }
            default: {
                if (is_edited)
                    subTitle.push(' edited: ');
                else
                    subTitle.push(': ');
                subTitle.push(message);
            }
        }

        return subTitle.join('');
    }

    const handleClick = () => {
        const {status} = data;
        if (status === MESSAGE_STATUS.NEW) {
            dispatch(messageActions.readMessageRequest(data))
        }
        if (onClick)
            onClick();
    }

    return <Menu items={menu} trigger={['contextMenu']}>
        <div
            className={styles['wrapper']}
            data-is-new={status === MESSAGE_STATUS.NEW}
            data-selected={isSelected}
            onClick={handleClick}
        >
            <div className={styles.avatar}>
                {avatar}
            </div>
            <div className={styles.description}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles['sub-title']}>
                    <span>{renderSubtitle()}</span>
                    <MessageTime timestamp={timestamp}/>
                </div>
            </div>
        </div>
    </Menu>
};

export default Message;

