import React, {useCallback, useState} from 'react';
import {NOTE_TYPE, propsConfirm, propsEmpty} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import {INoteCalender} from "../../../../util/varibles/interface";
import WrapperPopup, {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import {Input, notification, Popconfirm} from 'antd';
import {checkLimit, showErrorResponse} from "../../../../util/varibles/global";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {UserService} from "../../../../util/services/user";
import {datetime} from "../../../../util/library/datetime";
import Footer from "../../../../components/Popup/Component/Footer";

const {TextArea} = Input;

interface IOpenNoteCalendar {
    notes?: INoteCalender[]
    level: NOTE_TYPE
    time: number

    onSave(notes: INoteCalender[]): void

    onDelete(): void
}

export const openNoteCalendar = (props: IOpenNoteCalendar) => {
    const el = openPopup(<PopupNote {...props} visible={true} onClose={() => el.remove()}/>);
}

interface IProps {
    notes?: INoteCalender[]
    level: NOTE_TYPE
    time: number

    visible?: boolean

    onSave(notes: INoteCalender[]): void

    onDelete(): void

    onClose?(): void
}

const PopupNote: React.FC<IProps> = (props) => {
    const [notes, setNotes] = useState(props.notes || []);
    const [visible, setVisible] = useState(props.visible);
    const [loading, setLoading] = useState(false);
    const {time, level, onSave, onDelete, onClose} = props;

    const {id = undefined, note = '', timestamp = 0} = notes[0] || {};

    const handleChange = useCallback((e: any, index = 0) => {
        const {value} = e.target;
        setNotes([{...notes[index] || {}, note: value}]);
    }, [notes]);


    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            onClose()
    }, [onClose]);

    const handleSave = useCallback(() => {
        setLoading(true);
        new Promise(resolve => resolve(id
            ? UserService.updateNote({
                id,
                note,
                timestamp
            })
            : UserService.createNote({
                note,
                timestamp: time,
                level
            })))
            .then((rs: any) => {
                setLoading(false);
                onSave([rs]);
                handleClose();
                notification.success({message: 'Update note successful'})
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Update note failed', error)
            })
    }, [time, level, onSave, handleClose]);

    const handleDelete = useCallback(() => {
        setLoading(true);
        UserService.deleteNote({id: id || '', timestamp})
            .then(() => {
                setLoading(false);
                onDelete();
                handleClose();
                notification.success({message: 'Delete note successful'})
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Delete note failed', error)
            })
    }, [onDelete, handleClose]);

    const rows = checkLimit(3, undefined, (note.match(/\n/g) || []).length + 1);

    return <WrapperPopup
        radius={'4px'}
        visible={visible}
        title={<div className={styles.header}>
            <div className={styles.title}>
                <span className='font-w4'>Note</span>
                <span> &#8212; </span>
                <span className='font-w3'>
                    {datetime(props.time).format(props.level === NOTE_TYPE.DAY ? 'dddd MMMM D' : '[Week] WW')}
                </span>
            </div>
            {id && <Popconfirm
                {...propsConfirm}
                placement="topLeft"
                title='Are you sure to delete this note?'
                disabled={loading}
                onConfirm={handleDelete}
            >
                <div className={styles.delete}>
                    <Icon icon={ICON_NAME.DELETE} danger/>
                </div>
            </Popconfirm>}
        </div>}
        onClose={handleClose}
    >
        <div className={styles.note}>
            <TextArea
                {...propsEmpty}
                value={note}
                rows={rows}
                showCount
                maxLength={500}
                onChange={handleChange}
            />
        </div>
        <Footer
            cancel={{click: handleClose}}
            ok={{click: handleSave}}
        />
    </WrapperPopup>;
};

export default PopupNote;
