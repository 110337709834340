import React from 'react';
import {connect} from 'react-redux';
import {ISite} from "../../../../../util/varibles/interface";
import {loginActions} from "../../../../LoginPage/reducer";
import SiteFilter from "../../_Component/SiteFilter";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {
        smoltFilter = [],
        smoltProdAreaFilter = [],
    } = state.login.user.setting || {};

    return {
        siteFilter: smoltFilter,
        prodAreaFilter: smoltProdAreaFilter,
    }
};

export interface IProps {
    siteFilter: string[]
    prodAreaFilter: string[]
    sites: ISite[]

    updateUserSetting(payload: any[]): void
}


const Filter: React.FC<IProps> = (props) => {
    const updateSetting = ({siteFilter, prodAreaFilter}: { siteFilter: string[], prodAreaFilter: string[] }) => {
        props.updateUserSetting([{smoltFilter: siteFilter, smoltProdAreaFilter: prodAreaFilter}]);
    }

    return <SiteFilter
        siteFilter={props.siteFilter}
        prodAreaFilter={props.prodAreaFilter}
        sites={props.sites}
        updateSetting={updateSetting}
    />
};

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting
})(Filter);
