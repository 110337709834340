import React from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss'
import {ACTION_TYPE} from "../../../util/varibles/constants";
import Menu from "../../../components/Menu";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {CopyOutlined} from "@ant-design/icons";
import {IChecklist} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "../PopupEdit";
import {checklistActions} from "../reducer";
import {deleteChecklistFetch} from "../../../util/services/checklist";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";

interface IProps extends IChecklist {
    update(payload: IChecklist): void

    add(payload: IChecklist): void

    remove(payload: string): void

    loading: boolean

    setLoading(loading: boolean): void
}


const MenuChecklist: React.FC<IProps> = (props) => {
    const {add, update, remove, loading, setLoading, ...checklist} = props;
    const handleEdit = () => {
        if (loading)
            return;
        const el = openPopup(<PopupEdit
            editMode={ACTION_TYPE.EDIT}
            checklist={checklist}
            onSave={update}
            onClose={() => el.remove()}
        />)
    };

    const handleDelete = () => {
        if (loading)
            return;

        const {id} = checklist;
        setLoading(true);
        new Promise(resolve => resolve(deleteChecklistFetch(id)))
            .then(() => {
                notify.success(NotifyCode.S4)(['The checklist']);
                remove(id)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                showErrorResponse('Delete failed', error);
            })
    }

    const handleDuplicate = () => {
        const {content} = props;
        const checklist = {id: '', tags: [], content, user_id: '', vessel_id: '-', operation_type: -1, step: -1}
        add(checklist);
    }

    const menu = [
        {
            key: 'edit',
            label: <div className='menu-line' onClick={handleEdit}>
                <Icon icon={ICON_NAME.EDIT}/>
                <span>Edit</span>
            </div>
        },
        {
            key: 'duplicate',
            label: <div className='menu-line' onClick={handleDuplicate}>
                <div className={styles['icon']}><CopyOutlined/></div>
                <span>Duplicate</span>
            </div>
        },
        {
            key: 'delete',
            label: <div className='menu-line' data-danger='true' onClick={handleDelete}>
                <Icon icon={ICON_NAME.DELETE} danger/>
                <span>Delete</span>
            </div>
        },
    ]

    return <Menu items={menu} placement="bottomRight" disabled={loading}>
        <div {...{className: styles['bt-menu'], onMouseDown: (e: any) => e.stopPropagation()}}>
            <div/>
        </div>
    </Menu>
};

export default connect(() => {}, {
    update: checklistActions.update,
    add: checklistActions.add,
    remove: checklistActions.delete
})(MenuChecklist);
