import React, {useCallback, useEffect, useState} from 'react';
import {Drawer, Tooltip} from "antd";
import {UserService} from "../../../../util/services/user";
import {showErrorResponse} from "../../../../util/varibles/global";
import Header from "../../../../components/Popup/Component/Header";
import {propsModal} from "../../../../util/varibles/constants";
import ChangeLogs from "./ChangeLogs";
import {IChangeLog} from "../../../../util/varibles/interface";
import LoadingBox from "../../../../components/LoadingBox";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../util/store/selectors";
import WSService, {IResultSocket} from "../../../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../../../web-socket/constants";
import styles from './style.module.scss';
import {SearchOutlined} from "@ant-design/icons";
import SearchBox from "./SearchBox";

let socket: WSService | undefined;

interface IProps {
    onClose(): void
}

const PopupChangesLog: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(true);
    const [data, setData] = useState<IChangeLog[]>([]);
    const [loadMore, setLoadMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const vessels = useSelector(selectPlan.vessels);
    const popupClose = useSelector(selectPlan.popupClose);
    const {onClose} = props;

    const handleClose = useCallback(() => {
        if (socket)
            socket.removeListener(SOCKET_LEV.POPUP);
        setVisible(false)
        onClose();
    }, [onClose]);

    const onKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            handleClose()
        }
    }

    function lazyLoading() {
        if (loading)
            return;
        const {timestamp} = data[data.length - 1] || {};
        getData(timestamp - 1);
    }

    function getData(timestamp: number = Date.now()) {
        setLoading(true);
        UserService.getUserLog({timestamp})
            .then((rs: any) => {
                const {is_prev, logs = []} = rs;
                setLoadMore(is_prev)
                setLoading(false);
                setData(prev => [...prev || [], ...logs]);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Get data failed', error)
            })
    }

    const addByWs = useCallback((value: IResultSocket) => {
        if (value.function !== SOCKET_FUNC.CHANGES_LOG) return;

        setData(prev => [value.message, ...prev])
    }, []);


    useEffect(() => {
        getData()

        document.addEventListener('keydown', onKeyDown)

        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, []);

    useEffect(() => {
        socket = new WSService({listener: {lev: SOCKET_LEV.POPUP, func: addByWs}})

        return () => {
            if (socket)
                socket.removeListener(SOCKET_LEV.POPUP);
        };
    }, [addByWs]);

    useEffect(() => {
        if (popupClose)
            handleClose()
    }, [popupClose, handleClose]);

    const handleCloseSearch = useCallback((reload?: boolean) => {
        setSearch(false);
        if (reload) {
            setData([]);
            getData();
        }
    }, [])

    return <Drawer
        {...propsModal}
        open={visible}
        mask={false}
        title={<Header title={<div className='flex justify-between items-center gap-2'>
            <div>Changes log</div>
            <Tooltip title='Search by operation code'>
                <div className='bt-ghost' data-icon={true} onClick={() => setSearch(true)}>
                    <SearchOutlined/>
                </div>
            </Tooltip>
        </div>} size='small' onClose={handleClose}/>}
    >
        <SearchBox
            open={search}
            loading={loading}
            onClose={handleCloseSearch}
            onLoad={(code?: string) => {
                if (!code) {
                    return;
                }
                setData([]);
                setLoadMore(false);
                setLoading(true);
                UserService.getOpLog({operation_code: code})
                    .then((rs: any) => {
                        const {logs = []} = rs;
                        setLoading(false);
                        setData(logs);
                    })
                    .catch(error => {
                        setLoading(false);
                        showErrorResponse('Get data failed', error)
                    })
            }}
        />
        <ChangeLogs data={data} vessels={vessels} prev={loadMore} loadMore={lazyLoading}/>
        {(data.length === 0 || loading) && <div className={styles.loading}>
            <LoadingBox loading={loading}/>
        </div>}
    </Drawer>;
};


export default PopupChangesLog;

