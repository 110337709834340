import React, {createRef, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {Button, Tooltip} from "antd";
import {getRoleNames, SHORT_TIME_FORMAT_AMPM, USER_ROLE} from "../../../util/varibles/constants";
import {updatePositionCaret} from "../../../pages/WeatherPage/util";
import {userColors} from "../constants";
import MessageStatus from "./MessageStatus";
import Icon, {ICON_NAME} from "../../Icon";
import Menu from "../../Menu";
import {IMessage} from "../../../util/varibles/interface";
import {datetime} from "../../../util/library/datetime";
import {useSelector} from "react-redux";
import {selectUsername} from "../../../util/store/selectors";

interface IProps {
    data: IMessage
    color: any

    onUpdate(chat: IMessage, message: string): void
}

let content = '';

const Message: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>();
    const inputRef = useRef<HTMLInputElement>(null);
    const {
        timestamp,
        message,
        username: userOfMessage,
        roles = [],
        user_tenant_name: tenant_name = '',
        sending_status
    } = props.data;
    const [isEdit, setIsEdit] = useState(false);
    const [isOwn, setIsOwn] = useState(false);
    const [menu, setMenu] = useState<{ key: string, label: ReactNode }[]>([]);
    const username = useSelector(selectUsername)

    const handleEdit = useCallback(() => {
        content = message;
        setIsEdit(true);
        setTimeout(() => updatePositionCaret(inputRef.current, 1), 100)
    }, [message])

    useEffect(() => {
        const isOwn = userOfMessage === username;
        setIsOwn(isOwn);
        if (isOwn)
            setMenu([{
                key: 'edit',
                label: <div className='menu-line' onClick={handleEdit}>
                    <Icon icon={ICON_NAME.EDIT}/>
                    <span>Edit</span>
                </div>
            }])
    }, [handleEdit]);

    const suffix = []
    if (tenant_name.length > 0)
        suffix.push(<span key={0} className={styles['item-suffix']}>{tenant_name}</span>)
    if (roles.length > 0) {
        suffix.push(roles.length > 1
            ? <Tooltip
                key={1}
                color='#002C4D'
                overlayClassName={styles['tooltip-role']}
                title={roles.map((item: USER_ROLE) => <div className={styles.role} key={item}>
                    {getRoleNames([item])}
                </div>)}
            >
                <span className={styles['item-suffix']}>Multi-roles</span>
            </Tooltip>
            : <span key={1} className={styles['item-suffix']}>
                    {getRoleNames(roles)}
                </span>)
    }
    const [nameText] = username.split('@');
    const {nameColor} = props.color || userColors[0];


    const handleSave = () => {
        props.onUpdate(props.data, content);
        setIsEdit(false);
    }

    const handleInput = () => {
        const {textContent} = inputRef.current || {};
        content = textContent || ''
    }


    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSave();
            e.preventDefault();
            return false
        }
    }

    return <div className={styles['container-message']} data-is-own={isOwn}>
        <div className={styles.sender}>
            <div className={styles.username} style={{color: nameColor}}>{nameText}</div>
            <div className={styles.you}>You</div>
            {suffix.length > 0 && <div className={styles.suffix}>
                ({suffix})
            </div>}
            <div className={styles['sending-time']}>{datetime(timestamp).format(SHORT_TIME_FORMAT_AMPM)}</div>
        </div>
        <div className={styles['message-content']}>
            <div className={styles['wrapper-message']} data-is-edit={isEdit}>
                {isEdit ? <div className={styles['container-edit']}>
                    <div
                        className={styles['edit-input']}
                        ref={inputRef}
                        contentEditable={true}
                        onInput={handleInput}
                        onKeyDown={handleKeyDown}
                    >
                        {content}
                    </div>
                    <div className={styles['edit-action']}>
                        <Button onClick={() => setIsEdit(false)}>Cancel</Button>
                        <Button className='' type='primary'
                                onClick={handleSave}>Save</Button>
                    </div>
                </div> : <div className={styles.message}>{message}</div>}
                <MessageStatus isOwn={isOwn} status={sending_status}/>
            </div>
            <div className={styles['message-action']}>
                {(isOwn && !isEdit) && <Menu
                    items={menu}
                    placement="bottomRight"
                    getPopupContainer={() => rootRef.current as HTMLDivElement}
                >
                    <div className={[styles.menu, 'no-select'].join(' ')}>...</div>
                </Menu>}
            </div>
        </div>
    </div>;
};

export default Message;
