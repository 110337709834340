import React, {ReactNode, useMemo} from 'react';
import {useWorldMap} from "./index";
import {OverlayView} from "./OverlayView";
import Dialog from "./Dialog";
import styles from "./style.module.scss";
import {diseasedZone} from "../../util/varibles/constants";

interface IProps {
    onClick?: (data: any) => void
}

const DiseaseZonesBox: React.FC<IProps> = ({onClick}) => {
    const [zones = {}] = useWorldMap(state => state.zones);
    const [location] = useWorldMap(state => state.location);

    const children = useMemo(() => Object.keys(zones).reduce((rs: ReactNode[], key) => {
        const {name = '', color = ''} = diseasedZone[key] || {};
        const style: any = {'--diseased-color': color};
        const subs: any[] = zones[key] || [];
        return [...rs, ...subs.map(item => {
            const {id} = item;
            return <div
                key={id}
                className={styles['disease-info']}
                onMouseDown={() => onClick ? onClick({...item, diseasedType: key}) : null}
            >
                <div className="diseased-zone" data-code={key} style={style}/>
                <span>{name}</span>
            </div>
        })];
    }, []), [zones, onClick]);

    return <>
        {(location && children.length > 0) && <OverlayView
            position={{lat: location[0], lng: location[1]}}
            content={<Dialog visible={true}>
                <div className={styles['container-info']}>
                    <div className={[styles.header, 'text-center'].join(' ')}>Disease zones</div>
                    <div className={styles['disease-zones']}>
                        {children}
                    </div>
                </div>
            </Dialog>}
        />}
    </>;
};

export default DiseaseZonesBox;