import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteMode} from "../../../constatns";
import styles from './style.module.scss';
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {notification, Spin} from "antd";
import {IRoute} from "../../../../../util/varibles/interface";
import modal from "../../../../../components/Popup/ModalCustom";
import {routeActions} from "../../../reducer";
import {showErrorResponse} from "../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {RouteService} from "../../../../../util/services/route";
import {useSelector} from "../../../../../util/store/store";
import {colors} from "../../../../../contexts/WorldMapContext/Polyline";

interface IProps {
    data: IRoute
    index: number

    show(payload: number): void

    changeMode(payload: { mode: RouteMode, route: ({ index?: number } & IRoute) }): void

    deleteRoute(payload: string): void

    updateDefault(payload: string): void

    updateRoute(payload: IRoute): void
}

const RouteItem: React.FC<IProps> = (props) => {
    const {data, index, changeMode} = props;
    const {id, name: rootName} = props.data;
    const [isFocus, setIsFocus] = useState(false);
    const [name, setName] = useState(rootName);
    const [loading, setLoading] = useState(false);
    const showId = useSelector(state => state.route.route?.id) || '';

    const closeEdit = useCallback(() => {
        setIsFocus(false);
        setName(rootName);
    }, [rootName]);

    useEffect(() => {
        const handleClose = (e: MouseEvent) => {
            const {target} = e;
            if (target) {
                const el = (target as HTMLElement).closest(`.${styles['route-container']}`);
                if (el && el.id === 'route-row-' + id)
                    return false;
                closeEdit();
                document.removeEventListener('click', handleClose);
                e.preventDefault();
            }
        }

        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, [isFocus, closeEdit, id]);

    const handleSetDefault = () => {
        if (loading)
            return;
        setLoading(true);
        RouteService.setDefault(id)
            .then(() => {
                props.updateDefault(id)
                notify.success(NotifyCode.S2)([rootName]);
                setLoading(false);
            })
            .catch(error => {
                showErrorResponse('Update failed', error)
                setLoading(false);
            })
    }

    const handleDeleteRoute = () => {
        if (loading)
            return;
        modal.confirm({
            content: `Are you sure you want to delete this route "${rootName}"?`,
            ok: {
                click: () => {
                    setLoading(true);
                    RouteService.delete(id)
                        .then(({default_id = ''}) => {
                            props.deleteRoute(id)
                            if (data.default) {
                                props.updateDefault(default_id);
                            }
                            notify.success(NotifyCode.S4)([rootName]);
                            setLoading(false);
                        })
                        .catch(error => {
                            showErrorResponse('Delete failed', error)
                            setLoading(false);
                        })
                }
            }
        })
    };

    const handleFocus = (e: React.MouseEvent) => {
        if (id === showId && !isFocus) {
            setIsFocus(true);
        }
        e.preventDefault();
    }


    const handleChange = (e: any) => {
        const {value} = e.target;
        setName(value.trim());
        e.preventDefault();
        return false;
    }

    const handleSave = (e: any) => {
        if (name.length <= 0) {
            notification.error({message: 'Error', description: 'Name is required'});
            setTimeout(() => {
                const el = document.getElementById(id + '-input');
                if (el)
                    el.focus();
            }, 300)
            return false;
        }
        const {id: router_id, source_id, destination_id, default: isDefault, type, points} = data;
        const params = {router_id, source_id, destination_id, default: isDefault, type, name, points};
        setLoading(true);
        RouteService.update(params)
            .then(rs => {
                const data = {...params, ...rs};
                props.updateRoute(data)
                notify.success(NotifyCode.S2)([rootName]);
                setLoading(false);
                setIsFocus(false);
            })
            .catch(error => {
                showErrorResponse('Update failed', error)
                setLoading(false);
            })
        e.preventDefault();
        return false;
    }

    return <Spin spinning={loading}>
        <div
            id={'route-row-' + id}
            className={styles['route-container']}
            onClick={() => props.show(index)}
            data-selected={id === showId}
            data-is-focus={isFocus}
        >
            <div className={styles['route-info']}>
                <div className={styles['route-color']} style={{background: "#" + colors[index % 10]}}/>
                <div
                    className={styles['route-name']}
                    data-distance={data.distance ? `${data.distance} nm` : ''}
                    onClick={handleFocus}
                >
                    {isFocus
                        ? <input
                            id={id + '-input'}
                            className={styles['route-input']}
                            value={name}
                            onChange={handleChange}
                        />
                        : <span>{rootName}</span>}
                </div>
            </div>
            <div className={styles['route-action']}>
                {data.default ? <div className={styles['route-default']}>Default</div>
                    : <div className={styles['route-set-default']} onMouseDown={handleSetDefault}>
                        Set default
                    </div>}
                <div
                    className={styles['route-icon-edit']}
                    title="Edit router"
                    data-toggle="tooltip"
                    data-placement="top"
                    onMouseDown={() => changeMode({mode: RouteMode.EDIT, route: {...data, index}})}
                />
                <div
                    className={[styles['route-icon-delete'], 'mr-2'].join(' ')}
                    onMouseDown={handleDeleteRoute}
                />

                <div className={styles['action-edit-name']}>
                    <div className={styles['bt-cancel']} onMouseDown={closeEdit}><CloseOutlined/></div>
                    <div className={styles['bt-save']} onMouseDown={handleSave}><SaveOutlined/></div>
                </div>
            </div>
        </div>
    </Spin>;
};

export default connect(() => ({}), {
    changeMode: routeActions.changeMode,
    show: routeActions.show,
    deleteRoute: routeActions.deleteRoute,
    updateDefault: routeActions.updateDefault,
    updateRoute: routeActions.updateRoute
})(RouteItem);
