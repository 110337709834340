import styles from './Component/InputCondition/style.module.scss';
import {TAddEl} from "./Component/InputCondition";
import {getWidthOfText} from "../../util/varibles/global";


export enum WEATHER_TYPE {
    GLOBAL = 'global',
    OPERATION = 'operation',
    SITE = 'site'
}

export interface IOption {
    value: string
    label: string
    unit?: string
    labelHtml?: string
}

export const operatorObject: { [id: string]: IOption } = {
    '>': {value: '>', label: '>', labelHtml: '>'},
    '<': {value: '<', label: '<', labelHtml: '<'},
    '==': {value: '==', label: '=', labelHtml: '='},
    '>=': {value: '>=', label: '>=', labelHtml: '≥'},
    '<=': {value: '<=', label: '<=', labelHtml: '≤'},
    '!=': {value: '!=', label: '<>', labelHtml: '<>'},
}

export enum WEATHER_EL {
    PARAM = 'param',
    OPERATOR = 'operator',
    VALUE = 'value',
    RELATION = 'relation',
    GROUP = 'group',
    CONTAINER = 'container',
}


export const options: { [id: string]: IOption[] } = {
    [WEATHER_EL.PARAM]: [
        {value: 'wind_speed', label: 'Wind speed', unit: 'm/s'},
        {value: 'wind_direction', label: 'Wind direction', unit: '°'},
        {value: 'gust', label: 'Gust', unit: 'm/s'},
        {value: 'waves_height', label: 'Waves height', unit: 'm'},
        {value: 'waves_direction', label: 'Waves direction', unit: '°'},
        {value: 'waves_period', label: 'Waves period', unit: 's'},
        {value: 'temp', label: 'Air temperature', unit: '°C'},
    ],
    [WEATHER_EL.OPERATOR]: [
        operatorObject['>'],
        operatorObject['<'],
        operatorObject['=='],
        operatorObject['>='],
        operatorObject['<='],
        operatorObject['!='],
    ],
    [WEATHER_EL.RELATION]: [
        {value: 'and', label: 'and'},
        {value: 'or', label: 'or'}
    ]
}

export const operators = ['>', '<', '=']

export function getSelectionCharacterOffsetWithin(element: any) {
    let start = 0;
    let end = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection !== "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            const range = win.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.startContainer, range.startOffset);
            start = preCaretRange.toString().length;
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            end = preCaretRange.toString().length;
        }
    } else if ((sel = doc.selection) && sel.type !== "Control") {
        const textRange = sel.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToStart", textRange);
        start = preCaretTextRange.text.length;
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        end = preCaretTextRange.text.length;
    }
    return {start, end};
}

export function createSpanEl(type: string, unit?: string) {
    const span = document.createElement('span');
    span.className = styles[type];
    span.setAttribute('spellcheck', 'false');
    span.setAttribute('data-role', type);
    if (unit) {
        span.style.paddingRight = getWidthOfText(unit) + 'px';
        span.setAttribute('data-unit', unit);
    }
    return span;
}

export function createMethod(data: string) {
    if (data.length === 0)
        return [];
    let indexes = [{index: 0, length: 0}];
    let match;
    let regExp = / and |\)and | and\(| or |\)or | or\(/g;
    while ((match = regExp.exec(data)) !== null) {
        indexes.push({index: match.index, length: regExp.lastIndex - match.index})
    }
    if (indexes.length > 1) {
        indexes.sort((a, b) => a.index - b.index);
        return indexes.reduce((list: any, {index, length}: any, i: number, m: any[]) => {
            let start = index + length;
            const finish = m[i + 1] ? m[i + 1].index : data.length
            const sub = data.slice(start, finish)
            let value = [{
                value: sub,
                type: WEATHER_EL.PARAM
            }];
            let regexStart = /^\(+/g;
            let regexEnd = /\)+$/g
            let matchStart = regexStart.exec(sub);
            let matchEnd = regexEnd.exec(sub);
            if (matchStart)
                value = [
                    {value: sub.substring(0, regexStart.lastIndex), type: WEATHER_EL.GROUP},
                    {value: sub.substring(regexStart.lastIndex), type: WEATHER_EL.PARAM}
                ]

            if (matchEnd) {
                const sub = value[value.length - 1].value;
                value = [
                    ...(value.length > 1 ? [value[0]] : []),
                    {value: sub.substring(0, matchEnd.index), type: WEATHER_EL.PARAM},
                    {value: sub.substring(matchEnd.index), type: WEATHER_EL.GROUP}
                ]
            }
            if (length === 5)
                value = [{value: 'and', type: WEATHER_EL.RELATION}, ...value]
            else if (length === 4)
                value = [{value: 'or', type: WEATHER_EL.RELATION}, ...value]
            return [...list, ...value]
        }, []);
    } else {
        return [{value: data, type: WEATHER_EL.PARAM}]
    }
}

export function updatePositionCaret(target: any, offset: any) {
    const sel: any = window.getSelection();
    sel.setPosition(target, offset);
    // const range = document.createRange();
    // range.setStart(target, offset);
    // range.collapse(true);
    // if (sel.rangeCount > 0) {
    //     sel.removeAllRanges();
    // }
    // sel.addRange(range);
}

export const handleNext: {
    [weatherType: string]: (el: Element, props: { addEl: TAddEl, unit?: string }) => {
        nextRole: WEATHER_EL,
        nextEl: ChildNode | null
    }
} = {
    [WEATHER_EL.PARAM]: (el, props) => {
        let nextEl;
        if (el.nextSibling) {
            nextEl = el.nextSibling
        } else {
            nextEl = props.addEl(WEATHER_EL.OPERATOR, el);
            props.addEl(WEATHER_EL.VALUE, nextEl, props.unit);
        }
        updatePositionCaret(nextEl, 1)
        return {nextRole: WEATHER_EL.OPERATOR, nextEl};
    },
    [WEATHER_EL.OPERATOR]: (el, props) => {
        let nextEl;
        if (el.nextSibling) {
            nextEl = el.nextSibling
        } else {
            nextEl = props.addEl(WEATHER_EL.VALUE, el);
        }
        updatePositionCaret(nextEl, 1)
        return {nextRole: WEATHER_EL.VALUE, nextEl};
    },
    [WEATHER_EL.VALUE]: (el, props) => {
        let nextEl;
        if (el.nextSibling) {
            nextEl = el.nextSibling
        } else {
            nextEl = props.addEl(WEATHER_EL.RELATION, el);
        }
        updatePositionCaret(nextEl, 1)
        return {nextRole: WEATHER_EL.RELATION, nextEl};
    },
    [WEATHER_EL.RELATION]: (el, props) => {
        let nextEl;
        if (el.nextSibling) {
            nextEl = el.nextSibling
        } else {
            nextEl = props.addEl(WEATHER_EL.PARAM, el);
        }
        updatePositionCaret(nextEl, 1)
        return {nextRole: WEATHER_EL.PARAM, nextEl};
    }
}

export const updatePositionMenu = (el: any) => {
    const sel: any = window.getSelection();
    const {anchorOffset} = sel;
    const {length = 1} = sel.anchorNode;
    const {x, y, width, height} = el.getBoundingClientRect();
    const menuEl = document.getElementById('weather-suggestions');
    if (!menuEl)
        return;

    menuEl.style.left = x + (width * anchorOffset / length) + 'px';
    menuEl.style.top = y + height + 2 + 'px';
}