import React from 'react';
import styles from "./style.module.scss";
import {Button, Select} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import Task from "../index";
import stylesContainer from "../../style.module.scss";
import {IConditionOfSorting, IPropsChildren, TTaskTreatment} from "../../../../../../../../util/varibles/interface";
import {cloneObj} from "../../../../../../../../util/varibles/global";
import {propsSelect} from "../../../../../../../../util/varibles/template";
import {initTask} from "../../constants";
import LineMiddle from "../../../../_Component/LineMiddle";
import {getEditOp, IUnitWhenEdit} from "../../../../../../../../contexts/EditOperationContext";

export const unloadDirectly = {id: '', name: 'Unload directly'}

interface IProps extends IPropsChildren {
    id: string
    unit: IUnitWhenEdit
    taskParentId: string
    condition: IConditionOfSorting
    tasks: TTaskTreatment[]

    onUpdateCondition(condition: IConditionOfSorting): void

    onUpdate(tasks: TTaskTreatment[]): void
}

const Condition: React.FC<IProps> = ({
                                         id,
                                         unit,
                                         taskParentId,
                                         condition,
                                         tasks = [],
                                         children,
                                         onUpdateCondition,
                                         onUpdate
                                     }) => {
    const {vessel} = getEditOp()();
    const {storages = []} = vessel || {};

    const handleChangeTask = (task: TTaskTreatment) => {
        const {index} = task;
        onUpdate(tasks.map((item, i: number) => i === index ? task : item));
    }
    const handleAddTask = () => {
        onUpdate([...tasks, initTask(unit)]);
    }

    const handleDeleteTask = (index: number) => {
        const value = cloneObj(tasks)
        value.splice(index, 1);
        onUpdate(value);
    }

    return <div className={styles['container-sub-tasks']}>
        <div className={styles['container-condition']}>
            <div className={styles.condition}>
                <div className={styles.conditionValue}>
                    {children}
                </div>
                <Select
                    {...propsSelect}
                    className={[styles['select-custom'], 'w-full min-w-[140px] max-w-[200px]'].join(' ')}
                    placeholder="Unload to"
                    variant={"borderless"}
                    value={condition.middle_tank || ''}
                    popupClassName={styles['dropdown-custom']}
                    onSelect={(value, option) => onUpdateCondition({
                        ...condition,
                        middle_tank: value,
                        middle_tank_name: option?.data?.name
                    })}
                    popupMatchSelectWidth={false}
                    options={[unloadDirectly, ...storages].map(item => ({
                        value: item.id,
                        label: item.name,
                        data: item
                    }))}
                />
            </div>
            <div className={styles['add-sub-task']}>
                <Button {...{
                    shape: "circle",
                    type: 'text',
                    size: 'small',
                    className: styles['bt-add'],
                    icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                    onClick: handleAddTask
                }}/>
            </div>
        </div>
        <div className={styles['sub-tasks']}>
            <div className={stylesContainer['line-middle']} data-level='condition'>
                <LineMiddle containerId={'treatment-operation'} attr={{unitId: unit.id, condition: id, taskParentId}}/>
            </div>
            {tasks.map((item, index: number) => <Task
                key={item.group_id}
                unit={unit}
                condition={id}
                taskParentId={taskParentId}
                data={{...item, index}}
                onUpdate={value => handleChangeTask({...value, index})}
                onDelete={() => handleDeleteTask(index)}
            />)}
        </div>
    </div>;
};

export default Condition;


