import createFastContext from "../createFastContext";
import React, {ReactNode, useMemo} from "react";
import {ILevel, IOperation, IOperations, IVessel} from "../../util/varibles/interface";
import {openPopup} from "../../components/Popup/Component/WrapperPopup";
import OpInfo from "../../pages/PlanOperationPage/Popup/OpInfo";
import {planOpActions} from "../../pages/PlanOperationPage/reducer";
import {createKeyActivityLog, updateTime} from "../../pages/PlanOperationPage/Plan/Calendar/Operations/constants";
import {useDispatch} from "react-redux";
import {showErrorResponse} from "../../util/varibles/global";

interface IState {
    minHeight: number
    modeDrag: string

    rootKey: string
    currentKey: string
    activityLogs: any

    isTimeline: boolean

    loading: boolean

    id: string
    left?: number
    vesselIndex: number
    vesselWidth?: number

    data?: IOperation
    level: ILevel

    width?: number

    targetX?: number
    targetY?: number
    maxWidth?: number
    scrollHeight?: number
    isOwn?: boolean
    rootRef?: () => HTMLDivElement
}

const {
    Provider,
    useStore: useOperation,
    setStore: setOperation,
    getStore: getOperation,
    callEvent: callEventOperation
} = createFastContext<IState, {
    openInfo: () => void,
    resetState: (props: { isTimeline?: boolean, modeDrag?: string }) => void,
    getActivityLog: (props: {
        time: number,
        vessel: IVessel,
        key?: string,
        abort?: AbortController,
        success?: (ops: IOperations) => void
    }) => void
}>({
    state: {
        vesselIndex: 0,
        minHeight: 0,
        modeDrag: '',
        rootKey: '',
        currentKey: '',
        activityLogs: {},
        isTimeline: false,
        loading: false,
        id: '',
        level: {
            level: 0,
            ids: [],
            main: false,
            sub: 0,
            operation_code: ''
        }
    }
});

const OperationProvider = React.memo(({children, vesselIndex, ...args}: {
    children: ReactNode,
} & Partial<IState>) => {
    const {data} = args;
    const dispatch = useDispatch();

    const {currentKey, activityLogs} = useMemo(() => {
        if (!data)
            return {currentKey: '_', activityLogs: {['_']: []}}
        const currentKey = createKeyActivityLog(data.operation, data.vessel);
        return {currentKey, activityLogs: {[currentKey]: data.activity_log}}
    }, [data]);

    return <Provider
        props={{...args, vesselIndex, rootKey: currentKey, currentKey, activityLogs}}
        event={{
            openInfo: ({get}) => {
                const {isOwn} = get();
                if (data) {
                    const opInfoEl = openPopup(<OpInfo
                        isOwn={isOwn}
                        data={data}
                        onClose={() => opInfoEl.remove()}
                    />);
                }
            },
            resetState: ({isTimeline, modeDrag = ''} = {}, {get, set}) => {
                const {operation, vessel, activity_log} = get().data || {};

                if (!operation || !vessel)
                    return;

                const key = createKeyActivityLog(operation, vessel);

                set(prev => {
                    const state = {
                        loading: false,
                        modeDrag,
                        vesselIndex,
                        left: undefined,
                        key,
                        currentKey: key,
                        activityLogs: {...prev.activityLogs, [key]: activity_log},
                        isTimeline: prev.isTimeline,
                    }
                    if (isTimeline !== undefined)
                        state.isTimeline = isTimeline;

                    return state
                });
            },
            getActivityLog: ({time, vessel, key = '', abort, success}, {get, set, event}) => {
                set({loading: true});
                const {data} = get();
                if (!data)
                    return;
                const {id} = data.operation;

                dispatch(planOpActions.getActivityLog({
                    source: [{
                        operation: {
                            ...data.operation,
                            round_map: data.vessel.id !== vessel.id ? {} : data.operation.round_map
                        },
                        vessel
                    }],
                    showError: false,
                    properties: {[id]: {start: time}},
                    signal: abort,
                    success: success ? success : (ops) => {
                        set((prev: any) => ({
                            loading: false,
                            key: prev.currentKey === key ? key : prev.key,
                            activityLogs: {
                                ...prev.activityLogs,
                                [key]: updateTime(ops[id].activity_log, 0, prev.activityLogs[key][0].est_start_time)
                            }
                        }))
                    },
                    failure: (error) => {
                        if (success) {
                            const {resetState} = event();
                            resetState({});
                            showErrorResponse('Get activity logs failed', error);
                        }
                    }
                }))
            }
        }}
    >
        {children}
    </Provider>
}, (prev, next) =>
    JSON.stringify(prev.data) === JSON.stringify(next.data)
    && prev.level === next.level
    && prev.width === next.width)


export {useOperation, setOperation, getOperation, callEventOperation, OperationProvider}
