import {serverType} from "../varibles/aws-config";

const version = '/api/v1'

const urlServers = [
    // 'http://localhost:5002';
    // 'http://0.0.0.0:5000',
    // 'http://10.0.210.129:5002',
    // 'http://10.0.210.129:5000',
    'http://10.0.210.252:5000',
    'https://sfer1z5wd8.execute-api.eu-west-1.amazonaws.com/staging',                                // STAGING
    // 'https://wo6x2x9pj7.execute-api.eu-west-1.amazonaws.com/staging',
    // 'https://ydzkmwop64.execute-api.eu-west-1.amazonaws.com/dev';
    // 'http://10.0.210.131:5000',
    'https://bobxoonowj.execute-api.eu-west-1.amazonaws.com/prod',                                   // PROD
]

export const urlServer = urlServers[serverType || 1] + version;

export const urlUser = urlServer + '/user';
export const urlNotification = urlUser + '/notification'
export const urlUserWidgets = urlUser + '/dashboard';
export const urlSite = urlServer + '/site';
export const urlFactory = urlServer + '/factory';
export const urlRegion = urlServer + '/region'
export const urlDevice = urlServer + '/device';
export const urlDeviceType = urlServer + '/device_type';
export const urlEvent = urlServer + '/event';
export const urlTreatment = urlServer + '/treatment';
export const urlChecklist = urlServer + '/checklist';
export const urlSpot = urlServer + '/spot';
export const urlDashboard = urlServer + '/dashboard';
export const urlGlobal = urlServer + '/global';
export const urlWeather = urlServer + '/weather';
export const urlTenant = urlServer + '/tenant';
export const urlTaskType = urlServer + '/tasktype';
export const urlContainer = urlServer + '/container';
export const urlCombine = urlServer + '/combine';
export const urlCombineType = urlCombine + '/type';
export const urlChat = urlServer + '/chat';
export const urlVesselSide = urlServer + '/vessel-side';
export const urlDisease = urlServer + '/disease';
export const urlPoi = urlServer + '/poi'
export const urlAutoRoute = 'http://alb-for-lambda-staging-1190821073.eu-west-1.elb.amazonaws.com'
// ---------------------------------------------------------------------------------------------------------------------


const urlServices = [
    // 'http://10.0.210.131:5000',
    // 'http://10.0.210.131:5000',
    'https://wo6x2x9pj7.execute-api.eu-west-1.amazonaws.com/staging',
    'https://wo6x2x9pj7.execute-api.eu-west-1.amazonaws.com/staging',
    // 'https://zj3qzm85o0.execute-api.eu-west-1.amazonaws.com/prod'
    'https://5s6omohfxj.execute-api.eu-west-1.amazonaws.com/prod',
]

const urlService = urlServices[serverType] + version

export const urlDevicesService = urlService + '/devices';
// export const urlSitesService = urlService + '/sites'
export const urlSitesService = 'https://sefdxqh5yk.execute-api.eu-west-1.amazonaws.com/staging' + version + '/sites'
// ---------------------------------------------------------------------------------------------------------------------

const urlWebSockets = [
    "wss://b4tu20glnc.execute-api.eu-west-1.amazonaws.com/staging",
    "wss://b4tu20glnc.execute-api.eu-west-1.amazonaws.com/staging",
    // "wss://b1ubbulv0e.execute-api.eu-west-1.amazonaws.com/staging",
    // "wss://ikzwvdb065.execute-api.eu-west-1.amazonaws.com/prod",
    // "wss://ikzwvdb065.execute-api.eu-west-1.amazonaws.com/prod",
    "wss://ikzwvdb065.execute-api.eu-west-1.amazonaws.com/prod",
]
export const urlWebSocket = urlWebSockets[serverType]
