import React, {useCallback, useMemo} from 'react';
import {WorldMapProvider} from "../../../contexts/WorldMapContext";
import GoogleMap from "../../../contexts/WorldMapContext/GoogleMap";
import Sites from "./Sites";
import Factories from "./Factories";
import Vessels from "./Vessels";
import Control from "../Control";
import Pois from "./Pois";
import {useDispatch} from "react-redux";
import {dashboardActions} from "../reducer";
import WrapperTrackingOp from "../WrapperTrackingOp";
import ProductionAreas from "./ProductionAreas";
import DiseaseZonesBox from "../../../contexts/WorldMapContext/DiseaseZonesBox";
import {MAP_ELEMENT} from "../../../contexts/WorldMapContext/constants";
import styles from "../style.module.scss";
import {selectViewMode} from "../../../util/store/selectors";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {useSelector} from '../../../util/store/store';

const GroupWithOperation = () => {
    const data = useSelector(state => state.dashboard.target?.data);
    const viewMode = useSelector(selectViewMode);

    const ids: Set<string> = useMemo(() => {
        const {markers: markersOfSelected = [], id} = data || {};
        const result = new Set<string>(markersOfSelected.map((item: any) => item.id));
        if (viewMode === VIEW_MODE.PLANNER)
            return result;

        return (result.size === 0 && id) ? new Set<string>(id) : result;
    }, [viewMode, data]);

    return <>
        <Sites ids={ids}/>
        <Factories ids={ids}/>
        <Vessels ids={ids}/>
        <Pois ids={ids}/>
    </>
}

interface IProps {
}

const Map: React.FC<IProps> = () => {
    const dispatch = useDispatch()

    const showInfo = useCallback((props: any) => {
        dispatch(dashboardActions.showInfo(props))
    }, [dispatch])

    return <WorldMapProvider>
        <GoogleMap onClick={() => showInfo({})}>
            <GroupWithOperation/>
            <ProductionAreas/>
            <DiseaseZonesBox onClick={data => showInfo({data, targetType: MAP_ELEMENT.DISEASED_ZONE})}/>
            <div className={styles.screen}>
                <Control/>
                <WrapperTrackingOp/>
            </div>
        </GoogleMap>
    </WorldMapProvider>;
};

export default Map;
