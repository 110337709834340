import React from 'react';
import {Badge} from "antd";
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../Icon";
import {useSelector} from "../../../util/store/store";

const MessageBadge: React.FC = () => {
    const chatNew = useSelector(state => state.message.chatNew);
    const operationsNew = useSelector(state => state.message.operationsNew);

    return <Badge className={styles.badge} count={chatNew + operationsNew} overflowCount={99} offset={[10, -2]}>
        <Icon icon={ICON_NAME.MESSAGE} size='large'/>
        <span data-lev='name'>Messaging</span>
    </Badge>;
};

export default MessageBadge;
