import React, {ReactNode, useCallback, useEffect, useRef} from 'react';
import styles from "./style.module.scss";
import {patternFloat2, patternInteger, PROCESS_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import {Button, Divider, InputNumber} from "antd";
import DragEl from "../../../_Component/DragEl";
import {checkLimit, formatNumber} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import {ForkOutlined, PlusCircleOutlined, ProjectOutlined} from "@ant-design/icons";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {initTask} from "../constants";
import AddNote from "../../../_Component/AddNote";
import {callEventEditOp, IUnitWhenEdit, setEditOp, useEditOp} from "../../../../../../../contexts/EditOperationContext";
import {IUnit} from "../../../../../../../util/varibles/interface";

const DensityPercent: React.FC<{ unit: IUnitWhenEdit }> = ({unit}) => {
    const {density_percent} = unit;
    if (!density_percent)
        return null

    return <div className='flex items-center'>
        <Divider type='vertical' className='h-[24px]'/>
        <div className='ml-[6px]'>
            Density: {Math.round(density_percent * 100)}%
        </div>
    </div>
}

interface IPropsLayout {
    siteName?: string
    unitId: string
    unitName: string
    menu: { key: string, label: ReactNode }[]
    root?: HTMLElement | null
}

let dragEl: any, parentEl: any;

const Layout: React.FC<IPropsLayout> = ({menu, root, siteName, unitId, unitName}) => {
    const set = setEditOp();
    const {turnOnCalculate} = callEventEditOp();

    useEffect(() => {
        return () => {
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, []);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button !== 0)
            return;
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        dragEl = openPopup(<DragEl {...{
            data: [
                {label: 'Site', value: siteName},
                {label: 'Unit', value: unitName},
            ]
        }}/>)
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '2000';
        dragEl.style.pointerEvents = 'none';
        dragEl.style.display = 'none';
    }

    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = 'group';
        if (root)
            root.dataset.isDrag = 'true';

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        dragEl.style.cursor = 'grabbing';
        document.body.style.cursor = 'grabbing';
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        if (root)
            delete root.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        if (!e.target)
            return;

        e.target.dataset.hover = false;
        const {mode, unitid: newPosition} = e.target.dataset;
        if (mode !== 'change')
            return;

        set(({units}) => {
            const {[unitId]: current, ...args} = units;
            if (newPosition && Object.keys(args).length > 0) {
                return {
                    units: Object.keys(args).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                        rs[key] = args[key];
                        if (key === newPosition)
                            rs[unitId] = current
                        return rs;
                    }, {})
                }
            } else
                return {units: {[unitId]: current, ...(args || {})}}
        })
        turnOnCalculate()
    }

    return <Menu trigger={['contextMenu']} items={menu}>
        <div className={styles.layout} onMouseDown={handleMouseDown}/>
    </Menu>
}

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps {
    unit: IUnitWhenEdit
}

const Title: React.FC<IProps> = (props) => {
    const rootRef = useRef<HTMLDivElement>(null)
    const {unit} = props;
    const {id, total_weight = 0, fish_amount, avg_weight = '-', isLock, density_percent = 1, process_type} = unit;
    const [site, set] = useEditOp(state => state.site);
    const isInternal = site?.type === SITE_TYPE.INTERNAL;
    const {turnOnCalculate} = callEventEditOp();

    useEffect(() => {
        parentEl = document.getElementById('treatment-operation');
    }, []);

    const save = useCallback((value: Partial<IUnit>) => {
        set(({units}) => {
            const newUnit = {...units[id], ...value};
            const avg_weight = newUnit.fish_amount > 0 ? Math.round(newUnit.total_weight / newUnit.fish_amount) : 0;
            return {units: {...units, [id]: {...newUnit, avg_weight}}}
        })
        turnOnCalculate()
    }, [set, id, turnOnCalculate]);

    const handleChangeFishAmount = useCallback((e: any) => {
        const text = e.target.value.trim();
        if (text.length > 0) {
            const reg = new RegExp(patternInteger);
            if (!reg.test(text)) {
                notify.error(NotifyCode.E9)();
                return;
            }
        }
        save({fish_amount: +text, fish_amount_original: +text})
    }, [save])

    const handleChangeTotalWeight = useCallback((e: any) => {
        const text = e.target.value.trim();
        if (text.length > 0) {
            const reg = new RegExp(patternFloat2);
            if (!reg.test(text)) {
                notify.error(NotifyCode.E9)();
                return;
            }
        }
        save({total_weight: text * 1000000})
    }, [save])

    const handleDeleteUnit = useCallback(() => {
        set(({units}) => ({
            units: {...units, [id]: {...units[id], isSorting: false, tasks: []}}
        }))
    }, [set, id])

    const handleAddTask = useCallback(() => {
        set(({units}) => {
            const {isSorting} = units[id];
            const value = initTask(units[id])
            if (isSorting) {
                const newTasks = Array.from(units[id].tasks || []);
                const last = newTasks.pop();
                if (last)
                    return {units: {...units, [id]: {...units[id], tasks: [...newTasks || [], value, last]}}}
            }
            return {units: {...units, [id]: {...units[id], tasks: [...units[id].tasks || [], value]}}}
        })
    }, [set, id])

    const handleSaveNote = useCallback((note: string) => {
        set(({units}) => ({units: {...units, [id]: {...units[id], note}}}))
    }, [id, set]);

    const handleChangeDensityPercent = useCallback((value: number | null) => {
        set(({units}) => {
            const {tank_number, ...args} = units[id];
            return {units: {...units, [id]: {...args, density_percent: value === null ? 1 : Math.round(value) / 100}}}
        })

        turnOnCalculate()
    }, [id, set, turnOnCalculate]);

    const handleChangeProcess = useCallback(() => {
        set(({units}) => ({units: {...units, [id]: {...units[id], process_type: PROCESS_TYPE.SEQUENCE}}}))
        turnOnCalculate()
    }, [id, set, turnOnCalculate]);

    const menu = isLock ? [] : [
        {
            key: 'density',
            disabled: true,
            label: <div className='menu-line'>
                <ProjectOutlined/>
                <div>
                    <span className='mr-2'>Density</span>
                    <InputNumber<number>
                        defaultValue={100}
                        min={0}
                        value={Math.round(density_percent * 100)}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '') as unknown as number}
                        onChange={handleChangeDensityPercent}
                    />
                </div>
            </div>
        },
        ...process_type === PROCESS_TYPE.PARALLEL ? [{
            key: 'process_type',
            label: <div className='menu-line' onClick={handleChangeProcess}>
                <ForkOutlined/>
                <span>Change to sequence processing</span>
            </div>
        }] : [],
        {
            key: 'delete',
            label: <div className='menu-line' data-danger='true' onClick={handleDeleteUnit}>
                <Icon icon={ICON_NAME.DELETE} danger/>
                <span>Delete unit</span>
            </div>
        },
    ]

    return <div ref={rootRef} className={[styles.title, stylesContainer.title].join(' ')}>
        <Layout
            siteName={site?.name}
            unitId={unit.id}
            unitName={unit.unit_id}
            menu={menu}
            root={rootRef.current?.parentElement}
        />
        <div className={styles.left}>
            <label>{[site?.name, unit.unit_id].join(', ')}</label>
            <DensityPercent unit={unit}/>
            <div className='mt-[-1px]'>
                <AddNote
                    isTooltip
                    title={unit.unit_id}
                    value={unit.note || ''}
                    isLabel={false}
                    onChange={handleSaveNote}
                />
            </div>
        </div>
        <div className={styles.right}>
            {!isInternal ? <div className={styles.info}>
                <input
                    className={styles.input}
                    value={unit.fish_amount || ''}
                    onChange={handleChangeFishAmount}
                    placeholder='Amount'
                />
                <div/>
                <div className={styles.weight}>
                    <input
                        className={styles.input}
                        value={Math.round(total_weight / 1000) / 1000}
                        onChange={handleChangeTotalWeight}
                        placeholder='Total weight'
                    />
                    <div className={styles.suffix}>t</div>
                </div>
            </div> : <div className={styles.info}>
                <div className='pr-3'>{formatNumber(fish_amount)}</div>
                <div className='pr-3'>{formatNumber(avg_weight)} gr</div>
                <div className='pr-3'>{formatNumber(Math.round(total_weight / 1000) / 1000)} t</div>
            </div>}
            {isLock
                ? <div className={stylesContainer.lock}>
                    <div className={stylesContainer.message}> - Has been implemented</div>
                    <div className={stylesContainer.icon}>
                        <Icon icon={ICON_NAME.LOCK}/>
                    </div>
                </div>
                : <>
                    <Menu items={menu} placement='bottomRight'>
                        <div className='three-dot z-10'/>
                    </Menu>
                    <Button {...{
                        shape: "circle",
                        type: 'text',
                        size: 'small',
                        className: 'bt-add mt-[-1px]',
                        icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                        onClick: handleAddTask
                    }}/>
                </>}
        </div>
    </div>;
};

export default Title;