import React, {useEffect, useRef, useState} from 'react';
import {addClassName, checkLimit, removeClassName} from "../../../../util/varibles/global";
import styles from './style.module.scss';

let relY: number = 0, planBeforeHeight = 0, maxHeight = 0, isMove = false;
let beforeEl: any, afterEl: any;
const RowResize: React.FC = () => {
    const [isDrag, setIsDrag] = useState(false)
    const [isTop, setIsTop] = useState(false)
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = rootRef.current;
        if (el) {
            el.addEventListener('mousedown', handleResizeMouseDown);
            const planBeforeElement = document.getElementById('biology');
            if (planBeforeElement && planBeforeElement.offsetHeight === 15) {
                setIsTop(true)
            }
        }
        return () => {
            if (beforeEl)
                delete beforeEl.dataset.isDrag;

            if (afterEl)
                delete afterEl.dataset.isDrag;
            if (el)
                el.removeEventListener('mousedown', handleResizeMouseDown);
        }
    }, [rootRef]);

    const handleResizeMouseDown = (e: any) => {
        beforeEl = document.getElementById('biology');
        afterEl = document.getElementById('plan');
        if (!beforeEl || !afterEl) return;
        document.addEventListener('mousemove', handleResizeMouseMove);
        document.addEventListener('mouseup', handleResizeMouseUp);
        addClassName(document.body)
        const bodyPlanElement = document.getElementById('container-plan');
        relY = e.pageY;
        planBeforeHeight = beforeEl.offsetHeight;
        maxHeight = bodyPlanElement?.offsetHeight || 0
        isMove = false;
        setIsDrag(true)
    };

    const handleResizeMouseMove = (e: any) => {
        isMove = true;
        let {pageY} = e;
        let beforeHeight = planBeforeHeight + pageY - relY;
        beforeHeight = checkLimit(15, maxHeight - 15, beforeHeight);
        const afterHeight = maxHeight - beforeHeight;
        beforeEl.style.height = beforeHeight + 'px';
        afterEl.style.height = afterHeight + 'px';
        beforeEl.dataset.isDrag = 'true';
        afterEl.dataset.isDrag = 'true';
        const isTopNew = beforeHeight === 15;
        if (isTop !== isTopNew)
            setIsTop(isTopNew)
    };

    const handleResizeMouseUp = () => {
        removeClassName(document.body);
        document.removeEventListener('mousemove', handleResizeMouseMove);
        document.removeEventListener('mouseup', handleResizeMouseUp);
        delete beforeEl.dataset.isDrag;
        delete afterEl.dataset.isDrag;
        setIsDrag(false)
    };

    const handlePointerClick = (e: any) => {
        if (isMove) return;
        const planBeforeElement: any = document.getElementById('biology');
        const planAfterElement: any = document.getElementById('plan');
        if (isTop) {
            planBeforeElement.style.height = 'calc(35% - 2px)';
            planAfterElement.style.height = 'calc(65% - 2px)';
        } else {
            planBeforeElement.style.height = '15px';
            planAfterElement.style.height = (maxHeight - 15) + 'px';
        }

        setIsTop(!isTop)
        e.stopPropagation();
        e.preventDefault();
        return false
    }

    return <div ref={rootRef} className={styles.resize} data-is-drag={isDrag}>
        <div className={styles.pointer} data-is-top={isTop} onClick={handlePointerClick}/>
    </div>;
};

export default RowResize;