import React from 'react';
import {
    aWeekMillisecond,
    FULL_DAY_TIME_FORMAT,
    FULL_MONTH_DAY_YEAR_FORMAT,
    SHORT_TIME_FORMAT_AMPM
} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import {Tooltip} from "antd";
import {userColors} from "../constants";
import {datetime} from "../../../util/library/datetime";

interface IProps {
    data: any
    members: any
    colors: any
}

const Seen: React.FC<IProps> = ({data, colors}) => {
    if (!data)
        return null;
    const renderTime = (time: any) => {
        const day = datetime(time).day;
        const currentDay = datetime().day;
        if (day === currentDay) {
            return datetime(time).format(SHORT_TIME_FORMAT_AMPM);
        } else {
            if ((Date.now() - time) > aWeekMillisecond)
                return datetime(time).format(FULL_DAY_TIME_FORMAT)
            else
                return `${datetime(time).format(FULL_MONTH_DAY_YEAR_FORMAT)} at ${datetime(time).format(SHORT_TIME_FORMAT_AMPM)}`
        }
    }
    const {username: targetUser, seen = []} = data;

    return <div className={styles['container-seen']}>
        {Object.keys(seen).map(key => {
            const item = seen[key]
            const {username, timestamp} = item;
            if (!username || username === targetUser)
                return null;

            const {background} = colors[username] || userColors[0];
            const [name] = username.split('@')
            return <Tooltip
                key={key}
                overlayClassName={styles['seen-tooltip']}
                title={`Seen by ${name} at ${renderTime(timestamp)}`}
                placement='topRight'
            >
                <div className={styles.seen} style={{background}}>
                    {username[0]}
                </div>
            </Tooltip>
        })}
    </div>;
};

export default Seen;
