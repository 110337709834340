import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import styles from "./style.module.scss";
import {Button, Switch} from "antd";
import Menu from "../../../../../components/Menu";
import {PlanSetting} from "../../../../../util/varibles/userSetting";
import {loginActions} from "../../../../LoginPage/reducer";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    groupByWeek: state.login.user.setting[PlanSetting.GROUP_BY_WEEK],
    groupByUnit: state.login.user.setting[PlanSetting.GROUP_BY_UNIT],
    showSTHPEmpty: state.login.user.setting[PlanSetting.SHOW_STHP_EMPTY],
    sthpPriorWeeks: state.login.user.setting[PlanSetting.STHP_PRIOR_WEEKS]
});

interface IProps {
    groupByWeek: boolean
    groupByUnit: boolean
    showSTHPEmpty: boolean
    sthpPriorWeeks: boolean
}

const DisplayMode: React.FC<IProps> = ({groupByWeek, groupByUnit, showSTHPEmpty, sthpPriorWeeks = true}) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const handleChange = (key: any, value: boolean) => {
        const data = {[key]: value};
        dispatch(loginActions.updateUserSetting([data]));
    }
    const handleVisibleChange = (visible: boolean) => setVisible(visible);

    const menu = [
        {
            key: 'group',
            className: styles.wrapper,
            disabled: true,
            label: <div className={styles['dropdown-item']}>
                <div className={styles['option']}>
                    <span>Group by week: </span>
                    <Switch
                        defaultChecked={groupByWeek}
                        onChange={(value: boolean) => handleChange(PlanSetting.GROUP_BY_WEEK, value)}
                    />
                </div>
                <div className={styles['option']}>
                    <span>Group by Site, unit: </span>
                    <Switch
                        defaultChecked={groupByUnit}
                        onChange={(value: boolean) => handleChange(PlanSetting.GROUP_BY_UNIT, value)}
                    />
                </div>
                <div className={styles['option']}>
                    <span>Show empty harvests: </span>
                    <Switch
                        defaultChecked={showSTHPEmpty}
                        onChange={(value: boolean) => handleChange(PlanSetting.SHOW_STHP_EMPTY, value)}
                    />
                </div>
                <div className={styles['option']}>
                    <span>Show prior weeks: </span>
                    <Switch
                        defaultChecked={sthpPriorWeeks}
                        onChange={(value: boolean) => handleChange(PlanSetting.STHP_PRIOR_WEEKS, value)}
                    />
                </div>
            </div>
        }
    ];

    return <Menu visible={visible} items={menu} placement="bottomRight" onOpenChange={handleVisibleChange}>
        <Button className='bt-primary h-32' data-is-show={visible}>
            <Icon icon={ICON_NAME.STHP_VIEW}/>
            <span className='ml-5'>Customize STHP view</span>
        </Button>
    </Menu>
};

export default connect(mapStateToProps, {})(DisplayMode);
