import React, {useEffect, useState} from 'react';
import Vessel from "./Vessel";
import {connect} from "react-redux";
import {ILevelByVessel, IVessel} from "../../../../../util/varibles/interface";
import EmptyVessels from "./EmptyVessels";
import {datetime} from "../../../../../util/library/datetime";
import VesselName from "./VesselName";
import styles from './style.module.scss';
import Menu from "../../../../../components/Menu";
import {CALENDAR_ID} from "../../../constants";
import Operations from "../Operations";
import {calculateWidth} from "../constants";
import AvailableTimes from "../AvailableTimes";
import Contracts from "../Contracts";
import LazyLoading from "../LazyLoading";
import Layout from "./Layout";
import {AppState} from "../../../../../util/store/store";
import {usePlan} from "../../../../../contexts/PlanOperationContext/Plan";

const mapStateToProps = (state: AppState) => ({
    vessels: state.planOperation.vessels,
    level: state.planOperation.level,
});

interface IProps {
    vessels: IVessel[]
    level: ILevelByVessel
    startTime: number
    finishTime: number
}

const Vessels: React.FC<IProps> = (props) => {
    const {vessels, startTime, finishTime, level} = props;
    const [countDay, setCountDay] = useState(0);

    const [{show, width}, setView] = useState<{
        show: { [id: string]: number }
        width: number
    }>({show: {}, width: 0});
    const [menu] = usePlan(state => state.menu);

    useEffect(() => {
        setCountDay(datetime(finishTime).diff(startTime, 'day'));
    }, [startTime, finishTime])

    useEffect(() => {
        const {data, count} = vessels.reduce((rs: { data: { [id: string]: number }, count: number }, {id, isShow}) => {
            if (isShow) {
                rs.data[id] = rs.count;
                rs.count++
            }
            return rs;
        }, {data: {}, count: 0});
        setView({show: data, width: calculateWidth(count)})
    }, [vessels]);

    const count = vessels.length;
    if (count === 0)
        return <EmptyVessels/>

    const maxWidth = width * Object.keys(show).length;

    return <Menu
        items={menu}
        visible={menu.length === 0 ? false : undefined}
        trigger={['contextMenu']}
    >
        <div className={styles.vessels}>
            <Layout calendarWidth={maxWidth}/>
            {(width ? vessels : []).map(vessel => {
                const {id: vesselId, contracts, available_times} = vessel;

                return <Vessel
                    key={vesselId}
                    width={width}
                    index={show[vesselId]}
                    vessel={vessel}
                    countDay={countDay}
                    calendarId={CALENDAR_ID}
                >
                    <Operations
                        width={width}
                        vesselIndex={show[vesselId]}
                        level={level[vesselId]}
                    />
                    <AvailableTimes
                        width={width}
                        vesselIndex={show[vesselId]}
                        available_times={available_times}
                        finishTime={finishTime}
                        vessel={vessel}
                    />
                    <Contracts
                        width={width}
                        vesselIndex={show[vesselId]}
                        contracts={contracts}
                        vessel={vessel}
                    />
                    <VesselName vessel={vessel}/>
                </Vessel>
            })}
            <LazyLoading calendarWidth={maxWidth}/>
        </div>
    </Menu>
};

export default connect(mapStateToProps, {})(Vessels);