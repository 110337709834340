import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {Checkbox} from "antd";
import {ILocationEditDuration, TRouteExtend} from "../index";
import LoadingBox from "../../../LoadingBox";
import {colors, SeaWayPolyline} from "../../../../contexts/WorldMapContext/Polyline";
import GoogleMap from "../../../../contexts/WorldMapContext/GoogleMap";
import {useMap} from "@vis.gl/react-google-maps";

interface IProps {
    routeId: string
    routes: { [id: string]: TRouteExtend }
    locations: ILocationEditDuration[]
    loading: boolean

    setRoute(id: string): void
}

const Route: React.FC<IProps> = ({routes, routeId, loading, locations, setRoute}) => {
    const [checkAll, setCheckAll] = useState(false);
    const map = useMap();

    useEffect(() => {
        if (routes[routeId]) {
            const {points = []} = routes[routeId] || {};
            const bounds = new google.maps.LatLngBounds();
            points.forEach(point => {
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
            });
            map?.fitBounds(bounds);
        }
    }, [routeId]);

    const [locationA, locationB] = locations;

    const showAll = (e: any) => {
        const {checked} = e.target;
        setCheckAll(checked)
    }

    const handleSelect = (key: string) => {
        if (key === routeId)
            return;
        setRoute(key);
    }

    return <div className={styles.wrapper}>
        <GoogleMap>
            {Object.keys(routes).map((key, i) => {
                const item = routes[key];
                return <SeaWayPolyline
                    key={key}
                    data={item}
                    color={colors[i]}
                    width={item.id === routeId ? 3 : 2}
                    show={item.id === routeId || checkAll}
                />
            })}
            {locations.map(item => item.node)}
        </GoogleMap>
        <div className={styles.box}>
            <div className={styles.header}>Select the route from {locationA?.name} to {locationB?.name}</div>
            <Checkbox className={styles.checkAll} checked={checkAll} onChange={showAll}>Show all routes</Checkbox>
            <div className={styles.routes}>
                {Object.keys(routes).map((key, i) => <div
                    key={key}
                    className={styles.route}
                    onClick={() => handleSelect(key)}
                    data-checked={key === routeId}
                >
                    <div className={styles.color} style={{background: colors[i % 10]}}/>
                    <div className={styles.name}>{routes[key].name}</div>
                    <div>{routes[key].distance} nm</div>
                </div>)}
                {Object.keys(routes).length === 0 && <LoadingBox loading={loading}/>}
            </div>
        </div>
    </div>
};

export default Route;

