import React from 'react';
import {connect} from 'react-redux';
import {CONTRACT_STATUS, vesselTypes} from "../../util/varibles/constants";
import styles from './style.module.scss';
import {Collapse, Table} from "antd";
import {NavLink} from "react-router-dom";
import Icon, {ICON_NAME} from "../../components/Icon";
import Menu from "../../components/Menu";
import {IVessel} from "../../util/varibles/interface";
import {openPopup} from "../../components/Popup/Component/WrapperPopup";
import PopupApprove from "./PopupApprove";
import {ACTION} from "../../util/varibles/permission";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    hiredVessels: state.vesselManagement.hiredVessels,
    permission: state.login.user.permission,
});

interface IProps {
    hiredVessels: IVessel[]
    columns: any
    permission: any
}

const HiredVessels: React.FC<IProps> = (props) => {
    const {hiredVessels, columns, permission} = props;
    const action = {
        key: 'action',
        render: (record: any) => {
            if (!permission[ACTION.VESSEL.UPDATE])
                return null;

            const {spot_status} = record;
            let content;
            switch (spot_status) {
                case CONTRACT_STATUS.COMPLETE:
                    content = <Menu {...{items: renderMenu(record), placement: "bottomRight",}}>
                        <div className='three-dot'/>
                    </Menu>
                    break;
                default:
                    return null
            }
            return <div className='d-flex justify-content-end'>
                {content}
            </div>
        },
    }

    const openApprove = (values: any) => {
        const el = openPopup(<PopupApprove values={values} onClose={() => el.remove()}/>)
    }

    const renderMenu = (record: any) => {
        return [{
            key: 'cancel',
            label: <div
                className='menu-line'
                data-danger='true'
                onClick={() => openApprove({...record, statusConfirm: CONTRACT_STATUS.INACTIVE})}
            >
                <Icon icon={ICON_NAME.CANCEL} danger/>
                <div>Cancel contract</div>
            </div>
        }]
    }

    const renderHeaderComponent = (vessel: any) => {
        return <div className={styles['header-vessel']} data-own={false}>
            <div className={styles['info-hired-vessel']}>
                <div className={styles['vessel-name']}>
                    <NavLink to={'/vessel/' + vessel.id}>
                        {vessel.name}
                    </NavLink>
                </div>
                <div>{vessel.vessel_owner}</div>
                <div>{vesselTypes[vessel.type - 1].name}</div>
                <div>{vessel.treatment_type}</div>
                <div>{Math.round(vessel.capacity / 1000) / 1000 + ' tons'}</div>
                <div>{vessel.speed + ' knots'}</div>
            </div>
        </div>
    }

    return <div className={styles['category']}>
        <div className={'font-s1 font-w4 pt-2 pb-2'}>Hired Vessels</div>
        <div className={styles['title-hired-vessel']}>
            <div>Vessel</div>
            <div>Owner</div>
            <div>Vessel type</div>
            <div>Treatment type</div>
            <div>Capacity</div>
            <div>Speed</div>
            <div/>
        </div>
        <Collapse items={hiredVessels.map(vessel => {
            return {
                key: vessel.id,
                className: styles.vessel,
                label: renderHeaderComponent(vessel),
                children: (!vessel.contracts || vessel.contracts.length === 0) ?
                    <div className={styles['data-empty']}>
                        There is no fixed contract, single operation nor request yet
                    </div> :
                    <Table {...{
                        className: styles.contracts,
                        rowKey: 'id',
                        dataSource: vessel.contracts.map((item: any) => ({
                            ...item,
                            vesselId: vessel.id,
                            vesselName: vessel.name
                        })),
                        columns: [...columns, action],
                        pagination: false,
                        title: () => 'Contracts'
                    }}/>
            }
        })}/>
    </div>;
};

export default connect(mapStateToProps, {})(HiredVessels);
