import React from 'react';
import styles from './style.module.scss';
import {KeyOfStep} from "../../util/varibles/constants";
import {IActivityLog} from "../../util/varibles/interface";
import {getTimeByOpMode} from "../../pages/PlanOperationPage/util/function_operation/constants";
import {datetime} from "../../util/library/datetime";
import {useSelector} from "../../util/store/store";
import {selectOpMode} from "../../util/store/selectors";

interface IProps {
    visible?: boolean
    activity_log: IActivityLog[]
    format?: string
    current_process: any
}

const ArrivalTimeAtFactory: React.FC<IProps> = ({visible = true, activity_log, format = 'HH:mm'}) => {
    const operation_mode = useSelector(selectOpMode);

    if (!visible) return null;

    const index = activity_log.findIndex((item: any) => item.key === KeyOfStep.ARRIVE_FACTORY);
    if (index === -1)
        return null

    const time = getTimeByOpMode[operation_mode](activity_log[index]) || 0;

    return <div
        className={[styles['margin-left'], styles.time].join(' ')}
        data-time-type='factory'
        data-is-more='true'
    >
        {datetime(time).format(format)}
    </div>;
};

export default ArrivalTimeAtFactory;