import React, {ReactNode, useEffect, useState} from 'react';
import {
    eventSteps,
    harvestSteps,
    OPERATION_TYPE,
    operationType,
    propsModal,
    treatmentSteps
} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import {ERROR_TYPE} from "./constants";
import {Button, Form, Modal, Select} from "antd";
import {FormInstance} from "antd/lib/form";
import {IChecklist, IIdName, IServiceTask, IVessel} from "../../../util/varibles/interface";
import Title from "./Title";
import {updateChecklistFetch} from "../../../util/services/checklist";
import {showErrorResponse} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {connect} from "react-redux";
import Footer from "../../../components/Popup/Component/Footer";
import {AppState} from "../../../util/store/store";

const prefix = [
    {id: 'start', name: 'Start'},
    {id: 'finish', name: 'Finish'}
]

const errorMessage = (errorType: ERROR_TYPE, step: string) => {
    switch (errorType) {
        case ERROR_TYPE.SAME_CHECKLIST_SAME_STEP:
            return <span>This checklist has been assigned to <b>{step}</b> already.</span>
        case ERROR_TYPE.SAME_TYPE:
            return <span>The <b>{step}</b> has its own checklist already. Are you sure you want to replace?</span>
        default:
            return undefined
    }
}

const mapStateToProps = (state: AppState) => ({
    vessels: state.checklist.vessels,
    checklists: state.checklist.checklists,
    serviceTasks: state.checklist.serviceTasks,
});

interface IProps {
    vessels: IVessel[]
    checklists: IChecklist[]
    serviceTasks: IServiceTask[]
    checklist: IChecklist
    isSend?: boolean

    onSave(checklist: IChecklist): void

    onClose?(): void
}

const layout = {
    wrapperCol: {span: 24},
};

const {Option} = Select;

const PopupAssign: React.FC<IProps> = (props) => {
    const {vessels = [], checklist, checklists, serviceTasks, isSend, onSave, onClose} = props;
    const {vessel_id, operation_type, step} = checklist || {};
    const formRef = React.createRef<FormInstance>();
    const [vessel, setVessel] = useState<IVessel>();
    const [steps, setSteps] = useState<IIdName[]>([]);
    const [stepName, setStepName] = useState('');
    const [error, setError] = useState<ReactNode | undefined>();
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const getSteps = (opType: OPERATION_TYPE) => {
        switch (opType) {
            case OPERATION_TYPE.HARVEST:
                return harvestSteps
            case OPERATION_TYPE.TREATMENT:
                return treatmentSteps
            case OPERATION_TYPE.EVENT:
                return eventSteps;
            case OPERATION_TYPE.SERVICE:
                return (serviceTasks || []).reduce((list: any, item: any) => {
                    const {id, name} = item;
                    return [...list, ...prefix.map(sub => ({
                        id: [sub.id, id].join('_'),
                        name: [sub.name, name].join(' ')
                    }))];
                }, [])
            default:
                return []
        }
    };

    useEffect(() => {
        const vessel = vessels.find(item => item.id === vessel_id);
        if (vessel && operation_type) {
            setVessel(vessel);
            const steps = getSteps(operation_type);
            setSteps(steps);
        }
    }, [vessels, getSteps]);

    const handleChangeVessel = (target: any, option: any) => {
        const {vessel} = option;
        setVessel(vessel);
        setSteps([]);
        setError('');
        formRef.current?.setFieldsValue({operation_type: null, step: null});
    }

    const handleChangeOperationType = (value: any) => {
        const steps = getSteps(value);
        setSteps(steps);
        setError('')
        formRef.current?.setFieldsValue({step: null});
    }

    const handleChangeStep = (_: any, option: any) => {
        const value = formRef.current?.getFieldsValue();
        const isExist = checklists.some(({vessel_id, operation_type, step}: any) =>
            JSON.stringify({vessel_id, operation_type, step}) === JSON.stringify(value));

        const {name: vesselName = ''}: any = vessel || {};
        const {name: opTypeName = ''} = operationType[value.operation_type] || {};
        const {name: stepName = ''} = option.step || {};
        const name = [vesselName, opTypeName, stepName].join('/');
        setError(isExist ? errorMessage(ERROR_TYPE.SAME_CHECKLIST_SAME_STEP, name) : '')
        setStepName(stepName)
    }

    const handleSubmit = (values: any) => {
        if (JSON.stringify(values) !== JSON.stringify({vessel_id, operation_type, step})) {
            if (!error) {
                const value = {...checklist, ...values, step_name: stepName};
                if (isSend) {
                    setLoading(true);
                    new Promise(resolve => resolve(updateChecklistFetch(value)))
                        .then((rs: any) => {
                            notify.success(NotifyCode.S2)(['The checklist']);
                            onSave({...rs, oldId: value.id});
                            handleCancel();
                        })
                        .catch(error => {
                            showErrorResponse('Update failed', error)
                        })
                } else {
                    onSave(value)
                    handleCancel();
                }
            }
        } else
            handleCancel();
    }

    const handleClear = () => {
        handleSubmit({vessel_id: '-', operation_type: -1, step: -1})
    }

    const handleCancel = () => {
        setVisible(false);
        if (onClose)
            onClose()
    }

    const types = [...((vessel || {}).operation_type || [])];
    return <Modal
        {...propsModal}
        className={styles['popup-assign']}
        open={visible}
        width='380px'
        closable={false}
        zIndex={1031}
        title={<Title
            disable={step === -1}
            onClear={handleClear}
            onClose={handleCancel}
        />}
        centered
        onCancel={handleCancel}
    >
        <div className={styles['error']}>{error}</div>
        <Form
            {...layout}
            ref={formRef}
            initialValues={vessel_id !== '-' ? {vessel_id, operation_type, step} : {}}
            onFinish={handleSubmit}
            layout='vertical'
        >
            <Form.Item name="vessel_id" rules={[{required: true, message: `Vessel is required`}]}>
                <Select placeholder='Select vessel' onChange={handleChangeVessel}>
                    {vessels.map((item: any) => <Option key={item.id} {...{value: item.id, vessel: item}}>
                        {item.name}
                    </Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="operation_type"
                       rules={[{required: true, message: `Operation type is required`}]}>
                <Select placeholder='Select operation type' onChange={handleChangeOperationType}>
                    {types.sort((a, b) => a - b).map((key: number) => <Option key={key} value={key}>
                        {operationType[key] ? operationType[key].name : undefined}
                    </Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="step" rules={[{required: true, message: `Step is required`}]}>
                <Select placeholder='Select step' onSelect={handleChangeStep}>
                    {steps.map((item: any) => <Option key={item.id} {...{value: item.id, step: item}}>
                        {item.name}
                    </Option>)}
                </Select>
            </Form.Item>
            <Footer>
                <Button className='bt-default' onClick={handleCancel}> Cancel </Button>
                <Button
                    className='bt-primary'
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    Assign
                </Button>
            </Footer>
        </Form>
    </Modal>
        ;
};

export default connect(mapStateToProps, {})(PopupAssign);
