import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ICombineTreatment} from "../../../util/varibles/interface";
import {CombineTreatmentState} from "../constants";
import styles from './style.module.scss';
import InputSearch from "../../../components/Basic/InputSearch";
import {Button, Divider, Modal, notification, Table} from "antd";
import Menu from "../../../components/Menu";
import PopupEdit from "../PopupEdit";
import {createCombineFetch, deleteCombineFetch, updateCombineFetch} from "../../../util/services/combine";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {combineTreatmentActions} from "../reducer";
import {COMBINE_TREATMENT_LEVEL, combineTreatmentLevel} from "../../../util/varibles/constants";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    combineTreatment: state.combineTreatment
})

interface IProps {
    combineTreatment: CombineTreatmentState

    add(data: ICombineTreatment): void

    update(data: ICombineTreatment): void

    remove(data: ICombineTreatment): void
}

class ListOfTreatment extends Component<IProps> {
    state = {
        search: ''
    }

    columns = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
        },
        {
            key: 'type',
            title: 'Combined treatment types',
            dataIndex: 'sub_types',
            render: (data: any) => <div>
                {data.map((item: any) => item.name).join(', ')}
            </div>
        },
        {
            key: 'type',
            title: 'Scope',
            dataIndex: 'level',
            render: (data: COMBINE_TREATMENT_LEVEL) => {
                const {name} = combineTreatmentLevel[data] || {};
                return name;
            }
        },
        {
            key: 'action',
            width: 80,
            title: ' ',
            className: styles.action,
            render: (data: any) => <div className='d-flex justify-content-end pr-16'>
                <Menu placement='bottomRight' items={[
                    {
                        key: 'edit',
                        label: <div className='menu-line' onClick={() => this.openPopupEdit(false, data)}>
                            <Icon icon={ICON_NAME.EDIT}/>
                            <div data-lev='name'>Edit</div>
                        </div>
                    },
                    {
                        key: 'delete',
                        label: <div className='menu-line' data-danger={true} onClick={() => this.deleteItem(data)}>
                            <Icon icon={ICON_NAME.DELETE}/>
                            <div data-lev='name'>Delete</div>
                        </div>
                    }
                ]}>
                    <div className='d-flex align-items-center'>
                        <Icon icon={ICON_NAME.DOTS} size="large"/>
                    </div>
                </Menu>
            </div>
        }
    ]

    opInfoEl: any;

    openPopupEdit = (isCreate: boolean, data: any = {}) => {
        let title, onOk, onSend, nameOk;
        if (isCreate) {
            title = 'New combined treatment';
            onOk = this.props.add;
            nameOk = 'Create';
            onSend = createCombineFetch
        } else {
            title = 'Update combined treatment: ' + data.name;
            onOk = this.props.update
            nameOk = 'Save';
            onSend = updateCombineFetch
        }

        this.opInfoEl = openPopup(<PopupEdit {...{
            title,
            data,
            nameOk,
            onSend,
            onOk,
            onCancel: () => setTimeout(() => this.opInfoEl.remove(), 200)
        }}/>)
    }

    deleteItem = (data: any) => {
        const modal = Modal.confirm({
            title: <div className=' font-s16 font-w4'>Are you sure to delete this "{data.name}"?</div>,
            okButtonProps: {
                className: 'bt-primary font-s4'
            },
            cancelButtonProps: {
                className: 'bt-default font-s4'
            },
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                modal.update({
                    okButtonProps: {
                        className: 'bt-primary font-s4',
                        loading: true
                    }
                })
                new Promise((resolve) => resolve(deleteCombineFetch({id})))
                    .then(() => {
                        modal.destroy();
                        this.props.remove(data);
                        notification.success({message: 'Delete successful'});
                    })
                    .catch(() => {
                        modal.update({
                            okButtonProps: {
                                loading: false
                            }
                        })
                        notification.error({message: 'Delete failed'});
                    })
                return true;
            },
            onCancel: () => modal.destroy(),
        })
        const {id} = data;

    }

    render() {
        const {list, loadingList} = this.props.combineTreatment;
        const {search} = this.state;
        const source = list.filter(({name}: any) => name.toLowerCase().includes(search.toLowerCase()));
        return <>
            <div className={styles.title}>
                <InputSearch placeHolder='Search by name' value={search}
                             onChange={(search) => this.setState({search})}/>
                <Divider className='divider-vertical' type='vertical'/>
                <Button className='bt-primary bt-plus' shape='circle' onClick={() => this.openPopupEdit(true)}/>
            </div>
            <div>
                <Table
                    className={styles.table}
                    rowKey='id'
                    loading={loadingList}
                    columns={this.columns}
                    dataSource={source}
                    pagination={false}
                />
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    add: combineTreatmentActions.add,
    update: combineTreatmentActions.update,
    remove: combineTreatmentActions.delete
})(ListOfTreatment);