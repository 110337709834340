import React, {useCallback, useMemo, useState} from 'react';
import styles from "../style.module.scss";
import stylesContainer from "../style.module.scss";
import Files from "../../../_Component/AttachFile/Files";
import {
    ACTION_TYPE,
    CERTAIN_TYPE,
    getParentEl,
    OP_STATUS,
    VESSEL_TYPE,
    VIEW_SMOOTH
} from "../../../../../../../util/varibles/constants";
import GroupByUnit from "../Unit";
import {IOperation, IOperations} from "../../../../../../../util/varibles/interface";
import Footer from "../../../Footer";
import {planOpActions} from "../../../../../reducer";
import {useDispatch} from "react-redux";
import AddUnit from "../../../_Component/AddUnit";
import AttachFile from "../../../_Component/AttachFile";
import AddNote from '../../../_Component/AddNote';
import {Button, Divider, InputNumber, Popconfirm} from "antd";
import Certain from "../../../_Component/Certain";
import Cleaning from "../../../_Component/Clening";
import RoundMap from "../RoundMap";
import LineMiddle from "../../../_Component/LineMiddle";
import {RoundMapProvider} from "../../../../../../../contexts/RoundMapContext";
import {
    callEventEditOp,
    getEditOp,
    IUnitWhenEdit,
    setEditOp,
    useEditOp
} from "../../../../../../../contexts/EditOperationContext";
import SelectTime from "../../../_Component/SelectTime";
import Duration from "../../../_Component/Duration";

let control: AbortController;

const DensityUnits: React.FC = () => {
    const [densityPercent, setDensityPercent] = useState<number | null>(100);
    const [focus, setFocus] = useState(false);
    const set = setEditOp();

    const handleDensity = useCallback(() => {
        set(({units}) => {
            return {
                units: Object.keys(units).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                    const {tank_number, ...args} = units[key];
                    rs[key] = {...args, density_percent: Math.round(densityPercent || 100) / 100}

                    return rs;
                }, {})
            }
        })
    }, [densityPercent, set]);

    return <Popconfirm
        getPopupContainer={getParentEl}
        title={<div className='mx-[10px] text-[15px]'>
            Update density for all units
        </div>}
        icon={false}
        description={<div className='flex items-center gap-[8px] mx-[10px] my-[6px]'>
            <div>Density:</div>
            <InputNumber<number>
                defaultValue={100}
                min={0}
                value={densityPercent ? Math.round(densityPercent) : densityPercent}
                formatter={(value) => focus ? `${value}` : `${value}%`}
                parser={(value) => value?.replace('%', '') as unknown as number}
                onChange={value => setDensityPercent((value))}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                    setFocus(false);
                    densityPercent === null && setDensityPercent(100)
                }}
            />
        </div>}
        onConfirm={handleDensity}
        okText="Save"
        cancelText="Cancel"
    >
        <Button className='bt-ghost' data-border={false} data-icon={true}>
            <div className='px-[8px]'>Density</div>
        </Button>
    </Popconfirm>
}

interface IProps {
    // onGoSupport(): void

    onSubmit(finish: Function): void

    onClose(): void
}

const ChangeOperation: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const [operation, set] = useEditOp(state => state.operation);
    const [files] = useEditOp(state => state.files);
    const [supportTasks] = useEditOp(state => state.supportTasks);
    const [units] = useEditOp(state => state.units);
    const [loadingSubmit] = useEditOp(state => state.loadingSubmit);
    const [loadingRoundMap] = useEditOp(state => state.loadingRoundMap);
    const get = getEditOp();
    const {getRoundMap, handeUpdateOp} = callEventEditOp()
    const {note = '', certain_mode = CERTAIN_TYPE.NONE, status = null, is_cleaning} = operation || {};
    const {vessel} = get()

    const handleSubmit = () => {
        props.onSubmit((data: IOperation) => {
            if (supportTasks.length > 0) {
                // props.onGoSupport();
                setTimeout(() => {
                    const el = document.getElementById('support-vessel');
                    if (el)
                        el.scrollIntoView(VIEW_SMOOTH);
                }, 100)
            } else {
                const {id: opId = 0} = data.operation;
                if (control)
                    control.abort();
                control = new AbortController();
                set({loadingSubmit: true});
                const {startTime, editMode, onSave} = get();
                dispatch(planOpActions.getActivityLog({
                    source: [data],
                    properties: {[data.operation.id]: {start: startTime}},
                    signal: control,
                    success: (ops: IOperations) => {
                        set({loadingSubmit: false});
                        const {operation, activity_log} = ops[opId];
                        const newOp = {...data, operation, activity_log};
                        const value = editMode === ACTION_TYPE.EDIT ? {update: {[opId]: newOp}} : {add: {[opId]: newOp}}
                        const listOfFile = files.length > 0 ? {[opId]: files} : {};
                        onSave({...value, files: listOfFile});
                    },
                    failure: () => {
                        set({loadingSubmit: false});
                    }
                }))
            }
        })
    }

    const taskByUnit = useMemo(() => {
        return Object.keys(units).reduce((rs: IUnitWhenEdit[], key) => {
            const {tasks = []} = units[key];
            if (tasks.length > 0) {
                rs.push(units[key])
            }
            return rs
        }, [])
    }, [units]);

    return <div id='plan-treatment' className={styles['container-plan']}>
        <div className={styles['sub-plan']}>
            <div/>
            <div className={styles['sub-section-right']}>
                <DensityUnits/>
                <AddUnit/>
                <AttachFile isShowList={false}/>
                <AddNote
                    isTooltip
                    title='Operation'
                    value={note}
                    onChange={note => handeUpdateOp({note})}
                />
                <Divider type='vertical'/>
                <Certain
                    value={certain_mode}
                    onChange={certain_mode => handeUpdateOp({certain_mode})}
                />
            </div>
        </div>
        <div className={styles['sub-files']} data-is-show={files.length > 0}>
            <Files/>
        </div>
        <div className={[styles['wrapper-operation'], 'scroll-small'].join(' ')}>
            {taskByUnit.length === 0 && <div className={styles.line} data-lev='empty'>No task</div>}
            <div id='treatment-operation' className={styles.operation}>
                {(status !== OP_STATUS.PROCESSING && taskByUnit[0]) &&
                    <div className={stylesContainer['line-middle']} data-level='top'>
                        <LineMiddle containerId={'treatment-operation'}/>
                    </div>}
                {taskByUnit.map((item) => <GroupByUnit
                    key={item.id}
                    unit={item}
                />)}
            </div>
            <div className='flex gap-3 mb-2 items-center'>
                <SelectTime extend={() => getRoundMap()}/>
                <Duration/>
            </div>
            {vessel?.type !== VESSEL_TYPE.SERVICE && <RoundMapProvider isEdit>
                <RoundMap units={taskByUnit}/>
            </RoundMapProvider>}
        </div>
        <div className={[styles['sub-info'], 'mt-3 mb-10'].join(' ')}>
            {taskByUnit.length > 0 && <Cleaning
                is_cleaning={!!is_cleaning}
                onChange={is_cleaning => handeUpdateOp({is_cleaning})}
            />}
        </div>
        <div className='mt-auto'>
            <Footer {...{
                saveTxt: supportTasks.length ? 'Select support vessel' : undefined,
                disableSave: taskByUnit.length === 0,
                loading: loadingSubmit || loadingRoundMap,
                handleSubmit,
                onClose: props.onClose,
            }}/>
        </div>
    </div>;
};

export default ChangeOperation;
