import {ReactNode} from "react";
import {APIProvider} from "@vis.gl/react-google-maps";
import {CENTER_DEFAULT, limitShowZoom, ZOOM_DEFAULT} from "./constants";
import createFastContext from "../createFastContext";

interface IState {
    zoom: number,
    center: { lat: number, lng: number },
    hideLabel: boolean,
    location?: [number, number]
    zones?: { [id: string]: any[] }
    zonesLock?: boolean
}

const {Provider, useStore: useWorldMap} = createFastContext<IState>({
    state: {
        zoom: ZOOM_DEFAULT,
        center: CENTER_DEFAULT,
        hideLabel: ZOOM_DEFAULT < limitShowZoom
    },
});

const WorldMapProvider = ({children}: {
    children: ReactNode,
}) => {

    return <Provider >
        <APIProvider apiKey={process.env.REACT_APP_MAP_API_KEY || ''}>
            {children}
        </APIProvider>
    </Provider>
}


export {useWorldMap, WorldMapProvider}
