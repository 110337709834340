import React from 'react';
import styles from './style.module.scss';
import {connect} from "react-redux";
import {IOperation} from "../../../util/varibles/interface";
import {OPERATION_TYPE, operationType} from "../../../util/varibles/constants";
import Icon from "../../../components/Icon";
import Logo from "../../../components/Operation/Logo";
import Id from "../../../components/Operation/Id";
import OperationStatus from "../../../components/Operation/OperationStatus";
import Certain from "../../../components/Operation/Certain";
import Locations from "../../../components/Operation/Locations";
import SiteUnit from "../../../components/Operation/SiteUnit";
import Factory from "../../../components/Operation/Factory";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {
        data: state.operationDetail.data,
    }
};

function view(data?: IOperation) {
    if (!data)
        return null;

    const {operation_type} = data.operation || {};
    switch (operation_type) {
        case OPERATION_TYPE.HARVEST: {
            return <div className={styles.locations}>
                <SiteUnit {...{operation: data.operation}}/>
                <span className='arrow-right'>&#8594;</span>
                <Factory {...{operation: data.operation}}/>
            </div>
        }
        case OPERATION_TYPE.TREATMENT:
        case OPERATION_TYPE.SERVICE: {
            return <div className={styles.locations}>
                <SiteUnit {...{operation: data.operation}}/>
            </div>
        }
        case OPERATION_TYPE.TRANSPORT: {
            const {source = {name: ''}, destination = {name: ''}} = data.operation;
            return <Locations className={styles.locations} locations={[source, destination]}/>
        }
        case OPERATION_TYPE.EVENT: {
            const {pois} = data.operation;
            return <Locations className={styles.locations} locations={pois || []} separate={', '}/>
        }
        default:
            return <></>
    }
}

interface IProps {
    data?: IOperation
}

const Title: React.FC<IProps> = ({data}) => {
    if (!data)
        return null;

    const {operation_type, status, event_type} = data.operation || {};

    if (operation_type === undefined)
        return null;

    const {icon} = operationType[operation_type] || {};

    return <div className={styles.title}>
        <Logo status={status}>
            <Icon icon={icon} dataAttribute={{'data-event-type': event_type}}/>
        </Logo>
        <Id operation={data.operation} status={status}/>
        <OperationStatus status={status}/>
        <Certain {...{operation: data.operation, status}}/>
        {view(data)}
    </div>;
};
export default connect(mapStateToProps, {})(Title)

