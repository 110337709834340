import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CONVERSATION_MODE} from "../../../../../pages/MessagePage/constants";
import styles from './style.module.scss';
import ChatBox from "../../../ChatBox";
import {NavLink} from "react-router-dom";
import {getSubInfoChatOp, getTitleChatUser} from "../../../constants";
import {onOpenPage} from "../../../../../util/varibles/global";
import {messageActions} from "../../../../../pages/MessagePage/reducer";
import {CHAT_TYPE, MESSAGE_STATUS, operationType} from "../../../../../util/varibles/constants";
import Icon from "../../../../Icon";
import {selectMessage, selectUserId} from "../../../../../util/store/selectors";

interface IProps {
    id: string
    conversation: any
    mode: CONVERSATION_MODE
}

let isActive = false

const Conversation: React.FC<IProps> = (props) => {
    const source = useSelector(selectMessage.messages);
    const {id, mode, conversation} = props;
    const {messages = [], prev: isMore = false, loading = false} = source[id] || {};
    const {status = null} = messages[messages.length - 1] || {};
    const rootRef = useRef<HTMLDivElement>(null);
    const userId = useSelector(selectUserId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (rootRef.current) {
            if (!source[id]) {
                dispatch(messageActions.getMessagesRequest({id, index: 1}))
            }
            rootRef.current.dataset.mode = mode;
            isActive = true;
        }

    }, [rootRef, dispatch]);

    const handleSend = (params: any) => {
        const {type} = props.conversation;
        dispatch(messageActions.sendMessageRequest({...params, type, group_id: id}))
    }

    const renderTitle = () => {
        const {type} = conversation;
        switch (type) {
            case CHAT_TYPE.USER: {
                return getTitleChatUser(conversation, userId);
            }
            case CHAT_TYPE.OPERATION: {
                const {id, operation_type, name: operation_code, tenant_id} = conversation.group_chat;
                const link = `/operation/${tenant_id}/${id}`;
                const {icon} = operationType[operation_type] || {}
                return <div className={styles['op-title']}>
                    <div className={styles['op-logo']}>
                        <Icon icon={icon} size='large'/>
                    </div>
                    <NavLink className={styles['op-code']} onMouseDown={(e: any) => onOpenPage(e, link)} to={link}>
                        #{operation_code}
                    </NavLink>
                    {getSubInfoChatOp(conversation.group_chat)}
                </div>
            }
            default:
                return ''
        }
    }

    const changeMode = (e: any, mode: CONVERSATION_MODE) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(messageActions.updateConversations({conversation, mode}))
        return false
    }

    const close = (e: any) => {
        if (rootRef.current)
            rootRef.current.dataset.mode = 'close';
        setTimeout(() => changeMode(e, CONVERSATION_MODE.CLOSE), 100);
        return false
    }

    return <div
        ref={rootRef}
        className={styles.conversation}
        data-mode={isActive ? mode : null}
        data-is-new={status === MESSAGE_STATUS.NEW}
    >
        <div className={styles.title}
             onClick={(e) => changeMode(e, mode === CONVERSATION_MODE.MINIMIZE ? CONVERSATION_MODE.OPEN : CONVERSATION_MODE.MINIMIZE)}>
            <div className={styles['title-name']}>{renderTitle()}</div>
            <div className='bt-close' onMouseDown={close}/>
        </div>
        <div className={styles.content}>
            <ChatBox
                data={props.conversation}
                messages={messages}
                loading={loading}
                isMore={isMore}
                onSend={handleSend}
            />
        </div>
    </div>;
};

export default Conversation;
