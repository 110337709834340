import React, {useCallback} from 'react';
import styles from './style.module.scss';
import stylesContainer from "../style.module.scss";
import Title from "./Title";
import {callEventEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../../contexts/EditOperationContext";
import LineMiddle from "../../../_Component/LineMiddle";
import {ITaskService, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import {cloneObj} from "../../../../../../../util/varibles/global";
import Task from "../Task";

interface IProps {
    unit: IUnitWhenEdit
}

const Unit: React.FC<IProps> = ({unit}) => {
    const {id, tasks = []} = unit;
    const set = setEditOp();
    const {turnOnCalculate} = callEventEditOp();

    const handleUpdateTask = useCallback((index: number, value?: TTaskTreatment | ITaskService) => {
        set(({units}) => {
            const newTasks = cloneObj(units[id].tasks || []);
            if (value)
                newTasks?.splice(index, 1, value);
            else
                newTasks?.splice(index, 1);
            return ({units: {...units, [id]: {...units[id], tasks: newTasks}}})
        })
        turnOnCalculate()
    }, [set, turnOnCalculate, id])

    return <div className={[styles.group, stylesContainer['group']].join(' ')}>
        <Title unit={unit} />
        <div className={stylesContainer['line-middle']} data-level='unit'>
            <LineMiddle containerId={'service-operation'} attr={{unitId: id}}/>
        </div>
        <div className={[styles['line-middle'], stylesContainer['line-middle']].join(' ')} data-level='group'>
            <LineMiddle containerId={'service-operation'} attr={{unitId: id}}/>
        </div>
        {tasks.map((item, subIndex: number) => <Task
            key={subIndex}
            data={{...item, index: subIndex}}
            unit={unit}
            onUpdate={(value) => handleUpdateTask(subIndex, value)}
            onDelete={() => handleUpdateTask(subIndex)}
        />)}
    </div>
};

export default Unit;
