import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import {filterOption, renderOpts} from "../../../../../../util/varibles/global";
import {callEventEditOp, useEditOp} from "../../../../../../contexts/EditOperationContext";

interface IProps {
    className?: string
    value?: { id: string, name: string }[] | string
    mode?: "multiple"

    onChange?(value: ({ id: string, name: string }[]) | { label: string, value: string }): void
}

const Location: React.FC<IProps> = ({className = 'h-36 select-mode-tag', value, mode, onChange}) => {
    const [selected, setSelected] = useState<(string[] | string)>([]);
    const [locations] = useEditOp(state => state.locations);
    const {getLocations} = callEventEditOp();

    useEffect(() => {
        getLocations()
    }, []);

    useEffect(() => {
        let current
        if (value === undefined) {
            current = mode === "multiple" ? [] : ''
        } else
            current = value
        setSelected(Array.isArray(current) ? current.map(item => item.id) : current)
    }, [value, mode]);

    const handleChange = (data: { label: string, value: string }, add: boolean) => {
        if (onChange) {
            if (mode === 'multiple') {
                const old = value || []
                if (!Array.isArray(old))
                    return;

                if (add)
                    onChange([...old, {id: data.value, name: data.label}])
                else
                    onChange(old.filter(item => item.id !== data.value))
            } else
                onChange(data)
        }
    }

    return <Select
        mode={mode}
        className={className}
        loading={locations.loading}
        showSearch
        placeholder='Location'
        filterOption={filterOption}
        options={renderOpts(locations.data)}
        value={selected}
        onSelect={(...args) => handleChange(args[1], true)}
        onDeselect={(...args) => handleChange(args[1], false)}
    />;
};

export default Location;
