import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDashboard, selectOneSetting} from "../../../util/store/selectors";
import {SiteMarker} from "../../../contexts/WorldMapContext/Marker";
import {DashboardSetting} from "../../../util/varibles/userSetting";
import {ISite} from "../../../util/varibles/interface";
import {dashboardActions} from "../reducer";
import {MAP_ELEMENT} from "../../../contexts/WorldMapContext/constants";
import {LOCATION_TYPE} from "../../../util/varibles/constants";

interface IProps {
    ids: Set<string>
}

const Sites: React.FC<IProps> = React.memo(({ids}) => {
    const sites = useSelector(selectDashboard.sites) || [];
    const operations = useSelector(selectDashboard.operations);
    const areaIds = new Set(useSelector(selectOneSetting(DashboardSetting.PRODUCT_AREAS)));
    const isActiveSite = useSelector(selectOneSetting(DashboardSetting.ACTIVE_SITE));
    const dispatch = useDispatch();

    const siteActiveIds = useMemo(() => {
        const siteIds = new Set((operations || []).reduce((rs: any[], item) => {
            const {site, source, destination, sites} = item.operation;
            return [
                ...rs,
                (site || {}).id,
                (source || {}).id,
                (destination || {}).id,
                ...(sites || []).map((sub: any) => sub.id)
            ];
        }, []));
        siteIds.delete(undefined);
        return siteIds;
    }, [operations]);

    const onClick = useCallback((data: ISite) => {
        dispatch(dashboardActions.showInfo({data, targetType: MAP_ELEMENT.SITE}));
        dispatch(dashboardActions.getWeatherRequest({type: LOCATION_TYPE.SITE, id: data.id}))
    }, [dispatch])

    return <>
        {sites.map(item => {
            const {id, prod_area = 0} = item;
            const isInArea = (areaIds.size === 0 || areaIds.has(prod_area));

            return <SiteMarker
                key={id}
                data={item}
                show={(isActiveSite ? (siteActiveIds.has(id) && isInArea) : isInArea) && (ids.size === 0 || ids.has(item.id))}
                onClick={() => onClick(item)}
            />
        })}
    </>;
});

export default Sites;
