import React, {Component} from 'react';
import {OP_STATUS, OPERATION_TYPE, propContainer, SHORT_DATETIME_FORMAT} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import {Button, Checkbox, DatePicker, Form, Select} from "antd";
import {FormInstance} from "antd/lib/form";
import {connect} from "react-redux";
import {FileExcelOutlined, FilePdfOutlined} from "@ant-design/icons";
import {IFactory, ISite, IVessel} from "../../../../util/varibles/interface";
import WrapperPopup from "../../../../components/Popup/Component/WrapperPopup";
import {GlobalService} from "../../../../util/services/global";
import {Storage} from "aws-amplify";
import {downloadBlob, showErrorResponse} from "../../../../util/varibles/global";
import {datetime} from "../../../../util/library/datetime";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {AppState} from "../../../../util/store/store";

enum ExportType {
    Excel = 'excel',
    Pdf = 'pdf'
}

const request = {
    [ExportType.Excel]: GlobalService.opsToExcel,
    [ExportType.Pdf]: GlobalService.opsToPdf
}

const mapStateToProps = (state: AppState) => {
    const {planVessels = []} = state.login.user.setting || {};
    return {
        planVessels,
        vessels: state.planOperation.vessels,
        sites: state.planOperation.sites,
        factories: state.planOperation.factories,
    }
};

interface IProps {
    visible?: boolean
    planVessels: any[]
    vessels: IVessel[]
    sites: ISite[]
    factories: IFactory[]

    onCancel?(): void
}

const initState = {
    open: false,
    loading: false,
    field: 'vessel',
}

class ExportPlan extends Component<IProps> {
    static defaultProps = {
        visible: true
    }

    formRef: any = React.createRef<FormInstance>();
    state = initState
    fileType: ExportType = ExportType.Excel;

    constructor(props: IProps) {
        super(props);
        this.state = {...initState, open: !!props.visible};
    }

    componentDidMount() {
        const {vessels, planVessels} = this.props;
        if (vessels.length > 0) {
            const checkedList = planVessels.length > 0 ? vessels.reduce((list: any, {id}) => {
                return planVessels.indexOf(id) !== -1 ? [...list, id] : list
            }, []) : [];
            if (this.formRef.current)
                this.formRef.current.setFieldsValue({vessel: checkedList})
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {vessels = []} = nextProps || {};
        const nextPlanVessels = nextProps.planVessels || [];
        if (JSON.stringify(this.props.planVessels) !== JSON.stringify(nextPlanVessels)) {
            const checkedList = nextPlanVessels.length > 0 ? vessels.reduce((list: any, {id}) => {
                return nextPlanVessels.indexOf(id) !== -1 ? [...list, id] : list
            }, []) : [];
            if (this.formRef.current)
                this.formRef.current.setFieldsValue({vessel: checkedList});
            return false
        }
        return true;
    }

    exportFile = (key: any) => {
        this.fileType = key;
        if (this.formRef.current)
            this.formRef.current.submit()
    }

    handleSubmit = (values: any) => {
        const {field} = this.state;
        const {factories, sites, vessels} = this.props;
        let {
            time,
            operation_type = [],
            status = [],
            site = [],
            factory = [],
            vessel = [],
            permission = false
        } = values;

        const vesselIds = new Set(vessel);

        if (factory.length === 0 && field === 'factory') {
            factory = factories.reduce((list: any, {id}) => {
                return [...list, id]
            }, [])
        }
        if (site.length === 0 && field === 'site') {
            site = sites.reduce((list: any, {id}) => {
                return [...list, id]
            }, [])
        }

        const tenantIds = vessels.reduce((rs: any, item) => {
            if (vesselIds.has(item.id))
                rs[item.tenant_id] = true;
            return rs;
        }, {});
        let filter: any = {
            start_time: time ? time[0].valueOf() : 0,
            finish_time: time ? time[1].valueOf() : 0,
            field,
            operation_type,
            status,
            ids: field === 'all' ? [] : [].concat(site, factory, vessel),
            tenant_ids: Object.keys(tenantIds).reduce((list: any, key) => [...list, key], []),
            permission,
            timezone: datetime().utcOffset / 60,
        };

        this.setState({loading: true})
        new Promise(resolve => resolve(request[this.fileType](filter)))
            .then((filePath: any) => {
                this.setState({loading: false})
                const fileName = filePath.slice(filePath.lastIndexOf('/') + 1);
                Storage.get(filePath, {download: true})
                    .then((rs: any) => {
                        downloadBlob(rs.Body, fileName)
                        notify.success(NotifyCode.S10)();
                    })
            })
            .catch(error => {
                this.setState({loading: false})
                showErrorResponse('Export failed', error)
            })
    }

    onChangeField = (value: any) => {
        this.setState({field: value})
    }

    filterSelect = (inputValue: any, option: any) => option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    handleClose = () => {
        this.setState({open: false});
        setTimeout(() => {
            if (this.props.onCancel)
                this.props.onCancel()
        }, 300);
    }

    render() {
        const {Option} = Select
        const {RangePicker} = DatePicker;
        const {factories, sites, vessels,} = this.props;
        const {field, loading, open} = this.state;

        return <WrapperPopup
            radius='10px'
            className={styles.popup}
            visible={open}
            width={745}
            title={<div className={styles['form-header']}>
                PDF file filter for &nbsp;
                <Select
                    filterOption={false}
                    notFoundContent={null}
                    defaultValue={field}
                    className='select-single'
                    onChange={this.onChangeField}
                    popupMatchSelectWidth={false}
                >
                    <Option value='all'>All</Option>
                    <Option value='vessel'>Vessel</Option>
                    <Option value='site'>Site</Option>
                    <Option value='factory'>Factory</Option>
                </Select>
            </div>}
            onClose={this.handleClose}
        >
            <Form
                layout='vertical'
                ref={this.formRef}
                className={styles['form']}
                onFinish={this.handleSubmit}
                initialValues={{
                    operation_type: [OPERATION_TYPE.EVENT, OPERATION_TYPE.HARVEST, OPERATION_TYPE.TREATMENT],
                    status: Object.values(OP_STATUS),
                    rate: 3.5,
                }}
            >
                <div className={styles['form-body']}>
                    <div className={styles['form-1']}>
                        <Form.Item label="From time - To time:" name="time">
                            <RangePicker
                                {...{
                                    ...propContainer,
                                    allowClear: false,
                                    showTime: {format: 'HH:mm'},
                                    format: SHORT_DATETIME_FORMAT,
                                    placeholder: ['From time', 'To time'],
                                }}
                                suffixIcon={<div className={styles['calendar-icon']}/>}
                            />
                        </Form.Item>
                        <Form.Item className='form-input' label="Operation type:" name='operation_type'>
                            <Checkbox.Group className='checkbox-fill' style={{width: '100%'}}>
                                <Checkbox value={OPERATION_TYPE.EVENT}>Event</Checkbox>
                                <Checkbox value={OPERATION_TYPE.HARVEST}>Harvest</Checkbox>
                                <Checkbox value={OPERATION_TYPE.TREATMENT}>Treatment</Checkbox>
                                <Checkbox value={OPERATION_TYPE.TRANSPORT}>Smolt transport</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item className='form-input' label="Operation status:" name='status'>
                            <Checkbox.Group className='checkbox-fill' style={{width: '100%'}}>
                                <Checkbox value={OP_STATUS.WAITING}>Waiting</Checkbox>
                                <Checkbox value={OP_STATUS.SAVED}>Saved</Checkbox>
                                <Checkbox value={OP_STATUS.PROCESSING}>Processing</Checkbox>
                                <Checkbox value={OP_STATUS.FINISHED}>Finished</Checkbox>
                                <Checkbox value={OP_STATUS.APPROVED}>Approved</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        {field === 'site' && <Form.Item className='form-input' label="Site:" name='site'>
                            <Select
                                filterOption={this.filterSelect}
                                notFoundContent={null}
                                placeholder="Site"
                                mode="multiple"
                                className='select-mode-tag w-full'
                            >
                                {sites.map((item: any) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>}
                        {field === 'factory' && <Form.Item className='form-input' label="Factory:" name='factory'>
                            <Select
                                filterOption={this.filterSelect}
                                notFoundContent={null}
                                placeholder="Factory"
                                mode="multiple"
                                className='select-mode-tag w-full'
                            >
                                {factories.map((item: any) => <Option key={item.id}
                                                                      value={item.id}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>}
                        {field === 'vessel' && <Form.Item className='form-input' label="Vessel:" name='vessel'>
                            <Select
                                filterOption={this.filterSelect}
                                notFoundContent={null}
                                placeholder="Vessel"
                                className='select-mode-tag w-full'
                                mode="multiple"
                            >
                                {vessels.map((item: any) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>}
                    </div>
                    <Form.Item className='form-input' name='permission' valuePropName="checked">
                        <Checkbox>Only show own operations</Checkbox>
                    </Form.Item>
                </div>
                <div className={styles['form-footer']}>
                    <Button
                        icon={<FileExcelOutlined/>}
                        className='bt-default'
                        loading={loading}
                        htmlType="button"
                        onClick={() => this.exportFile(ExportType.Excel)}
                    >
                        Export &amp; download (Excel)
                    </Button>
                    <Button
                        className='bt-default'
                        icon={<FilePdfOutlined/>}
                        loading={loading}
                        htmlType="button"
                        onClick={() => this.exportFile(ExportType.Pdf)}
                    >
                        Export &amp; download (PDF)
                    </Button>
                </div>
            </Form>
        </WrapperPopup>
    }
}

export default connect(mapStateToProps, {})(ExportPlan);
