import React from 'react';
import styles from "./style.module.scss";
import {selectUsername} from "../../../util/store/selectors";
import {useSelector} from "react-redux";

interface IProps {
    data: any
}

const MessageLeave: React.FC<IProps> = ({data}) => {
    const currentUsername = useSelector(selectUsername);
    const [currentUser] = currentUsername.split('@');
    const [username] = data.username.split('@');
    const name = username === currentUser ? 'You' : username
    return <div className={styles['change-user']}>
        {name} left the group
    </div>;
};

export default MessageLeave;


