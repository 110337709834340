import {getMultiSetting, getSetting} from "../varibles/defaultSetting";
import {OPERATION_MODE} from "../varibles/constants";
import {AppState} from "./store";
import {createSelector} from "@reduxjs/toolkit";
import {PreferenceSetting} from "../varibles/userSetting";

// Plan operation
export const selectPlan = {
    root: (state: AppState) => state.planOperation,
    ops: (state: AppState) => state.planOperation.operations,
    popupEdit: (state: AppState) => state.planOperation.popupOperation,
    level: (state: AppState) => state.planOperation.level,
    opsCanceled: (state: AppState) => state.planOperation.canceled,
    opsDelete: (state: AppState) => state.planOperation.operationsDelete,
    vessels: (state: AppState) => state.planOperation.vessels || [],
    weeks: (state: AppState) => state.planOperation.weeks,
    beforeCount: (state: AppState) => state.planOperation.beforeCount,
    afterCount: (state: AppState) => state.planOperation.afterCount,
    factories: (state: AppState) => state.planOperation.factories,
    files: (state: AppState) => state.planOperation.files,
    opMode: (state: AppState) => state.planOperation.opMode,
    fishChanges: (state: AppState) => state.planOperation.fishChanges,
    sites: (state: AppState) => state.planOperation.sites,
    harvests: (state: AppState) => state.planOperation.harvests,
    routeIds: (state: AppState) => state.planOperation.routeIds,
    loadingSites: (state: AppState) => state.planOperation.isFetching.sites,
    opFocusId: (state: AppState) => state.planOperation.opFocusId,
    popupClose: (state: AppState) => state.planOperation.popupClose,
}

// Login
export const selectSetting = (keys: string[]) => createSelector((state: AppState) => state.login.user.setting, data => getMultiSetting(data, keys));
export const selectOneSetting = (key: string) => createSelector((state: AppState) => state.login.user.setting, data => getSetting(data, key));

export const selectUserId = (state: AppState) => state.login.user.user_id || '';
export const selectDefaultOpType = (state: AppState) => state.login.user.tenant.default_op_type;
export const selectUser = (state: AppState) => state.login.user || {};
export const selectUsername = (state: AppState) => state.login.user.username
export const selectPermission = (state: AppState) => state.login.user.permission || {};
export const selectTenantId = (state: AppState) => state.login.user.tenant_id;
export const selectDefaultVessel = (state: AppState) => state.login.user.default_vessel;
export const selectTenant = (state: AppState) => state.login.user.tenant;
export const selectOpMode = (state: AppState) => state.login.user.tenant.operation_mode || OPERATION_MODE.PLAN;
export const selectVesselsSide = (state: AppState) => state.login.vessels || [];
export const selectViewMode = (state: AppState) => getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE)

// Dashboard
export const selectDashboard = {
    sites: (state: AppState) => state.dashboard.sites,
    factories: (state: AppState) => state.dashboard.factories,
    prodAreas: (state: AppState) => state.dashboard.productionAreas,
    loadingProdAreas: (state: AppState) => state.dashboard.loadingProdAreas,
    vessels: (state: AppState) => state.dashboard.vessels,
    loadingVessels: (state: AppState) => state.dashboard.loadingVessels,
    operations: (state: AppState) => state.dashboard.operations,
    target: (state: AppState) => state.dashboard.target,
    tracking: (state: AppState) => state.dashboard.tracking,
    loadingWeather: (state: AppState) => state.dashboard.loadingWeather,
    weather: (state: AppState) => state.dashboard.weather,
}

// Overview
export const selectOverview = {
    root: (state: AppState) => state.overview,
    weeks: (state: AppState) => state.overview.weeks,
    ops: (state: AppState) => state.overview.operations,
    level: (state: AppState) => state.overview.level,
}

// Configuration
export const selectConfiguration = {
    iotName: (state: AppState) => state.configuration.iotName
}

// Route
export const selectRoute = {
    mode: (state: AppState) => state.route.mode
}

// Message
export const selectMessage = {
    messages: (state: AppState) => state.message.messages || [],
    conversations: (state: AppState) => state.message.conversations || [],
}

// Vessel detail
export const selectVessel = {
    replay: (state: AppState) => state.vesselDetail.replay,
    dashboard: (state: AppState) => state.vesselDetail.dashboard,
    isLive: (state: AppState) => state.vesselDetail.isLive,
    isTracking: (state: AppState) => state.vesselDetail.isTracking,
    isDragging: (state: AppState) => state.vesselDetail.isDragging,
    vessel: (state: AppState) => state.vesselDetail.vessel,
    history: (state: AppState) => state.vesselDetail.history,
    currentTime: (state: AppState) => state.vesselDetail.currentTime,
    finishTime: (state: AppState) => state.vesselDetail.history.finishTime,
    vesselLive: (state: AppState) => state.vesselDetail.vesselLive,
    iot_name: (state: AppState) => state.vesselDetail.vessel?.iot_name,
    deviceIds: (state: AppState) => state.vesselDetail.deviceIds,
    update: (state: AppState) => state.vesselDetail.update,
}

// Operation detail
export const selectOpDetail = {
    data: (state: AppState) => state.operationDetail.data,
}

// Weather
export const selectWeather = {
    locations: (state: AppState) => state.weather.locations
}

