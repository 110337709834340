import React from 'react';
import styles from "./style.module.scss";
import {selectUsername} from "../../../util/store/selectors";
import {useSelector} from "react-redux";

interface IProps {
    data: any
}

const MessageRemoveUser: React.FC<IProps> = ({data}) => {
    const currentUsername = useSelector(selectUsername);
    const [currentUser] = currentUsername.split('@');
    const [username] = data.username.split('@');
    const {members = []} = data;

    const userA = username === currentUser ? 'You' : username;
    const userB = members.map((item: any) => {
        const [name] = (item.username || '').split('@');
        return name === currentUser ? 'you' : name
    })
    return <div className={styles['change-user']}>
        {userA} removed {userB.join(', ')} from the group
    </div>;
};

export default MessageRemoveUser;

