import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./style.module.scss";
import {connect, useDispatch, useSelector} from "react-redux";
import {IOperation, IOperations, IVessel} from "../../../util/varibles/interface";
import {
    ACTION_TYPE,
    AVAILABLE_TIME_TYPE,
    aWeekMillisecond,
    OP_STATUS,
    OPERATION_TYPE
} from "../../../util/varibles/constants";
import {CALENDAR_ID} from "../constants";
import Header from "./Header";
import Weeks from "../../PlanOperationPage/Plan/Calendar/Weeks";
import Vessel from "../../PlanOperationPage/Plan/Calendar/Vessels/Vessel";
import VesselName from "../../PlanOperationPage/Plan/Calendar/Vessels/VesselName";
import Operations from "./Operations";
import AvailableTimes from "../../PlanOperationPage/Plan/Calendar/AvailableTimes";
import Contracts from "../../PlanOperationPage/Plan/Calendar/Contracts";
import LazyLoading from "../../PlanOperationPage/Plan/Calendar/LazyLoading";
import Menu from "../../../components/Menu";
import EmptyVessels from "../../PlanOperationPage/Plan/Calendar/Vessels/EmptyVessels";
import {datetime} from "../../../util/library/datetime";
import {EL_RULE} from "../../PlanOperationPage/Plan/Calendar/constants";
import {
    changeLoadingOp,
    cloneObj,
    getElById,
    getStartTimeFromDate,
    jumpToToday,
    showErrorResponse
} from "../../../util/varibles/global";
import {MENU_OP, menuOp, menuOptions} from "./constants";
import {renderMenu} from "../../PlanOperationPage/Plan/Calendar/Operations/actions";
import {TargetTypes} from "../../PlanOperationPage/Plan/Calendar/Vessels/constants";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "../Popup/PopupEdit";
import Layout from "../../PlanOperationPage/Plan/Calendar/Vessels/Layout";
import {AppState, store} from "../../../util/store/store";
import {overviewActions} from "../reducer";
import {checkValidOperation, generate} from "../../PlanOperationPage/util";
import {VALIDATION_STATUS} from "../../PlanOperationPage/util/validation";
import {crewSendFetch} from "../../../util/services/vessel-side";
import {notification} from "antd";
import {template, TemplateCode} from "../../../util/varibles/template";
import {loginActions} from "../../LoginPage/reducer";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {PlanProvider, usePlan} from "../../../contexts/PlanOperationContext/Plan";
import {selectOpMode, selectOverview, selectTenantId} from "../../../util/store/selectors";

const Vessels: React.FC<{ vessel: IVessel, countDay: number, finishTime: number }> = ({vessel, countDay, finishTime}) => {
    const {id: vesselId, contracts, available_times} = vessel;
    const level = useSelector(selectOverview.level);
    const [menu] = usePlan(state => state.menu);

    return <Menu
        items={menu}
        visible={menu.length === 0 ? false : undefined}
        trigger={['contextMenu']}
    >
        <div className={styles.vessels}>
            <Vessel
                key={vesselId}
                width={width}
                vessel={vessel}
                index={0}
                countDay={countDay}
                calendarId={CALENDAR_ID}
            >
                <VesselName vessel={vessel}/>
                <Operations
                    width={width}
                    vesselIndex={0}
                    level={level[vesselId]}
                />
                <AvailableTimes
                    width={width}
                    available_times={available_times}
                    vesselIndex={0}
                    finishTime={finishTime}
                    vessel={vessel}
                />
                <Contracts
                    vessel={vessel}
                    width={width}
                    contracts={contracts}
                    vesselIndex={0}
                />
            </Vessel>
            <LazyLoading calendarWidth={width}/>
            <Layout calendarWidth={width}/>
        </div>
    </Menu>
}

const mapStateToProps = (state: AppState) => ({
    weeks: state.overview.weeks,
    vessels: state.overview.vessels,
    loading: state.overview.loadingData,
    default_vessel: state.login.user.default_vessel,
})

const width = 100;

interface IProps {
    weeks: any
    vessels: IVessel[]
    loading: boolean
    default_vessel?: string

    addOps(operations: IOperation[]): void

    deleteOps(payload: string[]): void

    updateOps(operations: IOperations): void
}

const CalendarVessel: React.FC<IProps> = (props) => {
    const [vessel, setVessel] = useState<IVessel>();
    const {weeks, vessels, default_vessel} = props;
    const [countDay, setCountDay] = useState(0);
    const dispatch = useDispatch();

    const startTime = weeks[0].time;
    const finishTime = weeks[weeks.length - 1].time + aWeekMillisecond - 1;
    const calendarRef = useRef<HTMLDivElement>(null);
    const tenantId = useSelector(selectTenantId);

    useEffect(() => {
        setCountDay(datetime(finishTime).diff(startTime, 'day'));
    }, [startTime, finishTime])

    useEffect(() => {
        const vessel = vessels.find(item => item.id === default_vessel) || vessels[0];
        setVessel(vessel);
        jumpToToday(CALENDAR_ID);
    }, [vessels, default_vessel]);

    const handleDragOp = (op: IOperation) => {
        const {id} = op.operation;
        const {operations, level} = selectOverview.root(store.getState())
        const old = cloneObj(operations[id]);
        if (op.operation.operation_type !== OPERATION_TYPE.EVENT) {
            notify.error(NotifyCode.E34)()
            props.deleteOps([id]);
            setTimeout(() => props.addOps([old]), 4)
            return;
        }
        const opMode = selectOpMode(store.getState());
        const {status, message} = checkValidOperation({
            operations,
            level,
            data: op,
            factories: [],
            opMode
        });
        if (status === VALIDATION_STATUS.VALID) {
            const vessels = [
                {
                    vessel_id: op.vessel.id,
                    vessel_owner_id: op.vessel.tenant_id,
                    operations: [generate[op.operation.operation_type](op)]
                }
            ]
            props.deleteOps([id]);
            setTimeout(() => {
                props.addOps([op]);
                setTimeout(() => {
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, true);
                }, 1)
            }, 4)
            new Promise(resolve => resolve(crewSendFetch({vessels})))
                .then((rs: any) => {
                    const {new_operations, old_operations} = rs;
                    const {vessel} = op;

                    const {newCode, newOps} = new_operations.reduce((rs: any, item: any) => {
                        rs.newCode = [...rs.newCode, `#${item.operation.operation_code}`];
                        rs.newOps = [...rs.newOps, {...item, vessel}];
                        return rs;
                    }, {newCode: [], newOps: []});

                    const {oldCode, oldOps} = old_operations.reduce((rs: any, item: any) => {
                        rs.oldCode = [...rs.oldCode || [], `#${item.operation.operation_code}`];
                        rs.oldOps = [...rs.oldOps || [], {...item, vessel}];
                        return rs;
                    }, {oldCode: [], oldOps: []});

                    notification.success({
                        message: 'Update successfully',
                        description: template[TemplateCode.T2]('', newCode, oldCode)
                    });
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, false);
                    props.updateOps(oldOps);
                    dispatch(loginActions.updateOpsOfVessel([...newOps, ...oldOps]));
                })
                .catch(error => {
                    const el = getElById(id + '-loading');
                    changeLoadingOp(el, false);
                    showErrorResponse('Update failed', error)
                    props.deleteOps([id]);
                    setTimeout(() => props.addOps([old]), 5)
                    console.log(error);
                    showErrorResponse('Error', error);
                })
        } else {
            notification.error({message: 'Update failed', description: message});
            props.deleteOps([id]);
            setTimeout(() => props.addOps([old]), 4)
        }
    }

    const generateMenu = useCallback((target: TargetTypes) => {
        let list = [];
        if (!vessel)
            return [];

        const {type} = target;
        switch (type) {
            case EL_RULE.VESSEL: {
                const {time} = target;
                const {isOwn, permission, available_time_type} = vessel;
                if (isOwn || permission || available_time_type === AVAILABLE_TIME_TYPE.ALL) {
                    list.push({
                        key: 'event',
                        label: <div
                            onMouseDown={() => {
                                const state = store.getState();
                                const {operations, level} = selectOverview.root(state)
                                const opMode = selectOpMode(state);
                                const start = getStartTimeFromDate({date: time, operations, level, opMode, vessel});

                                const el = openPopup(<PopupEdit
                                    visible={true}
                                    editMode={ACTION_TYPE.CREATE}
                                    data={{
                                        activity_log: [{est_start_time: start}],
                                        operation: {
                                            operation_type: OPERATION_TYPE.EVENT
                                        },
                                        vessel
                                    }}
                                    onClose={() => el.remove()}
                                />)
                            }}
                        >
                            Add event
                        </div>
                    })
                }
                break;
            }
            case EL_RULE.OPERATION: {
                const {data, edit} = target;
                const {status, operation_type} = data.operation;

                const start = data.activity_log[0].est_start_time;
                const isActive = status !== OP_STATUS.PROCESSING
                    && status !== OP_STATUS.FINISHED
                    && start > Date.now();

                const common = {dispatch, operations: selectOverview.ops(store.getState())}
                list = renderMenu(menuOptions[operation_type].map((type: MENU_OP) => menuOp[type]({
                    ...data,
                    isActive
                }, {...common, edit})))
                break;
            }

        }
        return list;
    }, [vessel, dispatch])

    if (!vessel)
        return <>
            <Header/>
            <div id={CALENDAR_ID} className={styles.calendar}>
                <Weeks startTime={startTime} finishTime={finishTime}/>
                <EmptyVessels/>
            </div>
        </>


    return <>
        <Header/>
        <div id={CALENDAR_ID} className={styles.calendar}>
            <Weeks startTime={startTime} finishTime={finishTime}/>
            <PlanProvider
                userTenantId={tenantId}
                startPoint={startTime}
                calendarRef={() => calendarRef.current as HTMLDivElement}
                generateMenu={generateMenu}
                onDragOp={handleDragOp}
            >
                <Vessels vessel={vessel} countDay={countDay} finishTime={finishTime}/>
            </PlanProvider>
        </div>
    </>;
};
export default connect(mapStateToProps, {
    deleteOps: overviewActions.deleteOps,
    addOps: overviewActions.addOps,
    updateOps: overviewActions.updateOps
})(CalendarVessel);
