import React from 'react';
import {OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import Harvest from "./Harvest";
import Unavailable from "./Unavailable";
import Event from "./Event";
import Transport from "./Transport";
import Treatment from "./Treatment";
import {IOperation} from "../../../../../../util/varibles/interface";
import Service from "./Service";

export interface IPropsOp {
    data: IOperation
    isOwn: boolean
}

const Operation: React.FC<IPropsOp> = (props) => {
    const {isOwn: isVesselOwn = false, permission} = props.data.vessel;
    const {operation_type, status} = props.data.operation;
    const operationType = (!(isVesselOwn || permission) && !props.isOwn) ? OPERATION_TYPE.UNAVAILABLE : operation_type;

    const common = {
        ...props,
        isVesselOwn,
        permission,
        operationType,
        status,
    }

    switch (operationType) {
        case OPERATION_TYPE.HARVEST:
            return <Harvest {...common}/>
        case OPERATION_TYPE.TREATMENT:
            return <Treatment {...common}/>
        case OPERATION_TYPE.EVENT:
            return <Event {...common}/>
        case OPERATION_TYPE.TRANSPORT:
            return <Transport {...common}/>
        case OPERATION_TYPE.SERVICE:
            return <Service {...common}/>
        default:
            return <Unavailable {...common}/>
    }
};

export default Operation;
