import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE,} from "../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Button, Table, Tooltip} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditTaskType from "../PopupEditTaskType";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    taskTypes: state.taskType.taskTypes,
    loading: state.taskType.loading
});

interface IProps {
    taskTypes: any[]
    loading: boolean
}

class ListOfTaskType extends Component<IProps> {
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: any) => <span>{name}</span>,
        },
        // {
        //     title: 'Tag',
        //     dataIndex: 'in_fields',
        //     key: 'in_fields',
        //     render: (tag: any) => tag.map((item: any) =>
        //         <span className={ styles['tag'] } data-tag={ item }>{ item }</span>),
        // },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            render: (unit: any) => <span>{unit}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value: any) => <span>{value} h</span>,
        },
        {
            width: '55px',
            className: styles['action'],
            render: (data: any) => <div
                className={styles['edit-icon']}
                onClick={() => this.handleClick(ACTION_TYPE.EDIT, data)}
            />,
        },
    ]

    handleClick = (editMode: ACTION_TYPE, data?: any) => {
        const el = openPopup(<PopupEditTaskType
            editMode={editMode}
            task={data}
            onClose={() => el.remove()}
        />)
    }

    render() {
        const {taskTypes, loading} = this.props;

        return <div className={styles.category}>
            <div className='d-flex justify-content-end mb-10'>
                <Tooltip title="Add task type">
                    <Button className='bt-primary bt-plus' type="primary" shape="circle"
                            icon={<PlusOutlined style={{fontSize: '18px'}}/>}
                            onClick={() => this.handleClick(ACTION_TYPE.CREATE)}
                    />
                </Tooltip>
            </div>
            <Table
                rowKey="id"
                className={styles.table}
                loading={loading}
                columns={this.columns}
                dataSource={taskTypes}
                pagination={false}
            />
        </div>;
    }
}

export default connect(mapStateToProps, {})(ListOfTaskType);
