import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, Modal, notification, Radio, Select} from "antd";
import styles from './style.module.scss';
import {
    aMinutesMillisecond,
    COMBINE_TREATMENT_LEVEL,
    combineTreatmentLevels,
    TASK_TYPE,
    taskTypes,
    TREATMENT_TYPE,
    treatmentType,
    treatmentTypes,
    USER_ROLE
} from "../../../util/varibles/constants";
import {
    checkPermission,
    convertNumberTimeToStringTime,
    convertStringTimeToNumberTime,
    renderOpts,
    showErrorResponse
} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {formValidation} from "../../../util/varibles/validation";
import Header from "../../../components/Popup/Component/Header";
import {propsSelect, template, TemplateCode} from "../../../util/varibles/template";
import {connect} from "react-redux";
import {ICombineTreatment, ISubCombineTreatment} from "../../../util/varibles/interface";
import {ACTION} from "../../../util/varibles/permission";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    roles: state.login.user.roles,
    permission: state.login.user.permission
})

const tasks = [
    ...treatmentTypes.map((item: any) => ({...item, id: [TASK_TYPE.TREATMENT, item.id].join('-')})),
    {...taskTypes[TASK_TYPE.MOVE], id: `${TASK_TYPE.MOVE}-`}
]

const renderDetail = (data: ISubCombineTreatment, handleChangeDetail: Function, error: any) => {
    const {type, treatment_type} = data;

    switch (+type) {
        case TASK_TYPE.TREATMENT: {
            switch (+treatment_type) {
                case TREATMENT_TYPE.HYDROGENPEROXIDE:
                case TREATMENT_TYPE.SALTWATER:
                case TREATMENT_TYPE.FRESHWATER: {
                    const {name = ''} = treatmentType[treatment_type] || {};
                    const key = [type, treatment_type].join('-');
                    return <div className={styles['form-line']} key={key}>
                        <div data-lev='label'>{name} duration (Optional)</div>
                        <Input
                            className='h-36'
                            placeholder='4d 6h 45m'
                            value={data.durationTxt || ''}
                            onChange={(e: any) => handleChangeDetail(type, treatment_type, e.target.value)}
                        />
                        {error[key] && <div data-lev='error'>
                            {template[TemplateCode.T1](error[key])}
                        </div>}
                    </div>
                }
                default:
                    return null;
            }
        }
        default:
            return null;
    }
}

const getLevel = (roles: USER_ROLE[]) => {
    const isGlobal = checkPermission([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN, USER_ROLE.VESSEL_OWNER], roles);
    if (isGlobal)
        return COMBINE_TREATMENT_LEVEL.GLOBAL
    return COMBINE_TREATMENT_LEVEL.LOCAL
}

interface IProps {
    title: string
    data: ICombineTreatment
    permission: any
    nameOk: string
    roles: USER_ROLE[]

    onSend(params: any): Promise<any>

    onOk(data: any): void

    onCancel(): void
}

const PopupEdit: React.FC<IProps> = (props) => {
    const {data, nameOk, permission, onCancel} = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    const [name, setName] = useState('');
    const [sub_types, setSubTypes] = useState<any[]>([]);
    const [error, setError] = useState<any>({});
    const [level, setLevel] = useState(COMBINE_TREATMENT_LEVEL.LOCAL);

    useEffect(() => {
        const {name = '', sub_types = [], level = getLevel(props.roles)} = data || {};
        setLevel(level)
        setName(name);
        setSubTypes(sub_types.map((item: any) => {
            const {duration} = item;
            if (duration)
                return {...item, durationTxt: convertNumberTimeToStringTime(duration * aMinutesMillisecond)}
            return item
        }))
    }, [data, props.roles]);

    function close() {
        setVisible(false)
        onCancel();
    }

    const handleSubmit = useCallback(() => {
        const error: any = {};
        const newSubTypes: ISubCombineTreatment[] = [];
        if (name.length === 0) {
            error.name = `Name can't be empty`;
        }

        if (sub_types.length === 0) {
            error.sub_types = `Combined treatment types can't be empty`;
        } else {
            sub_types.forEach((item: any) => {
                const {durationTxt = '', type, treatment_type} = item
                if (durationTxt.length > 0) {
                    const duration = convertStringTimeToNumberTime(durationTxt);
                    const id = [type, treatment_type].join('-');

                    if (duration !== undefined) {
                        delete error[id];
                        newSubTypes.push({
                            ...item,
                            duration: Math.round(duration / aMinutesMillisecond)
                        })
                    } else
                        error[id] = notify[NotifyCode.E19]();
                } else
                    newSubTypes.push(item);
            })
        }

        if (Object.keys(error).length === 0) {
            const params = {...props.data, name, sub_types: newSubTypes};
            if (permission[ACTION.COMBINE_TREATMENT.GLOBAL]) {
                params.level = level;
            }
            setLoading(true)
            new Promise((resolve) => resolve(props.onSend(params)))
                .then(rs => {
                    props.onOk(rs);
                    setLoading(false)
                    notification.success({message: nameOk + ' successful'});
                    close();
                })
                .catch(() => {
                    showErrorResponse(nameOk + ' failed', error);
                    setLoading(false)
                })
        } else {
            setError(error)
        }
    }, [name, permission, sub_types, level, props]);


    const handleChangeDetail = (type: TASK_TYPE, treatment_type: TREATMENT_TYPE, value: string) => {
        const duration = convertStringTimeToNumberTime(value);
        const id = [type, treatment_type].join('-');
        if (duration !== undefined)
            delete error[id]
        else
            error[id] = notify[NotifyCode.E19]();

        setSubTypes(sub_types.map((item: any) => (item.type === type && item.treatment_type === treatment_type)
            ? {...item, durationTxt: value, duration} : item))
        setError(error)
    }

    const handleChangeName = (value: any) => {
        if (value.length === 0) {
            const {message} = formValidation.require('Name');
            error.name = message
        } else
            delete error.name

        setName(value)
        setError(error)
    }

    const handleChangeSub = (isAdd: boolean, id: any) => {
        if (isAdd) {
            const [type, treatment_type = ''] = id.split('-');
            const {name = ''} = (+type === TASK_TYPE.TREATMENT ? treatmentType[treatment_type] : taskTypes[type]) || {};
            const common: any = {type: +type};
            if (treatment_type.length > 0)
                common.treatment_type = +treatment_type
            setSubTypes([...sub_types, {name, ...common,}])
        } else {
            delete error[id];
            setSubTypes(sub_types.filter((item: any) => [item.type, item.treatment_type].join('-') !== id))
        }
    }

    const {name: errorName, sub_types: errorTypes} = error || {};
    const taskIds = sub_types.map(({type, treatment_type}: any) => [type, treatment_type].join('-'))

    return <Modal
        className={styles.modal}
        title={<Header title={props.title} size={'normal'} onClose={close}/>}
        closable={false}
        open={visible}
        footer={null}
        onCancel={close}
    >
        <div className={styles.form}>
            <div className={styles['form-line']}>
                <div data-lev='label'>Name</div>
                <Input className='h-36' value={name} onChange={(e: any) => handleChangeName(e.target.value)}/>
                {errorName && <div data-lev='error'>{errorName}</div>}
            </div>
            <div className={styles['form-line']}>
                <div data-lev='label'>Combined treatment types</div>
                <Select
                    {...propsSelect}
                    value={taskIds}
                    className='w-full select-mode-tag'
                    mode='multiple'
                    onDeselect={(key: string) => handleChangeSub(false, key)}
                    onSelect={(key: string) => handleChangeSub(true, key)}
                    options={renderOpts(tasks)}
                />
                {errorTypes
                    ? <div data-lev='error'>{errorTypes}</div>
                    : <div className={styles.note}>
                        The combined treatment will be implemented in order from left to right.
                    </div>}
            </div>
            {sub_types.map((item: any) => renderDetail(item, handleChangeDetail, error))}
            {permission[ACTION.COMBINE_TREATMENT.GLOBAL] && <div className={styles['form-line']}>
                <div data-lev='label'>Set effective scope:</div>
                <Radio.Group className={styles.level} value={level} onChange={e => setLevel(e.target.value)}>
                    {combineTreatmentLevels.map(item => {
                        return <Radio key={item.id} value={item.id}>
                            <span className='mb-5'>{item.name}</span>
                            <div>{item.description}</div>
                        </Radio>
                    })}
                </Radio.Group>
            </div>}
        </div>

        <Button
            className={[styles.button, 'bt-primary'].join(' ')}
            data-full={true}
            loading={loading}
            onClick={handleSubmit}
        >
            {props.nameOk}
        </Button>
    </Modal>;
};

export default connect(mapStateToProps, {})(PopupEdit);