import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss';
import {messageActions} from "../../../pages/MessagePage/reducer";
import {MESSAGE_STATUS} from "../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../Icon";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    username: state.login.user.username,
    roles: state.login.user.roles,
    tenant_name: state.login.user.tenant_name,
});

interface IProps {
    username: string
    roles: any[]
    tenant_name: string
    placeholder: string
    data: any
    loading: boolean

    onSend(params: any): void

    readMessage(payload: any): void
}

class ChatInput extends Component<IProps> {
    static defaultProps = {
        placeholder: ''
    }

    state = {message: '', isFocus: false, isBlock: false}

    inputRef: HTMLDivElement | any = React.createRef<HTMLDivElement>();

    handleInput = () => {
        this.handleSeen();
        const {textContent} = this.inputRef.current;
        this.setState({message: textContent})
    }

    handleSend = () => {
        const {loading} = this.props;
        if (loading)
            return;

        const {message} = this.state;
        if (message.length === 0)
            return;
        this.setState({message: ''}, () => {
            const {username, roles, tenant_name} = this.props;
            const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];

            const data = {
                user_tenant_name: tenant_name,
                message,
                timestamp: Date.now(),
                username,
                roles,
                uuid: uint32.toString(16)
            }
            this.props.onSend(data)
            this.inputRef.current.textContent = '';
        })
    }

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.handleSend();
            e.preventDefault();
            return false
        }
    }

    handleFocus = () => {
        this.setState({isFocus: true})
    }

    handleClick = () => {
        this.handleSeen();
    }

    handleSeen = () => {
        const {data} = this.props;
        if (!data.message)
            return;

        const {status} = data;

        if (status === MESSAGE_STATUS.NEW)
            this.props.readMessage(data);
    }

    render() {
        const {placeholder} = this.props;
        const {message, isFocus} = this.state;
        const {id} = this.props.data.group_chat;

        return <div className={styles['wrapper-chat-input']}>
            {message.length === 0 && <div
                aria-hidden="true"
                className={styles.placeholder}
            >
                {placeholder}
            </div>}
            <div className={styles['wrapper-input']} data-is-focus={isFocus}>
                <div
                    id={'input-' + id}
                    tabIndex={1}
                    className={styles.input}
                    ref={this.inputRef}
                    contentEditable={true}
                    onInput={this.handleInput}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onClick={this.handleClick}
                    onBlur={() => this.setState({isFocus: false})}
                />
                <div className={[styles.btSend, 'bt-primary'].join(' ')} onClick={this.handleSend}>
                    <Icon icon={ICON_NAME.SEND_MESSAGE}/>
                </div>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    readMessage: messageActions.readMessageRequest
})(ChatInput);