import React, {useCallback, useEffect} from 'react';
import GoogleMap from "../../../../contexts/WorldMapContext/GoogleMap";
import {MapMouseEvent, useMap} from "@vis.gl/react-google-maps";
import {useSelector} from "../../../../util/store/store";
import {routeActions} from "../../reducer";
import {useDispatch} from "react-redux";
import {RouteMode} from "../../constatns";
import {colors, SeaWayPolyline} from "../../../../contexts/WorldMapContext/Polyline";
import {FactoryMarker, PointMarker, SiteMarker} from "../../../../contexts/WorldMapContext/Marker";
import {IRoute} from "../../../../util/varibles/interface";

const Seaway: React.FC<{
    data: IRoute,
    routeIndex: number,
}> = ({data, routeIndex}) => {
    const mode = useSelector(state => state.route.mode);
    const showId = useSelector(state => state.route.showId);

    const {id, points} = data;
    const color = colors[routeIndex];

    const seaway = points.map((item, subI) => ({
        id: subI,
        color,
        lat: item.latitude,
        lng: item.longitude,
    }))

    return <>
        <SeaWayPolyline
            key={id}
            data={{points: seaway}}
            show={mode === RouteMode.VIEW}
            width={showId === routeIndex ? 3 : 1}
            color={color}
        />
        {seaway.map((point, i) => (i && (i < seaway.length - 1)) && <PointMarker
            key={point.id}
            show={mode === RouteMode.VIEW}
            data={point}
        />)}
    </>
}

const SeawayEdit = () => {
    const route = useSelector(state => state.route.route);
    const mode = useSelector(state => state.route.mode);
    const dispatch = useDispatch();

    const {id, points = [], index = 0} = route || {};
    const color = colors[index];

    const seaway = points.map((item, subI) => ({
        id: subI,
        name: `${subI}`,
        color,
        lat: item.latitude,
        lng: item.longitude,
    }))

    const handleSeawayClick = useCallback((e: google.maps.MapMouseEvent) => {
        const {latLng} = e;
        if (!latLng)
            return;
        const latitude = latLng.lat() || 0;
        const longitude = latLng.lng() || 0;
        const position = {minDistance: 9999999999, index: -1};
        for (let i = 0, len = seaway.length - 1; i < len; i++) {
            const dist = google.maps.geometry.spherical.computeDistanceBetween(seaway[i], latLng);
            const dist1 = google.maps.geometry.spherical.computeDistanceBetween(seaway[i + 1], latLng);
            const dist2 = google.maps.geometry.spherical.computeDistanceBetween(seaway[i], seaway[i + 1]);
            const minDistance = dist + dist1 - dist2;
            if (minDistance < position.minDistance) {
                position.minDistance = minDistance;
                position.index = i + 1;
            }
        }
        dispatch(routeActions.updatePoint({marker: {latitude, longitude}, position: position.index}))
    }, [dispatch, seaway]);

    const handleUpdate = useCallback((subI: number, e: google.maps.MapMouseEvent) => {
        const latitude = e.latLng?.lat() || 0;
        const longitude = e.latLng?.lng() || 0;
        dispatch(routeActions.updatePoint({marker: {latitude, longitude}, position: subI, isDelete: true}))
    }, [dispatch]);

    return <>
        <SeaWayPolyline
            key={id}
            data={{points: seaway}}
            show={mode === RouteMode.EDIT}
            width={3}
            color={color}
            onContextMenu={handleSeawayClick}
        />
        {seaway.map((point, i) => (i && (i < seaway.length - 1)) && <PointMarker
            key={point.id}
            data={point}
            show={mode === RouteMode.EDIT}
            onUpdate={e => handleUpdate(i, e)}
        />)}
    </>
}

const Seaways: React.FC = () => {
    const routes = useSelector(state => state.route.routes);
    return <>
        {routes.map((item, i) => <Seaway key={item.id} data={item} routeIndex={i}/>)}
    </>
}

const locationMarker: { [id: string]: Function } = {
    site: SiteMarker,
    factory: FactoryMarker
}

const Locations: React.FC = () => {
    const {source, destination} = useSelector(state => state.route.params);
    const map = useMap();

    useEffect(() => {
        if (source && destination) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(source.position?.latitude, source.position?.longitude));
            bounds.extend(new google.maps.LatLng(destination.position?.latitude, destination.position?.longitude));
            map?.fitBounds(bounds);
        }
    }, [source, destination, map]);

    return <>
        {[source, destination].map(item => {
            const {field} = item || {};
            const Marker = locationMarker[field];
            if (Marker)
                return <Marker key={item.id} data={item}/>
            return null;
        })}
    </>
}

const Map: React.FC = () => {
    const mode = useSelector(state => state.route.mode);
    const dispatch = useDispatch();

    const handleMapClick = useCallback((event?: MapMouseEvent) => {
        const {lat = 0, lng = 0} = event?.detail.latLng || {};
        dispatch(routeActions.updatePoint({marker: {latitude: lat, longitude: lng}}))
    }, [dispatch]);

    return <div className='h-full w-full'>
        <GoogleMap onContextmenu={mode !== RouteMode.EDIT ? undefined : handleMapClick}>
            <Seaways/>
            <SeawayEdit/>
            <Locations/>
        </GoogleMap>
    </div>;
};

export default Map;
