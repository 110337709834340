import React, {Component} from 'react';
import styles from './style.module.scss';
import {connect} from "react-redux";
import {VIEW_MODE} from "../../util/varibles/permission";
import {loginActions} from "../../pages/LoginPage/reducer";
import Operations from "./Operations";
import {IOperation} from "../../util/varibles/interface";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {IVesselOfUser} from "../../pages/LoginPage/constants";
import {getSetting} from "../../util/varibles/defaultSetting";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {
        viewMode: getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE),
    }
}

interface IProps {
    viewMode: VIEW_MODE
    full?: boolean

    onUpdate?(args: any): void

    onTracking?(data: IOperation | { operation: undefined, vessel: IVesselOfUser }): any

    getOpsOfVessel(): void
}

class TrackingOp extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        if (this.props.viewMode === VIEW_MODE.VESSEL) {
            this.props.getOpsOfVessel();
        }
    }

    render() {
        if (this.props.viewMode !== VIEW_MODE.VESSEL)
            return null;

        return <div className={styles.page} data-full={this.props.full}>
            <Operations onTracking={this.props.onTracking}/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getOpsOfVessel: loginActions.getOpsOfVesselRequest,
})(TrackingOp);
