import React, {useEffect, useRef} from 'react';
import styles from "./style.module.scss";
import {checkLimit, cloneObj} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import DragEl from "../../../_Component/DragEl";
import {
    getParentEl,
    TASK_TYPE,
    taskTypes,
    TREATMENT_TYPE,
    treatmentType
} from "../../../../../../../util/varibles/constants";
import {IPropsChildren, ISorting, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import ReactDOM from "react-dom/client";
import Setting from "./Setting";
import LineMiddle from "../../../_Component/LineMiddle";
import {callEventEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../../contexts/EditOperationContext";

const view: { [id: string]: (data: TTaskTreatment) => { label: string, value: string }[] } = {
    [TASK_TYPE.TREATMENT]: (data: TTaskTreatment) => {
        const {name = ''} = taskTypes[TASK_TYPE.TREATMENT];
        const {treatment_type} = data;
        const {name: subName = ''} = treatmentType[treatment_type || ''] || {};
        return [{label: name, value: subName}];
    },
    [TASK_TYPE.MOVE]: (data: TTaskTreatment) => {
        const {name = ''} = taskTypes[TASK_TYPE.MOVE];
        const {destination_name = ''} = data;
        return [{label: name, value: destination_name}];
    },
    [TASK_TYPE.COMBINE]: (data: TTaskTreatment) => {
        const {name = ''} = taskTypes[TASK_TYPE.COMBINE];
        const {type_name = ''} = data;
        return [{label: name, value: type_name}];
    }
}

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps extends IPropsChildren {
    unit: IUnitWhenEdit
    data: TTaskTreatment;
    isError: boolean
    isAllowDrag: boolean
    condition?: string
    taskParentId?: string

    onChange(change: Partial<TTaskTreatment>): void

    onDelete?(): void
}

let dragEl: HTMLDivElement | any, levelMode = 'all';
const WrapperTask: React.FC<IProps> = ({
                                           data,
                                           unit,
                                           isAllowDrag,
                                           isError,
                                           condition,
                                           taskParentId,
                                           children,
                                           onChange,
                                           onDelete
                                       }) => {
    const {elementId, type, group_id, is_support_vessel, treatment_type} = data;
    const {id: unitId, isLock} = unit;
    const rootRef = useRef<HTMLDivElement>(null);
    const set = setEditOp();
    const {turnOnCalculate} = callEventEditOp();

    useEffect(() => {
        return () => {
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [])

    const handleMouseDown = () => {
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        dragEl = document.createElement('div');
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '2000';
        dragEl.style.pointerEvents = 'none';
        dragEl.style.display = 'none';
        const root = ReactDOM.createRoot(dragEl as HTMLElement);
        const {type} = data || {}
        const {[type || '']: content = () => [{label: 'Task', value: '-'}]} = view;
        root.render(<DragEl data={content(data)}/>);
        const parentEl = getParentEl();
        parentEl.append(dragEl);
        levelMode = 'all';
    }

    const handleMouseMove = (e: any) => {
        const parentEl = document.getElementById('treatment-operation');
        if (!parentEl)
            return;

        parentEl.dataset.isDrag = levelMode;

        if (rootRef.current)
            rootRef.current.dataset.isDrag = "true";

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
        }
        document.body.style.cursor = 'unset';

        const parentEl = document.getElementById('treatment-operation');
        if (!parentEl)
            return;

        delete parentEl.dataset.isDrag;
        if (rootRef.current)
            delete rootRef.current.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        if (!e.target)
            return;
        const {
            taskid,
            unitid: newPosition,
            condition: newCondition,
            taskparentid: newTaskParentId,
            mode
        } = e.target.dataset;
        if (mode !== 'change' || unitId === undefined)
            return;
        e.target.dataset.hover = false;
        const onDelete = taskParentId ? (value: IUnitWhenEdit): IUnitWhenEdit => {
            const tasks = cloneObj(value.tasks || []) as TTaskTreatment[];
            return {
                ...value,
                tasks: tasks.map(item => {
                    if (item.group_id === taskParentId) {
                        const {sub_tasks = {}} = item as ISorting
                        sub_tasks[condition || ''] = sub_tasks[condition || ''].filter(sub => sub.group_id !== group_id)
                        return {...item, sub_tasks}
                    }
                    return item
                })
            }
        } : (value: IUnitWhenEdit): IUnitWhenEdit => {
            const {tasks = []} = value;
            return {...value, tasks: tasks.filter(item => item.group_id !== group_id)}
        }

        const onAdd = newTaskParentId ? (value: IUnitWhenEdit): IUnitWhenEdit => {
                const tasks = cloneObj(value.tasks || []) as TTaskTreatment[];
                return {
                    ...value,
                    tasks: tasks.map(item => {
                        if (item.group_id === newTaskParentId) {
                            let {sub_tasks = {}} = item as ISorting
                            if (!taskid) {
                                sub_tasks[newCondition] = [data, ...sub_tasks[newCondition]]
                            } else {
                                sub_tasks[newCondition] = sub_tasks[newCondition].reduce((subRs: TTaskTreatment[], sub) =>
                                    sub.group_id === taskid ? [...subRs, sub, data] : [...subRs, sub], [])
                            }
                            return {...item, sub_tasks}
                        }
                        return item
                    })
                }
            }
            : (value: IUnitWhenEdit): IUnitWhenEdit => {
                const {tasks = []} = value;
                if (!taskid)
                    return {...value, tasks: [data, ...tasks]}
                else {
                    const result = cloneObj(tasks);
                    return {
                        ...value,
                        tasks: result.reduce((subRs: TTaskTreatment[], item: TTaskTreatment) =>
                            item.group_id === taskid ? [...subRs, item, data] : [...subRs, item], [])
                    }
                }
            }
        set(({units: prev}) => {
            const old = cloneObj(prev);
            return {
                units: Object.keys(old).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                    if (key === unitId)
                        rs[key] = onDelete(rs[key])

                    if (key === newPosition)
                        rs[key] = onAdd(rs[key])

                    return rs;
                }, old)
            }
        })
        turnOnCalculate()
    }

    return <div ref={rootRef} className={styles['container-task']}>
        {(isAllowDrag && type) &&
            <div className={stylesContainer['bt-drag']} onMouseDown={handleMouseDown}/>}
        <div
            id={`${elementId}`}
            className={[styles.task, stylesContainer['wrapper-task']].join(' ')}
            data-task={type}
            data-error={isError}
            data-allow-drag={isAllowDrag}
        >
            {children}
            <div className={[styles.menu, stylesContainer.menu].join(' ')}>
                {is_support_vessel && <div className={styles['icon-support-vessel']}/>}
                {!isLock && <Setting data={data} onChange={onChange} onDelete={onDelete}/>}
            </div>
        </div>
        {(!unit.isLock && isAllowDrag && treatment_type !== TREATMENT_TYPE.SORTING) &&
            <div className={[stylesContainer['line-middle'], styles['line-middle']].join(' ')} data-level='task'>
                <LineMiddle
                    containerId={'treatment-operation'}
                    attr={{
                        unitId: unit.id,
                        taskParentId,
                        condition,
                        taskId: data.group_id
                    }}
                />
            </div>}
    </div>;
};

export default WrapperTask;
