import React, {useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss';
import stylesPage from '../style.module.scss';
import {connect} from "react-redux";
import {OPERATION_TYPE} from "../../../util/varibles/constants";
import OperationType from "./OperationType";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {AppState} from "../../../util/store/store";

const list = [OPERATION_TYPE.HARVEST, OPERATION_TYPE.TREATMENT, OPERATION_TYPE.TRANSPORT];

const mapStateToProps = (state: AppState) => ({
    density: state.login.user.tenant.density || {},
});

interface IProps {
    density: any
}

const Density: React.FC<IProps> = ({density}) => {
    const [source, setSource] = useState(density);

    useEffect(() => {
        setSource(density);
    }, [density])

    const handleReset = useCallback((type: OPERATION_TYPE) => {
        const {[type]: data} = density;
        setSource({...source, [type]: data})
    }, [source, density])

    return <div className={stylesPage.section}>
        <div className={stylesPage.header}>
            <div className={stylesPage.title}>
                <Icon icon={ICON_NAME.TANK} size='large'/>
                <div>Operation's density</div>
            </div>
            <div className={stylesPage.subTitle}>
                Define Operation’s density to have this info. when planning an operation, in operation detail.
            </div>
        </div>
        <div className={styles.body}>
            {list.map(item => <OperationType
                key={item}
                density={source}
                type={item}
                onChange={setSource}
                onReset={handleReset}
            />)}
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(Density)
