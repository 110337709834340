import React from 'react';
import styles from "./style.module.scss";
import {CERTAIN_TYPE, certainType, OP_STATUS, statusHideCertain} from "../../../../../util/varibles/constants";
import {TOperation} from "../../../../../util/varibles/interface";
import {useSelector} from "../../../../../util/store/store";
import {selectOneSetting} from "../../../../../util/store/selectors";
import {PreferenceSetting} from "../../../../../util/varibles/userSetting";


interface IProps {
    operation: TOperation
    status: OP_STATUS
}

const Certain: React.FC<IProps> = ({operation, status}) => {
    const {certain_mode = CERTAIN_TYPE.NONE} = operation;
    const isCertainty = useSelector(selectOneSetting(PreferenceSetting.SHOW_CERTAINTY))

    if (statusHideCertain.indexOf(status) !== -1 || !isCertainty)
        return null;

    const {icon, name, color} = certainType[certain_mode] || {icon: null, name: '', color: ''};
    const style: any = {'--color': color}
    return <div className={styles['certain']} data-icon={icon} title={name} style={style}/>
};

export default Certain;
