import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired} from "./constants";
import styles from "./style.module.scss";


interface IProps extends IPropsPropertyElement {

}

class CheckboxElement extends Component<IProps> {
    render() {
        const {selected} = this.props;
        if (!selected) return null;

        const {label, properties: {required, options}} = selected;

        return <>
            <div className={styles['title']}>Multiple Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Options</label>
                <textarea value={options}
                          onChange={(e) => onChangeProperty({options: e.currentTarget.value}, this.props)}
                />
                <span>Type your options to select from. Separate each on a new line.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Number to columns</label>
                {/*<Select options={ columns } styles={ selectStyle }*/}
                {/*        value={ JSON.parse(numberOfColumns) }*/}
                {/*        onChange={ (item: any) => onChangeProperty({ numberOfColumns: JSON.stringify(item) }, this.props) }*/}
                {/*/>*/}
                <span>Spread options side by side into specified number of columns.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Selected by default</label>
                {/*<Select options={ data } styles={ selectStyle }*/}
                {/*        isMulti*/}
                {/*        closeMenuOnSelect={ false }*/}
                {/*        value={ JSON.parse(optionDefault) }*/}
                {/*        onChange={ (item: any) => onChangeProperty({ optionDefault: JSON.stringify(item) }, this.props) }*/}
                {/*/>*/}
                <span>Choose an option to be selected by default.</span>
            </div>
        </>;
    }
}

export default CheckboxElement;
