import React, {useCallback} from 'react';
import styles from "./style.module.scss";
import {Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {dashboardActions} from "../../reducer";
import {MAP_ELEMENT} from "../../../../contexts/WorldMapContext/constants";
import {selectDashboard} from "../../../../util/store/selectors";

interface IProps {
}

const TrackVessel: React.FC<IProps> = () => {
    const tracking = useSelector(selectDashboard.tracking);
    const target = useSelector(selectDashboard.target) || {};
    const dispatch = useDispatch();

    const onChange = useCallback((checked: boolean) => {
        dispatch(dashboardActions.updateTracking(checked));
    }, [dispatch]);

    return <div className={styles.wrapper} data-show={target.targetType === MAP_ELEMENT.VESSEL}>
        <span>Track vessel: </span>
        <Switch checked={tracking} onChange={onChange}/>
    </div>;
};

export default TrackVessel;

