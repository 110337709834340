import React, {useCallback, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {RouteMode} from "../../constatns";
import styles from './style.module.scss';
import InputCheckbox from "../../../../components/Basic/Checkbox";
import {Button, Input} from "antd";
import {IPoint, IRoute} from "../../../../util/varibles/interface";
import {openAutoRoute} from "../PopupAutoRoute";
import {routeActions} from "../../reducer";
import {RouteService} from "../../../../util/services/route";
import {showErrorResponse} from "../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {AppState} from "../../../../util/store/store";
import {useMap} from "@vis.gl/react-google-maps";


const mapStateToProps = (state: AppState) => ({
    params: state.route.params,
    route: state.route.route,
    routes: state.route.routes,
    loadingAuto: state.route.loadingAuto,
    mode: state.route.mode,
    autoRouteLicense: state.login.user.tenant.auto_route_license
});

interface IProps {
    params: any
    routes: IRoute[]
    loadingAuto: boolean
    autoRouteLicense?: boolean
    mode: RouteMode
    route: any

    changeMode(payload: { mode: RouteMode, route: any }): void

    getAutoRoute(payload: any): void

    getConnectId(): string
}

const PopupEditRoute: React.FC<IProps> = (props) => {
    const {route, routes, mode} = props;
    const {id, name = ''} = route || {};
    const {source, destination} = props.params;
    const [loading, setLoading] = useState(false);
    const map = useMap();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const {points = [], id = null, name = '',} = route || {};
        if (points.length <= 2)
            return;
        const params = {
            router_id: id,
            source_id: source.id,
            destination_id: destination.id,
            default: route.default,
            type: [source.field === 'factory' ? 'factory' : 'site', destination.field === 'factory' ? 'factory' : 'site'].join('-'),
            name,
            points: points.map((item: any, index: number) => ({
                point_id: index,
                name: item.name || `Way point ${index}`,
                latitude: item.latitude,
                longitude: item.longitude,
                depth: 0,
            }))
        };
        let fetch: (params: any) => Promise<IRoute>, action: any, title: any, notifyCode: any;
        if (id === '-') {
            notifyCode = NotifyCode.S1;
            fetch = RouteService.create;
            action = routeActions.addRoute
            title = 'Create route';
        } else {
            notifyCode = NotifyCode.S2;
            fetch = RouteService.update;
            action = routeActions.updateRoute
            title = 'Update route';
        }

        setLoading(true);
        fetch(params)
            .then(rs => {
                const data = {...params, ...rs};
                dispatch(action(data))
                dispatch(routeActions.changeMode(RouteMode.VIEW));
                dispatch(routeActions.show(data.id));
                if (data.default) {
                    dispatch(routeActions.updateDefault(data.id))
                }
                notify.success(notifyCode)([data.name]);
                setLoading(false);
            })
            .catch(error => {
                showErrorResponse(title + ' failed', error)
                setLoading(false);
            })
    };

    const handleDeleteMarker = (value: any) => {
        const position = value + 1;
        dispatch(routeActions.editRoute({
            ...route,
            points: route.points.reduce((list: any, item: any, index: number) => {
                if (position === index)
                    return list;
                return [...list, item]
            }, [])
        }))
    };

    const handleUpdate = (attr: any) => {
        dispatch(routeActions.editRoute({...route, ...attr}))
    }

    const handleCancel = () => {
        const data = routes.find(item => item.id === id) || routes[0] || {};
        props.changeMode({mode: RouteMode.VIEW, route: data})
    }

    const handleAutoRoute = () => {
        openAutoRoute({
            onOk: (params: any) => {
                const {mode, draft, airDraft} = params;
                const {
                    latitude: source_lat = 0,
                    longitude: source_lng = 0
                } = source.sea_position || source.position || {};
                const {
                    latitude: destination_lat = 0,
                    longitude: destination_lng = 0
                } = destination.sea_position || destination.position || {};

                const body = {
                    departure: {
                        position: `${source_lat} ${source_lng}`,
                        port: {}
                    },
                    arrival: {
                        position: `${destination_lat} ${destination_lng}`,
                        port: {}
                    },
                    mode,
                    safety: {draft, airDraft},
                    request_id: ['auto-route', Date.now()].join('-'),
                    connection_id: props.getConnectId()
                }

                props.getAutoRoute(body);
            }
        })
    }

    const handleClick = useCallback((point: IPoint) => {
        if (map) {
            const {latitude, longitude} = point;
            const position = new google.maps.LatLng(latitude, longitude);
            map.setCenter(position);
            map.setZoom(12);
        }
    }, [map]);


    if (!route || mode === RouteMode.VIEW) return null;

    let points = [...route?.points] || [];
    points = points.slice(1);
    points.pop();

    return <div className={styles['container-edit']}>
        <div className={styles['route-bt-back']} onClick={handleCancel}>
            <span>Back</span>
        </div>
        <div className={styles.title}>
            {!id ?
                <>Create new route from <b>{source.name}</b>
                    <span> &#8594; </span>
                    <b>{destination.name}</b></> :
                'Update route'}
        </div>
        <div className={styles.info}>
            <div className={styles.name}>
                <span>Name:</span>
                <Input
                    className='h-36 border-r-40'
                    value={name}
                    onChange={(e: any) => handleUpdate({name: e.target.value})}
                />
            </div>
            <InputCheckbox
                checked={route.default}
                label="Default"
                onChange={(e: any) => handleUpdate({default: e.target.checked})}
            />
        </div>
        <div className={[styles.points, 'scroll-small'].join(' ')}>
            {points.length === 0 ? <>
                <div className={styles.empty}>There is no way point yet <br/> Right click on the map to
                    create one
                </div>
            </> : <>
                <div className={styles.source} data-point-field={source.field}>{source.name}</div>
                {points.map((item, index: number) => <div
                    key={"position" + index}
                    id={`position_${index}`}
                    className={styles.point}
                    onClick={() => handleClick(item)}
                >
                        <span>
                            <span>{index + 1}</span>
                            Way point {index + 1}
                        </span>
                    <div className="route-action mr-2">
                        <div className="route-icon-delete" onClick={() => handleDeleteMarker(index)}/>
                    </div>
                </div>)}
                <div className={styles.source} data-point-field={destination.field}>{destination.name}</div>
            </>}
        </div>
        <div className={styles.footer}>
            {props.autoRouteLicense ? <Button
                className='bt-default h-36'
                onClick={handleAutoRoute}
                loading={props.loadingAuto}
            >
                Auto route
            </Button> : <div/>}
            <Button className="bt-primary" type='primary'
                    disabled={points.length <= 2}
                    loading={loading}
                    onClick={handleSubmit}
            >
                Save route
            </Button>
        </div>
        <div className={styles.description}>
            <p>Right click to create new way point.</p>
            <p>Drag a way point to change its position.</p>
        </div>
    </div>;
};

export default connect(mapStateToProps, {
    changeMode: routeActions.changeMode,
    getAutoRoute: routeActions.autoRouteRequest
})(PopupEditRoute);
