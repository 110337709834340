import {aubergineMapStyle, darkMapStyle, silverMapStyle} from "./map_style";

export enum MAP_STYLE {
    AUBERGINE = 'aubergine',
    ROADMAP = 'roadmap',
    DARK = 'dark',
    SILVER = 'silver',
    SATELLITE = 'satellite',
}

export const CENTER_DEFAULT = {lat: 61.592657, lng: 6.317605};
export const ZOOM_DEFAULT = 7;

export const mapType: {
    [id: string]: {
        id: MAP_STYLE,
        name: string,
        style?: any,
    }
} = {
    [MAP_STYLE.AUBERGINE]: {id: MAP_STYLE.AUBERGINE, name: 'Default', style: aubergineMapStyle},
    [MAP_STYLE.ROADMAP]: {id: MAP_STYLE.ROADMAP, name: 'Road map'},
    [MAP_STYLE.DARK]: {id: MAP_STYLE.DARK, name: 'Dark', style: darkMapStyle},
    [MAP_STYLE.SILVER]: {id: MAP_STYLE.SILVER, name: 'Silver', style: silverMapStyle},
    [MAP_STYLE.SATELLITE]: {id: MAP_STYLE.SATELLITE, name: 'Satellite'},
}

export const mapTypes = [
    mapType[MAP_STYLE.AUBERGINE],
    mapType[MAP_STYLE.ROADMAP],
    mapType[MAP_STYLE.DARK],
    mapType[MAP_STYLE.SILVER],
    mapType[MAP_STYLE.SATELLITE],
]

export const getMetersPerPx = (latitude: number, zoomLevel: number) =>
    156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoomLevel);

export const limitShowZoom = 10;

export enum MAP_ELEMENT {
    SITE,
    FACTORY,
    VESSEL,
    POINT,
    DISEASED_ZONE,
    PRODUCTION_AREA,
    POLYLINE,
    DIALOG,
    POI,
}

interface IMarkerIcon {
    id: MAP_ELEMENT,
    zIndex: number
}

export const markerIcon: { [key: string]: IMarkerIcon } = {
    [MAP_ELEMENT.SITE]: {id: MAP_ELEMENT.SITE, zIndex: 3},
    [MAP_ELEMENT.FACTORY]: {id: MAP_ELEMENT.FACTORY, zIndex: 4},
    [MAP_ELEMENT.VESSEL]: {id: MAP_ELEMENT.VESSEL, zIndex: 5},
    [MAP_ELEMENT.POINT]: {id: MAP_ELEMENT.POINT, zIndex: 1},
    [MAP_ELEMENT.POI]: {id: MAP_ELEMENT.POI, zIndex: 2}
}

export function inside(point: number[], vs: { lat: number, lng: number }[]) {
    const x = point[0], y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i].lat, yi = vs[i].lng;
        const xj = vs[j].lat, yj = vs[j].lng;

        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}
