import React, {useEffect, useState} from 'react';
import {IFactory, ITenant} from "../../../util/varibles/interface";
import {DELIVERY_TYPE, deliveryTypes, propsModal} from "../../../util/varibles/constants";
import styles from "../style.module.scss";
import Header from "../../../components/Popup/Component/Header";
import {Button, Checkbox, Col, Drawer, Form, Input, Row, Select} from "antd";
import {Rule} from "../../../util/varibles/validation";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import Footer from "../../../components/Popup/Component/Footer";
import {isEmpty, renderOpts, showErrorResponse} from "../../../util/varibles/global";
import {CENTER_DEFAULT} from "../../../contexts/WorldMapContext/constants";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {FactoryService} from "../../../util/services/factory";
import GoogleMap from "../../../contexts/WorldMapContext/GoogleMap";
import {WorldMapProvider} from "../../../contexts/WorldMapContext";
import {FactoryMarker} from '../../../contexts/WorldMapContext/Marker';
import {useMap} from "@vis.gl/react-google-maps";

const Body: React.FC<{
    data?: IFactory,
    customers: { label: string, value: string }[],
    reload(): void,
    handleClose(): void
}> = ({data, customers, reload, handleClose}) => {
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState(true);

    const [form] = Form.useForm<IFactory | { delivery_types: any[] }>();
    const {latitude = '', longitude = ''} = Form.useWatch('position', form) || {};
    const name = Form.useWatch('name', form);
    const openTime = Form.useWatch('work_time', form);
    const map = useMap();
    const isLocation = !isEmpty(latitude) && !isEmpty(longitude);

    useEffect(() => {
        if (data) {
            const {deliver_type, work_time} = data;
            let delivery_types;
            if (Object.keys(deliver_type).length > 0 && deliver_type.direct) {
                const {direct, ...args} = deliver_type;
                delivery_types = Object.keys(args).reduce((list: any, key: any) => {
                    const {speed, id} = args[key];
                    const split = id.split('_');
                    split.pop();
                    return [...list, {...args[key], type: split.join('_'), speed: speed / million}];
                }, [{...direct, type: DELIVERY_TYPE.DIRECT, speed: direct.speed / million}])
            } else {
                delivery_types = [directType]
            }
            form.setFieldsValue({...data, delivery_types, work_time: work_time / 60});
        } else
            form.setFieldsValue(initForm)
    }, [data, form]);

    useEffect(() => {
        if (center && map && isLocation) {
            map.setCenter({lat: +latitude, lng: +longitude});
        }
    }, [center, isLocation, map, latitude, longitude]);

    const handleChangeDefault = (index: any) => {
        const delivery_types = form.getFieldValue('delivery_types')
        form.setFieldsValue({
            delivery_types: delivery_types.map((item: any, i: number) => i === index
                ? {...item, default: true} : {...item, default: false})
        })
    }

    const handleAddDeliveryType = () => {
        const delivery_types = form.getFieldValue('delivery_types')
        delivery_types[delivery_types.length - 1] = {id: `${Date.now()}`, default: false};
        form.setFieldsValue({delivery_types})
    }

    const handleDeleteDeliveryType = (index: any) => {
        const delivery_types = form.getFieldValue('delivery_types')
        const {default: isDefault} = delivery_types[index];
        if (isDefault) {
            delivery_types[0].default = true;
            form.setFieldsValue({
                delivery_types: delivery_types.map((item: any, i: number) => i === index
                    ? {...item, default: true} : {...item, default: false})
            })
        }
    }

    const handleSubmit = (values: any) => {
        const {
            email = '',
            address = '',
            phone = '',
            customer_id = '',
            tenant_id = '',
            waiting_unit_speed = 0,
            discharging_speed = 0,
            work_time = 0,
            depth = 0,
            position = {latitude: CENTER_DEFAULT.lat, longitude: CENTER_DEFAULT.lng},
            delivery_types = [],
            ...rest
        } = values

        const factory = {
            ...data || {},
            ...rest,
            email,
            address,
            phone,
            customer_id,
            owner_id: tenant_id,
            waiting_unit_speed: Number(waiting_unit_speed),
            discharging_speed: Number(discharging_speed),
            work_time: work_time * 60,
            position: {
                point_id: 0,
                name: "",
                depth: Number(depth),
                longitude: Number(position.longitude),
                latitude: Number(position.latitude)
            },
            deliver_type: delivery_types.reduce((list: any, item: any) => {
                list[item.id] = {...item, speed: item.speed * million};
                return list;
            }, {}),
            tenant_id: String(values.tenant_id)
        }

        const {api, successCode, errorCode} = actions[`${!factory.id}`];
        setLoading(true);
        new Promise(resolve => resolve(api(factory)))
            .then(() => {
                setLoading(false);
                notify.success(successCode)([factory.name]);
                reload();
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(notify[errorCode]([factory.name]), error);
            })
    }


    return <Row gutter={[0, 16]} className='h-full w-full'>
        <Col span={13} className={styles.form}>
            <Form form={form} onFinish={handleSubmit} className='pr-[10px]' layout='vertical'>
                <div className={styles.formLine}>
                    <Form.Item label="Name" name="name" rules={[Rule.Require("Name")]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Email" name="email"><Input/></Form.Item>
                </div>
                <Form.Item label="Address" name="address"><Input/></Form.Item>
                <div className={styles.formLine}>
                    <Form.Item label="Phone" name="phone"><Input/></Form.Item>
                    <Form.Item label="Work time" name="work_time">
                        <Input suffix={<span className='text-comment'>hours</span>}/>
                    </Form.Item>
                </div>
                <div className={styles.formLine}>
                    <Form.Item label="Owner" name="tenant_id" rules={[Rule.Require("Owner")]}>
                        <Select className='select-single' options={customers}/>
                    </Form.Item>
                    <Form.Item label="Customer" name="customer_id" rules={[Rule.Require("Customer")]}>
                        <Select className='select-single' options={customers}/>
                    </Form.Item>
                </div>
                <div className={styles.formLine}>
                    <Form.Item label="Waiting unit speed" name="waiting_unit_speed">
                        <Input suffix={<span className='text-comment'>tons</span>}/>
                    </Form.Item>
                    <Form.Item label="Discharging speed" name="discharging_speed">
                        <Input suffix={<span className='text-'>tons</span>}/>
                    </Form.Item>
                </div>
                <div className={styles.formLine}>
                    <Form.Item label="Latitude" name={["position", "latitude"]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Longitude" name={["position", "longitude"]}>
                        <Input/>
                    </Form.Item>
                </div>
                <Form.List name="delivery_types">
                    {(fields: any, {add, remove}: any) => <>
                        <div className={styles['delivery_type_header']}>
                            <div className={styles['delivery_type_title']}>Delivery type</div>
                            <Form.Item className='mb-0'>
                                <Button
                                    className='bt-primary bt-plus'
                                    type="primary" shape="circle" icon={<PlusOutlined/>}
                                    onClick={() => {
                                        add();
                                        handleAddDeliveryType();
                                    }}
                                />
                            </Form.Item>
                        </div>
                        {fields.map(({key, name, fieldKey, ...restField}: any, index: any) =>
                            <div className={styles['delivery_type_row']} key={key}>
                                <Form.Item
                                    {...restField}
                                    className='hidden'
                                    name={[name, 'id']}
                                    key={[fieldKey, 'id']}
                                    rules={[Rule.Require('Code')]}
                                >
                                    <Input placeholder="Code"/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    key={[fieldKey, 'name']}
                                    name={[name, 'name']}
                                    rules={[Rule.Require('Name')]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    key={[fieldKey, 'type']}
                                    name={[name, 'type']}
                                    rules={[Rule.Require('Type')]}
                                >
                                    <Select
                                        placeholder='Type'
                                        className='select-single'
                                        options={renderOpts(deliveryTypes)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    key={[fieldKey, 'speed']}
                                    name={[name, 'speed']}
                                    rules={[Rule.Require('Speed')]}
                                >
                                    <Input
                                        placeholder="Speed"
                                        suffix={<span className='text-comment'>tons/h</span>}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    key={[fieldKey, 'default']}
                                    name={[name, 'default']}
                                    valuePropName="checked"
                                >
                                    <Checkbox className='mt-[3px]' onChange={() => handleChangeDefault(index)}>
                                        Default
                                    </Checkbox>
                                </Form.Item>
                                {index > 0 && <Button
                                    className='bt-danger'
                                    shape="circle" icon={<DeleteOutlined/>}
                                    danger={true}
                                    type="text" onClick={() => {
                                    handleDeleteDeliveryType(index);
                                    remove(name);
                                }}/>}
                            </div>)}
                    </>}
                </Form.List>
                <Footer
                    className={styles.footer}
                    cancel={{click: handleClose}}
                    ok={{loading, text: !data ? 'Create new Factory' : 'Save'}}
                />
            </Form>
        </Col>
        <Col span={11}>
            <div className='h-full w-full'>
                <GoogleMap
                    onDragstart={() => setCenter(false)}
                    onContextmenu={e => {
                        if (e) {
                            const {lat = 0, lng = 0} = e.detail.latLng || {};
                            const position = {latitude: lat, longitude: lng}
                            form.setFieldsValue({position});
                        }
                    }}
                >
                    <FactoryMarker
                        data={{
                            ...data,
                            name,
                            work_time: openTime * 60,
                            position: isLocation ? {latitude, longitude} : undefined
                        }}/>
                </GoogleMap>
            </div>
            <div className={styles.mapControl}>
                <Checkbox
                    className='mt-[3px]'
                    checked={center}
                    disabled={!latitude || !longitude}
                    onChange={e => {
                        const {checked} = e.target;
                        setCenter(checked)
                    }}
                >
                    Center
                </Checkbox>
            </div>
        </Col>
    </Row>
}


const actions = {
    true: {
        api: FactoryService.create,
        successCode: NotifyCode.S1,
        errorCode: NotifyCode.E22,
    },
    false: {
        api: FactoryService.update,
        successCode: NotifyCode.S2,
        errorCode: NotifyCode.E35,
    }
}

const million = 1000000;
const directType = {id: 'direct', name: 'Direct', type: DELIVERY_TYPE.DIRECT, default: true, speed: ''};
const initForm = {
    delivery_types: [directType]
}

interface IProps {
    data?: IFactory
    tenants: ITenant[]

    onClose(): void

    reload(): void
}

const PopupEdit: React.FC<IProps> = ({data, tenants, onClose, reload}) => {
    const [visible, setVisible] = useState(true);
    const [customers, setCustomers] = useState<{ label: string, value: string }[]>([]);

    useEffect(() => {
        setCustomers(tenants.map(({tenant_id, name}) => ({value: tenant_id, label: name})))
    }, [tenants]);

    const handleClose = () => {
        setVisible(false);
        onClose();
    }

    return <Drawer
        {...propsModal}
        className={styles.popupEdit}
        title={<Header
            title={data ? ('Update factory: ' + data.name) : 'Add factory'}
            onClose={handleClose}
        />}
        open={visible}
        width='100%'
        height='100%'
        placement='bottom'
        drawerRender={(node) => <WorldMapProvider>{node}</WorldMapProvider>}
        onClose={handleClose}
    >

        <Body data={data} customers={customers} reload={reload} handleClose={handleClose}/>
    </Drawer>;
};

export default PopupEdit;
