import React, {Component} from 'react';
import {connect} from 'react-redux';
import ListOfTreatment from "./ListOfTreatment";
import {combineTreatmentActions} from "./reducer";
import {ACTION} from "../../util/varibles/permission";
import {nextPage} from "../../util/library/Router";
import {PATHNAME} from "../../util/varibles/constants";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {permission: state.login.user.permission}
}

interface IProps {
    permission: any

    getLists(): void
}

class CombineTreatmentPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.getLists();
        if (!this.props.permission[ACTION.COMBINE_TREATMENT.VIEW]) {
            nextPage(PATHNAME.ACCESS_DENIED);
        }
    }

    render() {
        return <div className='m-header bgPage'>
            <div className='header-page'>
                <div className='title-page'>
                    <b>Combined treatment</b>
                </div>
            </div>
            <div className='body-page' data-box='true'>
                <ListOfTreatment/>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getLists: combineTreatmentActions.getListRequest
})(CombineTreatmentPage);