import React, {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {tabMessage} from "../../../../../../pages/MessagePage/constants";
import stylesContainer from "../style.module.scss";
import Message from "../../Message";
import {Spin} from "antd";
import styles from "../../../../../../pages/MessagePage/Component/Operation/style.module.scss";
import {getSubInfoChatOp} from "../../../../constants";
import {checkLoadMore} from "../../../../../../util/varibles/global";
import {messageActions} from "../../../../../../pages/MessagePage/reducer";
import {CHAT_TYPE, operationType} from "../../../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../../Icon";
import {useSelector} from "../../../../../../util/store/store";

interface IProps {
    onOpenConversation(conversation: any): void

    onAddConversation(): void
}


const Operation: React.FC<IProps> = (props) => {
    const operations = useSelector(state => state.message.operations);
    const operationsMore = useSelector(state => state.message.operationsMore);
    const loadingOperation = useSelector(state => state.message.loadingOperation);
    const operationsIndex = useSelector(state => state.message.operationsIndex);
    const chat = useSelector(state => state.message.chat);
    const dispatch = useDispatch();
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (operationsMore && !loadingOperation && rootRef.current) {
            checkLoadMore(rootRef.current, () => {
                dispatch(messageActions.getChatOfOpRequest({index: operationsIndex + 1}))
            })
        }

    }, [rootRef, chat]);

    const handleWheel = (e: any) => {
        if (!operationsMore || loadingOperation || !rootRef.current)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight} = rootRef.current;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;

        if (isScrolledToBottom && deltaY > 0) {
            dispatch(messageActions.getChatOfOpRequest({index: operationsIndex + 1}))
        }
    }

    return <>
        <div className={stylesContainer['title']}>
            <div data-lev='name'>
                {tabMessage[CHAT_TYPE.OPERATION].name}
            </div>
            <div data-lev='action'>
                <div className='bt-ghost' data-icon={true} onClick={props.onAddConversation}>
                    <Icon icon={ICON_NAME.ADD_2}/>
                </div>
            </div>
        </div>

        <div ref={rootRef} className={stylesContainer['container-content']} onWheel={handleWheel}>
            <div className={stylesContainer.content}>
                {operations.map((item: any) => {
                    const {group_chat = {}} = item || {};
                    const {id, name, operation_type} = group_chat;
                    const {icon} = operationType[operation_type] || {};
                    return <Message
                        title={<div className={styles['message-title']}>
                            <div>#{name}</div>
                            {getSubInfoChatOp(group_chat)}
                        </div>}
                        avatar={<div className={styles.avatar}>
                            <Icon icon={icon}/>
                        </div>}
                        key={id}
                        data={item}
                        onClick={() => props.onOpenConversation(item)}
                    />
                })}
            </div>
            {loadingOperation && <div className={stylesContainer.loading} data-empty={operations.length === 0}>
                <Spin/>
            </div>}
        </div>
    </>;
};

export default Operation;

