import React, {Component} from 'react';
import {connect} from 'react-redux';
import {vesselTypes} from "../../../../util/varibles/constants";
import Info from "./Info";
import S3Image from "../../../../components/S3Image";

import stylesContainer from '../../style.module.scss';
import styles from './style.module.scss';
import {IOperation, IPropsChildren} from "../../../../util/varibles/interface";
import {NavLink} from "react-router-dom";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    data: state.operationDetail.data
});

interface IProps extends IPropsChildren {
    data: IOperation
}

class Vessel extends Component<IProps> {
    static defaultProps = {
        children: null
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {data: dataOld} = this.props;
        const {data: dataNew} = nextProps;
        return Object.keys(dataOld).length === 0 && Object.keys(dataNew).length > 0
    }

    render() {
        const {data, children} = this.props
        if (!data.vessel) return null;
        const {id, name, type, image_url} = data.vessel;
        return <div className={stylesContainer.section}>
            <div className={[stylesContainer['header-box'], styles.header].join(' ')}>
                <NavLink to={'/vessel/' + id}>
                    <span className="mr-1">{name}</span>
                </NavLink>
                <div className={styles['name-boat']}>{vesselTypes[type - 1].name}</div>
            </div>
            <div className={[stylesContainer['body-box'], 'd-flex'].join(' ')}>
                <div className={styles['image-vessel']}>
                    <S3Image {...{imageUrl: image_url || '', height: null}}/>
                </div>
                {children ? children : <Info {...{data}}/>}
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Vessel);
