import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {AdvancedMarker, useAdvancedMarkerRef, useMap} from "@vis.gl/react-google-maps";
import Dialog from "./Dialog";
import styles from "./style.module.scss"
import {useWorldMap} from "./";
import {getMetersPerPx, MAP_ELEMENT, markerIcon} from "./constants";
import {diseaseType} from "../../util/varibles/constants";
import {checkZero, getHourMinute, getStatusOfOp, percentBrightness} from "../../util/varibles/global";
import DurationReel from "../../components/DurationReel";
import {colors} from "./Polyline";
import {AdvancedMarkerProps} from "@vis.gl/react-google-maps/src/components/advanced-marker";

export const vesselColors = [
    {strokeColor: '#FF8D68', fillColor: '#FFD24C'}, //Process vessel
    {strokeColor: '#1B6399', fillColor: '#5EB0ED'}, //Wellboat
    {strokeColor: '#EE2A2A', fillColor: '#FF988F'}, //Treatment
]
export const createIconVessel = ({...args}) => {
    const {width, length, metersPerPx, vesselType} = args;
    const minus = Math.round(length / 10 / metersPerPx * 10) / 10;
    let x = Math.round(width / 2 / metersPerPx * 10) / 10;
    let y = Math.round(length / 2 / metersPerPx * 10) / 10;
    let path;
    if (x < 3 || y < 3) {
        path = 'M -4.6 -6 L 0 -12 L 4.6 -6 L 4.6 9 L 0 5 L -4.6 9 Z';
        x = 9.2;
        y = 21;
    } else
        path = `M -${x} -${y - x}
            L 0 -${y + minus} 
            L ${x} -${y - x} 
            L ${x} ${y - minus} 
            L -${x} ${y - minus} 
            Z`
    return {
        path,
        strokeWeight: 4,
        x,
        y: y + minus,
        height: y * 2,
        width: x * 2,
        ...vesselColors[vesselType - 1]
    }
};

const Name: React.FC<{ children?: ReactNode }> = ({children}) => {
    const [hideLabel] = useWorldMap(store => store.hideLabel);

    return useMemo(() => {
        if (hideLabel)
            return null

        return <span style={{
            color: '#FFF',
            position: 'absolute',
            top: '18px',
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap'
        }}>
            {children}
        </span>
    }, [hideLabel, children]);
};

interface ICommonMarker {
    show?: boolean
    showPopup?: boolean

}

export interface IPropsMarker extends ICommonMarker {
    data: {
        name?: string
        position?: {
            latitude: number
            longitude: number
        }
        [id: string]: any
    }

    onClick?(e?: google.maps.MapMouseEvent): void

    onUpdate?(event: google.maps.MapMouseEvent): void
}


interface IMarker extends ICommonMarker {
    markerType: MAP_ELEMENT
    dialog?: ReactNode
    children?: ReactNode
    markerProps?: AdvancedMarkerProps
}


const Marker: React.FC<IMarker> = ({
                                       show = true,
                                       showPopup,
                                       markerType,
                                       dialog,
                                       children,
                                       markerProps
                                   }) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const map = useMap();
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (marker) {
            marker.map = show ? map : null;
        }
    }, [marker, map, show])

    useEffect(() => {
        const el = marker?.content as HTMLElement;
        if (el) {
            el.addEventListener('mouseenter', function () {
                setVisible(true)
            });

            el.addEventListener('mouseleave', function () {
                setVisible(false)
            });
        }
    }, [marker]);

    const isDialog = visible || showPopup;

    return <AdvancedMarker
        {...markerProps}
        ref={markerRef}
        zIndex={isDialog ? 10 : markerIcon[markerType]?.zIndex || 0}
    >
        <Dialog visible={isDialog}>{dialog}</Dialog>
        {children}
    </AdvancedMarker>;
}

const SiteMarker: React.FC<IPropsMarker> = ({data, show = true, onClick}) => {
    const {name, diseases = {}, units} = data;
    const {latitude = 0, longitude = 0} = data.position || {};
    const [{disease}] = diseases?.cases || [{}];
    const {color: background = '#1B6399'} = diseaseType[disease] || {};

    const dialog = useMemo(() => <div className={styles['container-info']}>
        <div className={[styles.header, styles['location-name']].join(' ')}>
            <div className={styles['site-icon']} style={{background}}/>
            <span>{name}</span>
        </div>
        {units && <div className={styles.contact}>
            <div className={styles.unit}>
                <div className={styles.icon}/>
                <span>{units.length + ` {units.length > 1 ? 'Units' : 'Unit'}`} </span>
            </div>
        </div>}
    </div>, [units, name, background]);

    return <Marker
        show={show}
        markerType={MAP_ELEMENT.SITE}
        dialog={dialog}
        markerProps={{
            onClick,
            title: name,
            position: {lat: +latitude, lng: +longitude}
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 16,
            height: 16,
            background,
            border: '2px solid #FFF',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Name>{name}</Name>
        </div>
    </Marker>;
};

const FactoryMarker: React.FC<IPropsMarker> = ({data, show = true, onClick}) => {
    const {phone, work_time, capacity_discharge, name} = data;
    const {latitude = 0, longitude = 0} = data.position || {};

    const dialog = useMemo(() => {
        const {hour, minute} = getHourMinute(work_time);

        return <div className={styles['container-info']}>
            <div className={[styles.header, styles['location-name']].join(' ')}>
                <div className={styles['factory-icon']}/>
                <span>{name}</span>
            </div>
            {phone && <div className={styles['contact']}>
                <div className={styles.phone}>
                    <div className={styles.icon}/>
                    <span>{phone}</span>
                </div>
            </div>}
            {(work_time || capacity_discharge) && <div className={styles.detail}>
                {work_time && <div className={styles.info}>
                    <span>Opening time: <b>{checkZero(hour)}:{checkZero(minute)}</b></span>
                </div>}
                {capacity_discharge && <div className={styles.info}>
                    <span>Capacity/hours: <b>{capacity_discharge} tons</b></span>
                </div>}
            </div>}
        </div>
    }, [name, work_time, phone, capacity_discharge]);

    return <Marker
        show={show}
        markerType={MAP_ELEMENT.FACTORY}
        dialog={dialog}
        markerProps={{
            onClick,
            title: name,
            position: {lat: +latitude, lng: +longitude}
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 15,
            height: 15,
            background: "#58BD28",
            border: '2px solid #FFF',
            transform: 'translate(-50%, -50%)'
        }}>
            <Name>{name}</Name>
        </div>
    </Marker>
}

const VesselMarker: React.FC<IPropsMarker> = ({data, show = true, showPopup, onClick}) => {
    const [zoom] = useWorldMap(store => store.zoom)
    const {id, type: vesselType, name, capacity, gps, operation, position} = data;

    const map = useMap();
    const {
        width = 10,
        length = 20,
        Heading,
        Latitude = position?.latitude || 0,
        Longitude = position?.longitude || 0
    } = data.gps || {};

    const icon = useMemo(() => {
        const metersPerPx = getMetersPerPx(map?.getCenter()?.lat() || 0, zoom);
        return createIconVessel({width, length, metersPerPx, vesselType})
    }, [zoom, map, width, length, vesselType]);

    const dialog = useMemo(() => {
        let statusTxt = 'Idle'
        if (operation) {
            const {current_process, activity_log} = operation
            statusTxt = getStatusOfOp({
                operation: operation.operation,
                current_process,
                activity_log,
            });
        }
        if (!gps)
            return null;
        const {Speed, Heading, Latitude, Longitude, time} = gps;

        return <div className={styles['vessel-info']}>
            <div className={styles.header}><b>{name}</b> - {statusTxt}</div>
            <div className={styles['speed-cargo']}>
                <div>
                    <p>Speed / Heading</p>
                    <b>{Math.round(Speed * 100) / 100} knots / {Math.round(Heading)}&#xb0;</b>
                </div>
                <div>
                    <p>Cargo</p>
                    <b>0 / {Math.round(capacity / 10000) / 100} t</b>
                </div>
            </div>
            <div className={styles['line-info']}>
                <p>Position:
                    <b className="ml-1" title="Latitude">{(+Latitude).toFixed(6)}</b>,
                    <b className="ml-1" title="Longitude">{(+Longitude).toFixed(6)}</b>
                </p>
            </div>
            <div className={styles['line-comment']}>
                <DurationReel time={time}/>
            </div>
        </div>;
    }, [name, operation, gps, capacity]);

    return <Marker
        show={show}
        showPopup={showPopup}
        markerType={MAP_ELEMENT.VESSEL}
        dialog={dialog}
        markerProps={{
            onClick,
            title: name,
            position: {lat: +Latitude, lng: +Longitude}
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: icon.width,
            height: icon.height,
            transform: `translate(-50%, -50%)`
        }}>
            <svg viewBox={`-${icon.x} -${icon.y} ${icon.x * 2} ${icon.y * 2}`}
                 style={{transform: `rotate(${Heading}deg)`}}>
                <defs>
                    <path id={id} d={icon.path}/>
                    <clipPath id={`clip_${id}`}>
                        <use href={`#${id}`}/>
                    </clipPath>
                </defs>
                <g>
                    <use
                        href={`#${id}`}
                        stroke={icon.strokeColor}
                        strokeWidth={icon.strokeWeight}
                        fill={icon.fillColor}
                        clipPath={`url(#clip_${id})`}
                    />
                </g>
            </svg>
        </div>
    </Marker>
};

const PoiMarker: React.FC<IPropsMarker> = ({data, show = true, onClick}) => {
    const {id, name} = data;
    const {latitude = 0, longitude = 0} = data.position || {};

    const dialog = useMemo(() => <div className={styles['container-info']}>
        <div className={[styles.header, styles['location-name']].join(' ')}>
            <svg height="22" width="20">
                <polygon points="10 1,19 5.5,19 16.5, 10 21,1 16.5,1 5.5"
                         stroke="#F6F5F4"
                         fill="#F69C6F" strokeWidth="2"/>
            </svg>
            <span>{name}</span>
        </div>
    </div>, [name]);

    return <Marker
        show={show}
        markerType={MAP_ELEMENT.POI}
        dialog={dialog}
        markerProps={{
            onClick,
            title: name,
            position: {lat: +latitude, lng: +longitude}
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 15.2,
            height: 15.2,
            transform: 'translate(-50%, -50%)'
        }}>
            <svg viewBox="-6.3 -7 12.6 14">
                <defs>
                    <path id={id} d="M 0 -7 L 6.3 -3.5 L 6.3 3.5, 0 7 L -6.3 3.5 L -6.3 -3.5 Z"/>
                    <clipPath id={`clip_${id}`}>
                        <use xlinkHref={`#${id}`}/>
                    </clipPath>
                </defs>
                <g>
                    <use
                        xlinkHref={`#${id}`}
                        stroke="#FFF"
                        fill="#F69C6F"
                        strokeWidth="3.6"
                        clipPath={`url(#clip_${id})`}
                    />
                </g>
            </svg>
            <Name>{name}</Name>
        </div>
    </Marker>
};

const PointMarker: React.FC<IPropsMarker> = ({data, show = true, onUpdate}) => {
    const {name, lat = 0, lng = 0, color = colors[0]} = data;
    const fontColor = percentBrightness(color) < 85 ? '#FFF' : '#2D3939';
    const [dragging, setDragging] = useState(false);
    const size = onUpdate ? 18 : 15;

    const event = useMemo(() => {
        if (onUpdate) {
            return {
                draggable: true,
                onDragStart: () => setDragging(true),
                onDragEnd: (e: google.maps.MapMouseEvent) => {
                    setDragging(false)
                    onUpdate(e)
                }
            }
        } else {
            return {}
        }
    }, [onUpdate]);

    return <Marker
        show={show}
        markerType={MAP_ELEMENT.POINT}
        markerProps={{
            title: name,
            position: {lat, lng},
            ...event
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: size + 'px',
            height: size + 'px',
            borderRadius: size + 'px',
            lineHeight: size + 'px',
            textAlign: 'center',
            color: fontColor,
            background: color,
            transform: ['translate(-50%, -50%)', dragging ? 'scale(1.05)' : ''].join(' ')
        }}>
            {name}
        </div>
    </Marker>
};

export {SiteMarker, FactoryMarker, VesselMarker, PoiMarker, PointMarker};
