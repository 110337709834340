import React from 'react';
import {formatNumber} from "../../../../../util/varibles/global";
import styles from "./style.module.scss";
import {Divider} from "antd";
import {IUnit} from "../../../../../util/varibles/interface";
import {getKeyUnit} from "../../../util";
import {useSelector} from "../../../../../util/store/store";

interface IProps {
    data: IUnit
}

const FishAndWeight: React.FC<IProps> = ({data}) => {
    const {avg_weight, fish_amount, total_weight} = data;
    const key = getKeyUnit(data);
    const fishChanges = useSelector(state => state.planOperation.fishChanges[key]) || {};
    const changes = Object.keys(fishChanges).reduce((value: number, subKey) => value + fishChanges[subKey], 0);
    const amount = fish_amount + changes;

    return <>
        <div className={styles.wrapper}>
            <span>{formatNumber(amount ? avg_weight : 0)} gr</span>
            <Divider type='vertical'/>
            <span>{formatNumber(amount)} fish</span>
        </div>
        <div className='font-s4'>
            {formatNumber(Math.round(changes ? total_weight : amount * avg_weight / 1000) / 1000)} tn
        </div>
    </>;
};

export default FishAndWeight;
