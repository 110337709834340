import React, {useEffect, useRef} from 'react';
import {IWidget} from "../../../constants";
import {useSelector} from "react-redux";
import {selectVessel} from "../../../../../util/store/selectors";
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {ECBasicOption} from "echarts/types/dist/shared";
import {chartUtil, colors} from "../../../../../util/library/chart-util";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IWidget {
    width: number
    data: any
    currentData: any
}

const initXAxis = {
    type: 'time',
    minInterval: 86400000,
    axisLine: {
        onZero: false,
        onZeroAxisIndex: 0,
    },
    ...chartUtil.formatTimeXAxis({
        padding: [2, 0, 0, 0]
    })
}

const LineChart: React.FC<IProps> = (props) => {
    const {yAxis: yAxisValue = [], data = [], currentData, width} = props;
    const {startTime, finishTime} = useSelector(selectVessel.history);
    const isDragging = useSelector(selectVessel.isDragging);
    const chartOpt = useRef<EChartsType>();
    const chartRef = useRef<HTMLDivElement>(null);
    const options = useRef({xAxis: initXAxis});

    useEffect(() => {
        if (isDragging) {
            const value = {
                xAxis: {
                    ...options.current.xAxis,
                    ...chartUtil.drawPlotLine(datetime(currentData.time).UTCText)
                },
            }
            options.current = {...options.current, ...value}
            chartOpt.current?.setOption(value)
        } else {
            // console.log(sensorsData[key], widget)
            // el.chart.tooltip.hide();
        }
    }, [currentData.time, isDragging]);

    useEffect(() => {
        const value = {series: data};
        options.current = {...options.current, ...value}
        chartOpt.current?.setOption(value)
    }, [data]);

    useEffect(() => {
        if (chartOpt) {
            const value = {
                xAxis: {
                    ...options.current.xAxis,
                    min: datetime(startTime).UTCText,
                    max: datetime(finishTime).UTCText,
                },
            }
            options.current = {...options, ...value}
            chartOpt.current?.setOption(value)
        }
    }, [startTime, finishTime]);

    useEffect(() => {
        const el = chartRef.current
        if (el) {
            const existInstance = echarts.getInstanceByDom(el)
            if (existInstance) echarts.dispose(el)
            const chart = echarts.init(el);
            const value: ECBasicOption = {
                tooltip: chartUtil.formatTimeTooltip(),
                legend: {
                    bottom: '10px',
                },
                grid: {
                    left: '40px',
                    top: '32px',
                    bottom: '65px',
                    containLabel: true
                },
                colorBy: "series",
                yAxis: yAxisValue.map((item: any, index: number) => ({
                    max: Number(item.max),
                    min: Number(item.min || 0),
                    name: item.format,
                    type: 'value',
                    nameTextStyle: !!index
                        ? {padding: [0, 0, 0, 8], align: 'left'}
                        : {padding: [0, 6, 0, 0], align: 'right'},
                    offset: !!index ? ((index - 1) * 40 + 5) : 5,
                    axisLine: {
                        lineStyle: {
                            color: colors[index]
                        }
                    },
                }))
            }
            options.current = {...options.current, ...value}
            chart.setOption(options.current, true);
            chartOpt.current = chart;
        }
    }, [chartRef, yAxisValue, width]);

    if (yAxisValue.length === 0)
        return null;

    return <div ref={chartRef} className='w-full h-full'/>
};

export default LineChart;