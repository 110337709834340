import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDashboard, selectOneSetting} from "../../../util/store/selectors";
import {VesselMarker} from "../../../contexts/WorldMapContext/Marker";
import {DashboardSetting} from "../../../util/varibles/userSetting";
import {IVessel} from "../../../util/varibles/interface";
import {dashboardActions} from "../reducer";
import {MAP_ELEMENT} from "../../../contexts/WorldMapContext/constants";
import {useMap} from "@vis.gl/react-google-maps";


interface IProps {
    ids: Set<string>
}

const Vessels: React.FC<IProps> = ({ids}) => {
    const filter = new Set(useSelector(selectOneSetting(DashboardSetting.VESSELS)));
    const source = useSelector(selectDashboard.vessels) || [];
    const target = useSelector(selectDashboard.target);
    const tracking = useSelector(selectDashboard.tracking);
    const map = useMap();

    const lockId = useMemo(() => {
        if (target.targetType === MAP_ELEMENT.VESSEL && tracking) {
            const {Latitude, Longitude} = target.data.gps;
            map?.setZoom(7)
            map?.setCenter({lat: Latitude, lng: Longitude});
            return target.data.id;
        } else
            return undefined;
    }, [target, tracking]);

    const hasFilter = filter.size > 0 && source.some(item => filter.has(item.id));
    const dispatch = useDispatch();

    const onClick = useCallback((data: IVessel) => {
        dispatch(dashboardActions.showInfo({data, targetType: MAP_ELEMENT.VESSEL}));
    }, [dispatch])

    return <>
        {source.map(item => <VesselMarker
            key={item.id}
            data={item}
            show={(hasFilter ? (filter.has(item.id) && (ids.size === 0 || ids.has(item.id))) : true)}
            onClick={() => onClick(item)}
            showPopup={lockId === item.id}
        />)}
    </>;
};

export default Vessels
