import React, {FC, useMemo, useRef, useState} from 'react';
import {CONTRACT_STATUS, CONTRACT_TYPE, propsModal} from "../../../util/varibles/constants";
import {Button, Modal, notification} from "antd";
import {FormInstance} from "antd/lib/form";
import styles from './style.module.scss';
import SingleContract from "./SingleContract";
import DeclineContract from "./DeclineContract";
import FixContract from "./FixContract";
import Footer from "../../../components/Popup/Component/Footer";
import {SpotService} from "../../../util/services/spot";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import {datetime} from "../../../util/library/datetime";
import {Storage} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
import {vesselManagementActions} from "../reducer";
import {selectTenantId} from "../../../util/store/selectors";

const handleSendOffer = (props: any, formValues: any) => {
    const {tenant_id, id, setLoading, contract_code, dispatch, handleClose} = props;
    const {file, time, note = '-'} = formValues;
    const value = {
        tenant_id,
        contract_id: id,
        start_time: new Date(time[0]).getTime(),
        finish_time: new Date(time[1]).getTime(),
        note
    }
    setLoading(true);
    const requestSendOffer = (params: any) => {
        SpotService.sendOffer(params)
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Sent offer successful',
                    description: `#${contract_code} has sent offer to customer.`,
                })
                handleClose();
                dispatch(vesselManagementActions.getListRequest())
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Send offer failed', error)
            })
    }
    if (file) {
        const name = file.name.split('.');
        name[name.length - 2] = name[name.length - 2] + '_' + datetime().format('YYYYMMDD_HHmmSSZZ');
        const newFileName = name.join('.');
        const key = 'Tenant_' + tenant_id + '/' + newFileName;
        Storage.put(key, file, {contentType: file.type})
            .then(() => {
                requestSendOffer({...value, file_name: newFileName, spot_offer: key});
            })
            .catch((err: any) => {
                setLoading(false);
                showErrorResponse('Upload file failed', err)
            });
    } else {
        requestSendOffer({...value, file_name: '-', spot_offer: '-'});
    }
};

const handleUpdateOffer = (props: any, formValues = {}) => {
    const {
        id,
        tenant_id,
        vesselId,
        contract_code,
        vessel_owner_id,
        userTenantId,
        statusConfirm,
        dispatch,
        setLoading,
        handleClose
    } = props;
    setLoading(true)
    const data = {tenant_id, contract_id: id, spot_status: statusConfirm, ...formValues}
    SpotService.updateRequestStatus(data)
        .then(() => {
            notify.success(NotifyCode.S2)([`Contract #${contract_code}`]);
            dispatch(vesselManagementActions.updateOffer({...data, vesselId, isOwn: vessel_owner_id === userTenantId}))
            handleClose();
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            showErrorResponse('Update failed', error)
        })
}

const handleUpdateContract = (props: any, formValues: any) => {
    const {id, tenant_id, vesselId, contract_code, dispatch, setLoading, handleClose} = props;
    const data = {id, tenant_id, ...formValues}
    SpotService.updateContract(data)
        .then(() => {
            notify.success(NotifyCode.S2)([`Contract #${contract_code}`])
            dispatch(vesselManagementActions.updateContract({...data, vesselId}))
            handleClose();
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            showErrorResponse('Update failed', error)
        })
};


const layouts: {
    [id: string]: {
        cancel: string
        ok: string
        name?: string
        header: (data: any) => string
        body: FC<any>
        handleOk: Function
        isDanger?: boolean
    }
} = {
    [CONTRACT_STATUS.APPROVED]: {
        cancel: 'Cancel',
        ok: 'Send offer',
        header: (data: any) => `Offer fixed lease for ${data.vesselName}`,
        body: FixContract,
        handleOk: (props: any, formValues: any) => handleSendOffer(props, formValues),
    },
    [CONTRACT_STATUS.COMPLETE]: {
        cancel: 'Cancel',
        ok: 'Approve',
        header: (data: any) => `Confirm on single operation request #${data.contract_code}`,
        body: SingleContract,
        handleOk: (props: any, formValues: any) => handleUpdateOffer(props, formValues),
    },
    [CONTRACT_STATUS.INACTIVE]: {
        cancel: 'No',
        ok: 'Yes',
        name: 'cancel',
        header: (data: any) => `Confirm on ${data.type === CONTRACT_TYPE.SINGLE ? 'single operation' : 'fixed lease'} contract #${data.contract_code}`,
        body: DeclineContract,
        handleOk: (props: any) => handleUpdateOffer(props),
        isDanger: true
    },
    [CONTRACT_STATUS.DECLINE]: {
        cancel: 'Cancel',
        ok: 'Decline',
        name: 'decline',
        header: (data: any) => `Confirm on ${data.type === CONTRACT_TYPE.SINGLE ? 'single operation' : 'fixed lease'} request #${data.contract_code}`,
        handleOk: (props: any) => handleUpdateOffer(props),
        body: DeclineContract,
        isDanger: true
    },
}

interface IProps {
    values: any

    onClose?(): void
}

const PopupApprove: React.FC<IProps> = (props) => {
    const {values, onClose} = props;
    const {statusConfirm} = values;
    const formRef = useRef<FormInstance>();
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const userTenantId = useSelector(selectTenantId);
    const dispatch = useDispatch();
    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose();
    }

    const layout = useMemo(() => layouts[statusConfirm] || {
        cancel: 'Cancel',
        ok: 'Save',
        header: (data: any) => `Update contract #${data.contract_code}`,
        body: SingleContract,
        handleOk: (props: any, formValues: any) => handleUpdateContract(props, formValues)
    }, [statusConfirm]);

    const propsHandle = {...values, userTenantId, dispatch, setLoading, handleClose};
    const propsNode = {formRef, values, name: layout.name};
    const {body: Body, handleOk} = layout;

    return <Modal {...{
        ...propsModal,
        className: styles['popup-approve'],
        open: visible,
        title: layout.header(values),
        onCancel: handleClose
    }}>
        <Body {...propsNode} onOk={(formValues: any) => handleOk(propsHandle, formValues)}/>
        <Footer className='mt-20'>
            <Button className='bt-default' onClick={handleClose}>{layout.cancel}</Button>
            <Button
                className={layout.isDanger ? 'bt-danger' : 'bt-primary'}
                onClick={() => formRef.current?.submit()}
                loading={loading}
            >
                {layout.ok}
            </Button>
        </Footer>
    </Modal>
};

export default PopupApprove;
