import React, {useEffect} from 'react';
import {KeyOfStep, OP_STATUS, OPERATION_TYPE, TIME_PER_STEP} from "../../../../../../../../util/varibles/constants";
import {IActivityLog, IOperation} from "../../../../../../../../util/varibles/interface";
import {getTimeByOpMode} from "../../../../../../util/function_operation/constants";
import TimePoint from "./TimePoint";
import Duration from "./Duration";
import {getElById} from "../../../../../../../../util/varibles/global";
import {CALENDAR_ID} from "../../../../../../constants";
import {convertPxToTimeByStep} from "../../../../../../util";
import {usePlan} from "../../../../../../../../contexts/PlanOperationContext/Plan";
import {useOperation} from "../../../../../../../../contexts/OperationContext";
import {useDispatch} from "react-redux";
import {planOpActions} from "../../../../../../reducer";

const timeline: { [id: string]: (data: IOperation, activityLog: IActivityLog[]) => any[] } = {
    [OPERATION_TYPE.HARVEST]: (data: IOperation, activityLog: IActivityLog[]) => {
        const {site_name, sites = []} = data.operation;
        const listOfSite = sites.length === 0 ? [{name: site_name}] : sites;
        let indexSite = 0;
        const listTimeOfSite = activityLog.reduce((rs: any, item: any, index) => {
            if (item.key === KeyOfStep.ARRIVE_SITE && index) {
                const {name = ''} = listOfSite[indexSite] || {};
                indexSite++;
                return [...rs, {key: index, name: 'site', label: 'Site', title: name}]
            }
            return rs
        }, []);
        const indexFactory = activityLog.findIndex((item: any) => item.key === KeyOfStep.ARRIVE_FACTORY);
        return [
            {key: 0, name: 'start', label: 'Start'},
            ...listTimeOfSite,
            {key: indexFactory, name: 'factory', label: 'Factory'},
            {key: -1, name: 'finish', label: 'Complete'}
        ]
    },
    [OPERATION_TYPE.TREATMENT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
    [OPERATION_TYPE.TRANSPORT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: 1, name: 'site', label: 'Smolt site'},
        {key: 5, name: 'site', label: 'Site'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
    [OPERATION_TYPE.EVENT]: () => [
        {key: 0, name: 'start', label: 'Start'},
        {key: -1, name: 'finish', label: 'Complete'}
    ],
}
let targetY = 0, dragState = -1;

interface IProps {
    isOwn: boolean
    activityLog: IActivityLog[]
    data: IOperation
    width: number
    adjust: any
}

const TimeLine: React.FC<IProps> = (props) => {

    const {data} = props;
    const [operation_mode] = usePlan(state => state.opMode);
    const [isTimeline, setOperation] = useOperation(state => state.isTimeline);
    const [left] = useOperation(state => state.left);
    const [index] = useOperation(state => state.vesselIndex);
    const dispatch = useDispatch();

    const handleMouseDown = (e: any, index: number) => {
        const {current_process} = data.operation;
        if (index && index <= current_process)
            return;
        const {pageY} = e;
        targetY = pageY;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        dragState = index;
        setOperation({modeDrag: 'time'});
        dispatch(planOpActions.setFocusId(data.operation.id));
    }

    const handleMouseMove = (e: any) => {
        setOperation(prev => ({activityLogs: {...prev.activityLogs, [prev.rootKey]: updateTime(e)}}));
    }

    const handleMouseUp = (e: any) => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        setOperation({modeDrag: ''});
        dispatch(planOpActions.updateOps({update: {[data.operation.id]: {...data, activity_log: updateTime(e)}}}))
    }

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    if (!isTimeline)
        return null;


    const updateTime = (e: any): IActivityLog[] => {
        const {pageY} = e;
        const {activity_log} = data;
        const {current_process} = data.operation;
        const space = pageY - targetY;
        if (!space)
            return activity_log;

        const durationMax = (activity_log[dragState].est_start_time - activity_log[0].est_start_time) / 2;
        const duration = convertPxToTimeByStep(pageY - targetY);
        const max = activity_log.length - 1;

        if (dragState === 0) {
            return activity_log.map((item: any, i) => ({
                ...item,
                est_start_time: (duration < 0 && max === i) ? item.est_start_time : item.est_start_time + duration
            }));
        }

        let rest = Math.max(duration, -durationMax), adjustment: any = {};
        for (let i = activity_log.length - 1; i > 0; i--) {
            if (i <= current_process) {
                break;
            } else if (i > dragState) {
                adjustment[i] = rest;
            } else {
                const time = activity_log[i].est_start_time - activity_log[i - 1].est_start_time;
                const limit = -time / 2;
                adjustment[i] = Math.max(time + limit, time + rest);
                rest -= limit
                if (rest >= 0)
                    break;
            }
        }

        return activity_log.reduce((rs: any, item: any, i: number) => {
            if (!adjustment[i])
                return rs;
            const {est_start_time: prev} = rs[i - 1];
            if (i > dragState) {
                const {est_start_time: current} = rs[i];
                rs[i] = {...item, est_start_time: Math.max(prev + TIME_PER_STEP, current + adjustment[i])};
            } else {
                rs[i] = {...item, est_start_time: prev + adjustment[i]};
            }
            return rs;
        }, JSON.parse(JSON.stringify(activity_log)));
    }

    const {activityLog, width, isOwn, adjust} = props;
    const {operation_type, id, status, current_process = -1} = props.data.operation;
    const el = getElById(CALENDAR_ID);
    const lastIndex = activityLog.length - 1;
    if (!el)
        return null
    const {scrollLeft} = el;
    const style: any = {left: scrollLeft + 'px'};

    if (left) {
        style.width = `${left + 110}px`;
    } else {
        style.transition = 'width .2s';
        style.width = adjust.leftTimeLine ? adjust.leftTimeLine : `calc(${width * index}% + ${120 - scrollLeft}px)`;
    }

    const generateData = timeline[operation_type];
    const list = generateData ? generateData(props.data, activityLog) : [];
    const isEdit = isOwn && status !== OP_STATUS.FINISHED;

    return <>
        {list.map((item: any) => {
            const {key} = item
            const index = key !== -1 ? key : lastIndex;
            const time = getTimeByOpMode[operation_mode](activityLog[index]);
            const isAllow = isEdit && (index > current_process || index === 0)
            return <TimePoint key={key} {...{
                id,
                data: item,
                isEdit: isAllow,
                style,
                index,
                time,
                handleMouseDown: isAllow ? handleMouseDown : () => null
            }}/>
        })}
        <Duration
            style={style}
            start={getTimeByOpMode[operation_mode](activityLog[0])}
            finish={getTimeByOpMode[operation_mode](activityLog[lastIndex])}
        />
    </>
};

export default TimeLine;
