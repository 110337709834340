import {ILocation, IRoute} from "../../util/varibles/interface";

export enum RouteMode {
    VIEW = 'view',
    EDIT = 'edit'
}

export interface RouteState {
    route?: ({ index?: number } & IRoute)
    showId: number
    loading: {
        routes: boolean
    }
    params: any
    routes: IRoute[]
    locations: ILocation[]

    loadingLocations: boolean
    loadingAuto: boolean
    mode: RouteMode,

    isShowAll: boolean

    requestId: string
}

export const initialState: RouteState = {
    loading: {
        routes: false,
    },
    showId: 0,
    params: {
        source: null,
        destination: null,
    },
    routes: [],
    locations: [],

    loadingLocations: false,
    loadingAuto: false,

    isShowAll: false,
    mode: RouteMode.VIEW,

    requestId: ''
};
