import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import Harvest from "./Harvest";
import Transport from "./Transport";
import Event from "./Event";
import Treatment from "./Treatment";
import {OPERATION_TYPE, operationType} from "../../../../../../util/varibles/constants";
import {IOperation, IVessel} from "../../../../../../util/varibles/interface";
import Service from "./Service";
import {dashboardActions} from "../../../../reducer";
import PopupOpNote from "../../../../../../components/Popup/PopupOpNote";
import {MAP_ELEMENT} from "../../../../../../contexts/WorldMapContext/constants";
import {generateMarkers} from "../../../constants";
import Duration from "./Component/Duration";
import Icon from "../../../../../../components/Icon";
import {NavLink} from "react-router-dom";
import Status from "./Component/Status";
import {AppState} from "../../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    target: state.dashboard.target,
    vessels: state.dashboard.vessels,
});

const content: any = {
    [OPERATION_TYPE.HARVEST]: (value: IOperation) => <Harvest {...value} />,
    [OPERATION_TYPE.TREATMENT]: (value: IOperation) => <Treatment {...value} />,
    [OPERATION_TYPE.TRANSPORT]: (value: IOperation) => <Transport {...value} />,
    [OPERATION_TYPE.EVENT]: (value: IOperation) => <Event {...value} />,
    [OPERATION_TYPE.SERVICE]: (value: IOperation) => <Service {...value} />,
}

interface IProps {
    data: IOperation
    target: any
    vessels: IVessel[]

    showInfo(payload: any): void

    updateOperations(payload: any): void
}

const Operation: React.FC<IProps> = (props) => {
    const {data, target, vessels} = props;
    const {operation} = data;
    const {id: selectedId} = target.data?.operation || {};
    const {operation_type, id, note = '', operation_code, event_type, tenant_id} = data.operation;
    const isSelected = id === selectedId;
    const [isShow, setIsShow] = useState(false);
    const [vessel, setVessel] = useState<IVessel | undefined>();

    useEffect(() => {
        const {vessel_id} = data.operation;
        setVessel(vessels.find(item => item.id === vessel_id))
    }, [data, vessels]);


    const handleClick = (e: any) => {
        if (e.target.closest(`.${styles.note}`)) {
            setIsShow(true);
            return;
        }

        if (isSelected) {
            props.showInfo({});
        } else {
            const {operation_type} = data.operation;
            const getMarkers = generateMarkers[operation_type];
            const markers = getMarkers ? getMarkers(data) : [];
            const {id: vessel_id = ''} = vessel || {};
            props.showInfo({
                targetType: MAP_ELEMENT.POLYLINE,
                data: {...data, vessel, markers: [...markers, {type: MAP_ELEMENT.VESSEL, id: vessel_id}]},
            });
        }
    };

    const updateNote = (note: string) => {
        props.updateOperations([{...data, operation: {...data.operation, note}}]);
    }

    const view = content[operation_type];
    const {icon} = operationType[operation_type] || {};
    const {} = data.operation;

    return <div
        className={styles.operation}
        data-is-active={isSelected}
        data-operation-type={operation_type}
        onClick={handleClick}
    >
        <div className={styles['content']}>
            <div className={styles['operation-title']}>
                <div className={styles.opName}>
                    <Icon className={styles.icon} icon={icon} dataAttribute={{'data-event-type': event_type}}/>
                    <NavLink className={styles['operation-id']} to={`/operation/${tenant_id}/${id}`}>
                        #{operation_code}
                    </NavLink>
                </div>
                <Status {...{operation, activityLog: data.activity_log}}/>
            </div>
            {view({...data, vessel: vessel || {}})}
            <Duration activity_log={data.activity_log}>
                {note.length > 0 && <div className={styles.note}/>}
            </Duration>
        </div>
        <PopupOpNote {...{
            isShow,
            note,
            info: {code: "#" + operation_code},
            operation,
            handleSave: updateNote,
            handleClose: () => setIsShow(false)
        }}/>
    </div>;
};

export default connect(mapStateToProps, {
    showInfo: dashboardActions.showInfo,
    updateOperations: dashboardActions.updateOperations
})(Operation);
