import React from 'react';
import {IOperation} from "../../../util/varibles/interface";
import LoadingBox from "../../LoadingBox";
import {useSelector} from "../../../util/store/store";

interface IProps {
    data: IOperation[]
}

const Loading: React.FC<IProps> = ({data}) => {
    const loading = useSelector(state => state.login.loadingDashboard);

    if (!loading && data.length > 0)
        return null

    return <LoadingBox loading={loading} message="There is no operation coming."/>;
};

export default Loading;
