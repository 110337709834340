import {OPERATION_TYPE} from "../../../util/varibles/constants";
import {MAP_ELEMENT} from "../../../contexts/WorldMapContext/constants";

export enum EL_DASHBOARD {
    LEFT_CONTROL = 'dashboard-left-control',
    RIGHT_CONTROL = 'dashboard-right-control',
    TOP_CONTROL = 'dashboard-top-control',
    CONTROL = 'dashboard-control'
}

export const generateMarkers: any = {
    [OPERATION_TYPE.HARVEST]: (data: any) => {
        const {factory_id} = data.operation;
        const {sites} = data.operation;
        return [
            ...sites.map((item: any) => ({type: MAP_ELEMENT.SITE, id: item.id})),
            {type: MAP_ELEMENT.FACTORY, id: factory_id},
        ]
    },
    [OPERATION_TYPE.TREATMENT]: (data: any) => {
        const {id = data.operation.site_id || ''} = data.operation.site || {};
        return [
            {type: MAP_ELEMENT.SITE, id},
        ]
    },
    [OPERATION_TYPE.TRANSPORT]: (data: any) => {
        const {source_id, destination_id} = data.operation;
        return [
            {type: MAP_ELEMENT.SITE, id: source_id},
            {type: MAP_ELEMENT.SITE, id: destination_id},
        ]
    },
}
