import React, {useState} from 'react';
import {connect} from 'react-redux';
import {
    ACTION_TYPE,
    aHourMillisecond,
    CONTRACT_TYPE,
    OPERATION_MODE,
    propContainer,
    propsModal,
    SHORT_DATETIME_FORMAT
} from "../../../../util/varibles/constants";
import {Button, DatePicker, Form, Input, Modal, Space} from "antd";
import styles from "./style.module.scss";
import {FormInstance} from "antd/lib/form";
import {ILevelByVessel, IOperations, IVessel} from "../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {planValidation, VALIDATION} from "../../util/validation";
import {disabledDate, layout} from "../Vessels/constants";
import Header from "../../../../components/Popup/Component/Header";
import {datetime} from "../../../../util/library/datetime";
import {planOpActions} from "../../reducer";
import {SpotService} from "../../../../util/services/spot";
import {showErrorResponse} from "../../../../util/varibles/global";
import {Rule} from "../../../../util/varibles/validation";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    operations: state.planOperation.operations,
    level: state.planOperation.level,
    opMode: state.login.user.tenant.operation_mode || OPERATION_MODE.PLAN
});

interface IProps {
    visible?: boolean
    availableTime?: any
    vessel: IVessel
    editMode: ACTION_TYPE

    userTenantId: string
    operations: IOperations
    level: ILevelByVessel
    opMode: OPERATION_MODE

    onClose?(): void

    updateAvailableTime(payload: any): void
}

const PopupAvailableTime: React.FC<IProps> = (props) => {
    const {editMode, vessel} = props;
    const formRef: any = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(props.visible);
    const {start_time = 0, finish_time = 0} = props.availableTime || {};
    const duration = finish_time ? Math.floor(datetime(finish_time).diff(start_time) / aHourMillisecond) : 0;

    const handleChangeTime = () => {
        const {start_time, finish_time} = formRef.current.getFieldsValue();
        const duration = finish_time ? Math.floor(datetime(finish_time).diff(start_time) / aHourMillisecond * 100) / 100 : 0;
        formRef.current.setFieldsValue({duration})
    }

    const handleSubmit = (values: any) => {
        const {start_time, finish_time} = values;
        const {vessel_id = vessel.id, id = undefined} = props.availableTime || {};
        const body = {
            vessel_id,
            id,
            start_time: datetime(start_time).time,
            finish_time: datetime(finish_time).time
        }
        let fetch, title = '', notifyCode: NotifyCode;
        if (editMode === ACTION_TYPE.CREATE) {
            fetch = SpotService.createAvailableTime
            title = 'Create'
            notifyCode = NotifyCode.S1;
        } else {
            fetch = SpotService.updateAvailableTime
            title = 'Update'
            notifyCode = NotifyCode.S2;
        }

        setLoading(true);
        fetch(body)
            .then(rs => {
                setLoading(false);
                props.updateAvailableTime(rs);
                notify.success(notifyCode)('Available time');
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(`${title} failed`, error);
            })
    }

    const handleChangeDuration = (e: any) => {
        const {value} = e.target
        const duration = Number(value) * 3600 * 1000;
        const {start_time} = formRef.current.getFieldsValue();
        if (start_time)
            formRef.current.setFieldsValue({finish_time: datetime(start_time.valueOf() + duration).value})
    }

    const validateTime = async () => {
        const {start_time = 0, finish_time = 0} = formRef.current.getFieldsValue() || {};
        const start = datetime(start_time).time;
        const finish = datetime(finish_time).time;
        const {userTenantId} = props;
        if (start_time && finish_time) {
            if (start > finish) {
                throw new Error(notify[NotifyCode.E8]());
            } else {
                const {operations, level, opMode} = props;
                const {id = undefined, vessel_id = vessel.id} = props.availableTime || {};
                const availableTime = {id, vessel_id, start_time, finish_time};
                let {message} = planValidation[VALIDATION.AVAILABLE_TIME](availableTime, {
                    operations,
                    userTenantId,
                    opMode,
                    level
                })

                if (message.length === 0) {
                    const {contracts, available_times} = props.vessel;
                    let isError = contracts.some(item => item.type === CONTRACT_TYPE.FIXED
                        && item.tenant_id !== userTenantId
                        && start <= item.finish_time
                        && item.start_time <= finish)
                    if (isError) {
                        message = notify[NotifyCode.E6]();
                    } else {
                        isError = available_times.some((item: any) => item.id !== id
                            && start <= item.finish_time
                            && item.start_time <= finish)

                        if (isError)
                            message = notify[NotifyCode.E6]();
                    }
                }
                if (message.length > 0)
                    throw new Error(message);
            }
        }
    };

    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose();
    };

    return <Modal {...{
        ...propsModal,
        open: visible,
        title: <Header
            title={`${editMode === ACTION_TYPE.CREATE ? 'Create' : 'Update'} spot available time`}
            onClose={handleClose}
        />,
        width: 378,
        wrapClassName: styles.popup,
        onCancel: handleClose,
    }} styles={{body: {padding: 0, height: '100%'}}}>
        <Form
            {...layout}
            ref={formRef}
            className={styles['container-body']}
            initialValues={{
                start_time: datetime(start_time).value,
                finish_time: finish_time ? datetime(finish_time).value : null,
                duration
            }}
            layout={'vertical'}
            onFinish={handleSubmit}
        >
            <Form.Item
                className={styles['container-time']}
                label={<>
                    <div className={styles['label-time']}>Start time</div>
                    <div className={styles['label-time']}>Finish time</div>
                </>}
                name='time'
                rules={[{validator: validateTime}]}
            >
                <Space.Compact className='d-flex'>
                    <Form.Item name="start_time" rules={[Rule.Require('Start time')]} noStyle>
                        <DatePicker {...{
                            ...propContainer,
                            allowClear: false,
                            showTime: {format: 'HH:mm', minuteStep: 15},
                            format: SHORT_DATETIME_FORMAT,
                            placeholder: 'Start time',
                            onChange: handleChangeTime,
                        }}  />
                    </Form.Item>
                    <Form.Item name="finish_time" rules={[Rule.Require('Start time')]} noStyle>
                        <DatePicker {...{
                            ...propContainer,
                            allowClear: false,
                            showTime: {format: 'HH:mm', minuteStep: 15},
                            disabledDate: (current) => disabledDate(current, formRef.current?.getFieldValue('start_time')),
                            format: SHORT_DATETIME_FORMAT,
                            placeholder: 'Finish time',
                            onChange: handleChangeTime,
                        }} />
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
            <Form.Item label="Duration" name="duration">
                <Input className='h-32' suffix="Hour(s)" onChange={handleChangeDuration}/>
            </Form.Item>
            <Button
                className={['bt-primary', styles['bt-submit']].join(' ')}
                loading={loading}
                onClick={() => formRef.current.submit()}
            >
                Save
            </Button>
        </Form>
    </Modal>;
};

export default connect(mapStateToProps, {
    updateAvailableTime: planOpActions.updateAvailableTime
})(PopupAvailableTime);
