import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {productionAreas, propContainer} from "../../../../../util/varibles/constants";
import {Button, Dropdown, Input} from "antd";
import styles from './style.module.scss';
import {IIdName, ISite, ISTHP} from "../../../../../util/varibles/interface";
import {loginActions} from "../../../../LoginPage/reducer";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import ProductionArea from "../../_Component/SiteFilter/ProductionArea";
import Factories from "./Factories";
import {SearchOutlined} from "@ant-design/icons";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {
        plannedSiteFilter = [],
        plannedFactoryFilter = [],
        plannedProdAreaFilter = [],
    } = state.login.user.setting || {};

    return {
        siteFilter: plannedSiteFilter,
        factoryFilter: plannedFactoryFilter,
        prodAreaFilter: plannedProdAreaFilter,
        sites: state.planOperation.sites,
        factories: state.planOperation.factories,
        harvests: state.planOperation.harvests,
    }
};


interface IProps {
    sites: ISite[]
    harvests: ISTHP[]
    siteFilter: string[]
    factoryFilter: string[]
    prodAreaFilter: string[]

    updateUserSetting(payload: any[]): void
}

const Filter: React.FC<IProps> = (props) => {
    const {siteFilter, prodAreaFilter, factoryFilter, sites, harvests, updateUserSetting} = props;
    const [visible, setVisible] = useState(false);
    const [prodAreas, setProdAreas] = useState<{ [productionAreaId: string]: ISite[] }>({});
    const [factories, setFactories] = useState<IIdName[]>([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (harvests.length > 0) {
            const siteIds = new Set(harvests.map(item => item.site_id));

            const data = sites.reduce((rs: any, item) => {
                const {id, prod_area = ''} = item;
                if (!siteIds.has(id)) {
                    return rs;
                }
                siteIds.delete(id);
                rs[prod_area] = [...rs[prod_area] || [], item]
                return rs;
            }, {})

            data['null'] = Array.from(siteIds).map(id => {
                const {site_name: name = ''} = harvests.find(item => item.site_id === id) || {};
                return {id, name}
            })
            setProdAreas(data)
            const factories = harvests.reduce((rs: IIdName[], item) => {
                const {factory_id: id, factory_name: name} = item;
                const check = rs.some(sub => sub.id === id);
                if (!check)
                    return [...rs, {id, name}]
                return rs
            }, [])
            setFactories(factories)
        }
    }, [sites, harvests]);

    const handleClear = () => {
        updateUserSetting([{plannedSiteFilter: [], plannedFactoryFilter: [], plannedProdAreaFilter: []}]);
    }

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape') {
            setSearch('');
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }, []);

    const updateSetting = (params: { siteFilter: string[], prodAreaFilter: string[] }) => {
        updateUserSetting([{plannedSiteFilter: params.siteFilter, plannedProdAreaFilter: params.prodAreaFilter}]);
    }

    const items = [
        {
            className: styles.wrapper,
            key: 'filter',
            label: <>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <div>Filter production area, site, factory</div>
                        <Input
                            placeholder='Search for site or factory'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            suffix={search.length === 0 && <SearchOutlined/>}
                            allowClear
                        />
                    </div>
                    <div className='link' onClick={handleClear}>Clear all filter</div>
                </div>
                <div className={styles.body}>
                    <Factories
                        factories={factories}
                        factoryFilter={factoryFilter}
                        search={search}
                        updateSetting={(plannedFactoryFilter) => updateUserSetting([{plannedFactoryFilter}])}
                    />
                    <div className={styles.productionAreas}>
                        {productionAreas.map(item => {
                            const {id, name} = item;
                            if (!prodAreas[id])
                                return null;

                            return <ProductionArea
                                key={id}
                                id={id}
                                prodAreaFilter={prodAreaFilter}
                                updateSetting={updateSetting}
                                name={name}
                                list={prodAreas[id]}
                                search={search.toLowerCase()}
                                siteFilter={siteFilter}
                            />
                        })}
                        <ProductionArea
                            key={null}
                            id={null}
                            prodAreaFilter={prodAreaFilter}
                            updateSetting={updateSetting}
                            list={prodAreas['null']}
                            search={search.toLowerCase()}
                            siteFilter={siteFilter}
                        />
                    </div>
                </div>
            </>
        }
    ]

    return <div className={styles['container-filter']}>
        <Dropdown
            {...propContainer}
            open={visible}
            trigger={['click']}
            onOpenChange={(changed) => setVisible(changed)}
            menu={{items}}
            overlayClassName={styles.dropdown}
            placement="bottom"
            arrow
        >
            <Button className='bt-ghost h-32' data-is-show={visible}>
                <Icon icon={ICON_NAME.FILTER}/>
                <span className='ml-5'>Filter</span>
            </Button>
        </Dropdown>
    </div>;
};

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting
})(Filter);
