import React, {useState} from 'react';
import stylesContainer from "../../Plan/Calendar/Operations/Operation/style.module.scss";
import stylesOp from "../../Plan/Calendar/Operations/Operation/style.module.scss";
import {OP_STATUS, OPERATION_TYPE, PATHNAME} from "../../../../util/varibles/constants";
import EventView from "../../_Component/ContentOperation/EventView";
import HarvestView from "../../_Component/ContentOperation/HarvestView";
import TreatmentView from "../../_Component/ContentOperation/TreatmentView";
import TransportView from "../../_Component/ContentOperation/TransportView";
import styles from './style.module.scss';
import {connect, useDispatch} from "react-redux";
import OpInfo from "../../Popup/OpInfo";
import Drag from "./Drag";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {notification} from "antd";
import {deleteOperationFetch} from "../../../../util/services/operation";
import {deleteSuccess} from "../../Plan/Calendar/Operations/actions";
import {IFactory, IOperation, IOperations, IVessel} from "../../../../util/varibles/interface";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import modal from "../../../../components/Popup/ModalCustom";
import {showErrorResponse} from "../../../../util/varibles/global";
import ServiceView from "../../_Component/ContentOperation/ServiceView";
import Loading from "../../Plan/Calendar/Loading";
import {planOpActions} from "../../reducer";
import {openPage} from "../../../../util/library/Router";
import {AppState} from "../../../../util/store/store";

const renderContent = (data: IOperation, status: OP_STATUS = OP_STATUS.CANCELED) => {
    const {operation_type} = data.operation;
    const common = {
        status,
        data,
        isOwn: true
    }
    switch (operation_type) {
        case OPERATION_TYPE.EVENT:
            return <EventView {...common}/>
        case OPERATION_TYPE.HARVEST:
            return <HarvestView {...common}/>
        case OPERATION_TYPE.TREATMENT:
            return <TreatmentView {...common}/>
        case OPERATION_TYPE.TRANSPORT:
            return <TransportView {...common}/>
        case OPERATION_TYPE.SERVICE:
            return <ServiceView {...common}/>
        default:
            return null
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        userTenantId: state.login.user.tenant_id,
        vessels: state.planOperation.vessels,
        operations: state.planOperation.operations,
        factories: state.planOperation.factories
    }
};

interface IProps {
    data: IOperation
    status?: OP_STATUS
    dragging?: boolean
    vessels: IVessel[]
    operations: IOperations
    factories: IFactory[]
    userTenantId: string

    onDragStart?(e: any, id: string): void

    onDragEnd?(e: any): void

    renewOp?(data: any): void
}


const Operation: React.FC<IProps> = React.memo((props) => {
    const {data, status = OP_STATUS.CANCELED, dragging = false, onDragStart, onDragEnd, renewOp} = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const menu = [
        {
            key: 'detail',
            label: <div className='menu-line' onClick={openDetail}>
                <Icon icon={ICON_NAME.OPERATION_DETAIL} size={16}/>
                <div>Operation detail</div>
            </div>,
        },
        {
            key: 'revert',
            label: <div className='menu-line' onClick={revert}>
                <Icon icon={ICON_NAME.REVERT} size={15}/>
                <div>Revert</div>
            </div>,
        },
        {
            type: 'divider'
        },
        {
            key: 'delete',
            label: <div className='menu-line' data-danger={true} onClick={openDeleteDialog}>
                <Icon icon={ICON_NAME.DELETE} danger size={15}/>
                <div>Delete</div>
            </div>,
        },
    ];

    function revert(e: any) {
        e.stopPropagation();
        const {vessels} = props;
        if (renewOp) {
            const vessel = vessels.find((item: any) => item.id === data.operation.vessel_id);
            if (!vessel) {
                notification.error({
                    message: 'Revert failed',
                    description: `Can't find the vessel in the system`
                })
            } else
                renewOp({...data, vessel})
        }
        return true;
    }

    function openDetail(e: any) {
        e.preventDefault();
        e.stopPropagation();
        const {tenant_id, id} = data.operation;
        openPage(`${PATHNAME.OPERATION}/${tenant_id}/${id}`)
        return false;
    }

    function openDeleteDialog(e: any) {
        e.preventDefault();
        e.stopPropagation();
        modal.confirm({
            content: 'Are you sure you want to delete this operation?',
            ok: {
                click: () => {
                    const {id, operation_code} = data.operation;
                    setLoading(true);
                    new Promise(resolve => resolve(deleteOperationFetch(data.operation.id)))
                        .then(() => {
                            setLoading(false);
                            deleteSuccess([id], [operation_code], (ids) => dispatch(planOpActions.deleteOps(ids)));
                        })
                        .catch(error => {
                            setLoading(false);
                            if (error.response && error.response.status.toString() === '445') {
                                deleteSuccess([id], [operation_code], (ids) => dispatch(planOpActions.deleteOps(ids)));
                            } else
                                showErrorResponse('Delete failed', error);
                        })
                }
            }
        });
        return false;
    }

    function openOperationPopup() {
        const {vessels} = props;

        const vessel = (vessels || []).find((item: any) => item.id === data.operation.vessel_id);

        if (vessel)
            openPopup(<OpInfo {...{
                isShow: true,
                isEdit: false,
                data: {...data, vessel},
            }}/>)
    }

    return <div
        id={data.operation.id}
        data-loading={loading}
        className={[styles.operation, stylesOp.operation].join(' ')}
        data-status={status}
        onClick={openOperationPopup}
    >
        <div
            {...{className: [stylesContainer['content-wrapper'], stylesContainer['layout'], 'text-truncate'].join(' ')}}
        >
            {renderContent(data, status)}
            {(!dragging && onDragStart) &&
                <Drag menu={menu} onDragStart={(e) => onDragStart(e, data.operation.id)} onDragEnd={onDragEnd}/>}
        </div>
        <Loading loading={loading} isFull={false}/>
    </div>;
});

export default connect(mapStateToProps, {})(Operation);

