import React from 'react';
import {connect} from 'react-redux';

import stylesContainer from '../../style.module.scss';
import styles from '../style.module.scss';
import {IOperation} from "../../../../util/varibles/interface";
import {getStepNameOfChecklist} from "../../../../util/varibles/global";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupChecklist from "../PopupChecklist";
import LoadingBox from "../../../../components/LoadingBox";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    data: state.operationDetail.data,
});

interface IProps {
    data: IOperation
}

const Checklists: React.FC<IProps> = (props) => {
    const {checklists = {}} = props.data;
    const {operation_type} = props.data.operation;
    const list = Object.keys(checklists);
    const openChecklist = (step: any) => {
        const el = openPopup(<PopupChecklist
            visible={true}
            data={props.data}
            step={step}
            onSave={() => {
            }}
            onClose={() => el.remove()}
        />)
    }

    return <div className={stylesContainer.section}>
        <div className={stylesContainer['header-box']}>Checklist</div>
        <div className={styles.checklists}>
            {list.length > 0
                ? list.map((key: string) => {
                    const {step_name = ''} = checklists[key] || {};
                    const header = getStepNameOfChecklist({step: key, step_name, operation_type})
                    return <div key={key} {...{
                        className: styles.checklist,
                        onClick: () => openChecklist(key)
                    }}>
                        <div>{header}</div>
                    </div>
                })
                : <LoadingBox/>}
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(Checklists);
