import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MessageState, tabMessage} from "../../../../../../pages/MessagePage/constants";
import stylesContainer from "../style.module.scss";
import Message from "../../Message";
import {Spin} from "antd";
import styles from "./style.module.scss";
import {getTitleChatUser} from "../../../../constants";
import {checkLoadMore} from "../../../../../../util/varibles/global";
import {messageActions} from "../../../../../../pages/MessagePage/reducer";
import {CHAT_TYPE} from "../../../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../../Icon";
import {AppState} from "../../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    user_id: state.login.user.user_id,
    message: state.message
})

interface IProps {
    message: MessageState
    user_id: string

    onOpenConversation(conversation: any): void

    onAddConversation(): void

    getChat(payload: any): void
}

class Chat extends Component<IProps> {
    rootRef: any = React.createRef();

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {
            chat: dataNew,
            chatMore,
            loadingChat
        } = this.props.message;
        const {chat: dataOld} = prevProps.message;

        if (chatMore && !loadingChat && JSON.stringify(dataNew) !== JSON.stringify(dataOld) && this.rootRef.current) {
            checkLoadMore(this.rootRef.current, () => {
                const {chatIndex} = this.props.message;
                this.props.getChat({index: chatIndex + 1})
            })
        }
    }

    handleWheel = (e: any) => {
        const {chatMore, loadingChat, chatIndex} = this.props.message;
        if (!chatMore || loadingChat)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight}: any = this.rootRef.current;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
        if (isScrolledToBottom && deltaY > 0) {
            this.props.getChat({index: chatIndex + 1})
        }
    }

    render() {
        const {chat, loadingChat} = this.props.message;

        return <>
            <div className={stylesContainer['title']}>
                <div data-lev='name'>
                    {tabMessage[CHAT_TYPE.USER].name}
                </div>
                <div data-lev='action'>
                    <div className='bt-ghost' data-icon={true} onClick={this.props.onAddConversation}>
                        <Icon icon={ICON_NAME.ADD_2}/>
                    </div>
                </div>
            </div>

            <div ref={this.rootRef} className={stylesContainer['container-content']} onWheel={this.handleWheel}>
                <div className={stylesContainer['content']}>
                    {chat.map((item: any) => {
                        const {group_chat = {}} = item || {}
                        const {id, users = []} = group_chat
                        const title = getTitleChatUser(item, this.props.user_id);

                        return <Message
                            title={title}
                            avatar={users.length > 2
                                ? <div className={styles['avatar-group']}>GR</div>
                                : <div className={styles['avatar-single']}>{title[0]}</div>}
                            key={id}
                            data={item}
                            onClick={() => this.props.onOpenConversation(item)}
                        />
                    })}
                </div>
                {loadingChat && <div className={stylesContainer['loading']} data-empty={chat.length === 0}>
                    <Spin/>
                </div>}
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    getChat: messageActions.getChatRequest
})(Chat);