import React, {useEffect, useRef, useState} from 'react';
import {FISH_TYPE, fishTypes, propsModal, SITE_TYPE} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {FormInstance} from "antd/lib/form";
import {connect} from "react-redux";
import SitesGlobal from "../../../../util/data/sites.json";
import {IFactory, IRegion, ISite} from "../../../../util/varibles/interface";
import Header from "../../../../components/Popup/Component/Header";
import {planOpActions} from "../../reducer";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {propsSelect} from "../../../../util/varibles/template";
import {showErrorResponse} from "../../../../util/varibles/global";
import {SiteService} from "../../../../util/services/site";
import {RegionService} from "../../../../util/services/region";
import {AppState} from "../../../../util/store/store";

const generateSites = (sites: any) => sites.reduce((list: any, site: ISite) => {
    if (site.type === SITE_TYPE.EXTERNAL) {
        const {position, ...args} = site;
        const isExist = list.findIndex((item: any) => item.id === site.id)
        if (isExist !== -1) {
            list[isExist] = {...site};
            return list;
        }
        return [...list, {...args, ...position}]
    }
    return list
}, SitesGlobal);

const mapStateToProps = (state: AppState) => ({
    sites: state.planOperation.sites,
    factories: state.planOperation.factories,
});

interface IProps {
    visible?: boolean
    sites: ISite[]
    factories: IFactory[]

    onClose?(): void

    addExternalSite(payload: ISite): any
}

const PopupEditExternalSite: React.FC<IProps> = (props) => {
    const [sites, setSites] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const formRef = useRef<FormInstance>(null);
    const [visible, setVisible] = useState(props.visible);
    const [isCreate, setIsCreate] = useState(true);
    const [{regions, loadingRegions}, setRegions] = useState<{
        loadingRegions: boolean,
        regions: IRegion[]
    }>({loadingRegions: false, regions: []});

    useEffect(() => {
        setRegions({regions: [], loadingRegions: true});
        RegionService.gets()
            .then(rs => setRegions({regions: rs, loadingRegions: false}))
            .catch(error => {
                showErrorResponse('Get regions failed', error)
                setRegions({regions: [], loadingRegions: true});
            })
    }, []);

    useEffect(() => {
        setSites(generateSites(props.sites))
    }, [props.sites]);

    const handleSubmit = (values: any) => {
        const {site: siteId, latitude, longitude, units, regionId, primaryFactoryId, address, site_type} = values;
        const site: any = sites.find((item: any) => item.id === siteId);
        const {id, name, site_id = '-'} = site;
        const code = isCreate
            ? {success: NotifyCode.S1, failure: 'Create failure',}
            : {success: NotifyCode.S2, failure: 'Update failure',}
        const site_number = id.split('-')[1] || '';
        const params = {
            id,
            name,
            site_id,
            site_number,
            units: units.split(','),
            position: {
                latitude: Number(latitude),
                longitude: Number(longitude)
            },
            region_id: regionId,
            primary_factory_id: primaryFactoryId || '',
            address: address ? address.trim() : '-',
            site_type
        };
        setLoadingSubmit(true);
        new Promise((resolve) => resolve(SiteService.addExternal(params)))
            .then((rs: any) => {
                setLoadingSubmit(false);
                props.addExternalSite(rs);
                handleClose();
                notify.success(code.success)([rs.name]);
            })
            .catch((error) => {
                showErrorResponse(code.failure, error)
                setLoadingSubmit(false);
            })
    }

    const handleSelect = (value: any) => {
        const site: any = sites.find((item: any) => item.id === value);
        if (!site) return;
        const {
            address,
            latitude,
            longitude,
            position = {},
            primary_factory_id = null,
            region_id = null,
            units = [],
            site_type = FISH_TYPE.PRODUCTION
        } = site;
        const {factories = []} = props;
        const dataForm = {
            address,
            latitude: latitude ? latitude : position.latitude,
            longitude: longitude ? longitude : position.longitude,
            primaryFactoryId: primary_factory_id ? primary_factory_id : (factories.length > 0 ? factories[0].id : ''),
            regionId: region_id ? region_id : (regions.length > 0 ? regions[0].id : ''),
            units: units.map((item: any) => item.unit_id).join(','),
            site_type
        }
        setIsCreate(units.length === 0)
        if (formRef.current)
            formRef.current.setFieldsValue(dataForm);
    }

    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose();
    }

    const handleSearch = (input: any, option: any) => {
        return option.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
            || option.company_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    }

    const {factories} = props;

    return <Modal
        {...propsModal}
        open={visible}
        title={<Header title='Add external site, unit' onClose={handleClose}/>}
        onCancel={handleClose}
        width={460}
        styles={{body: {padding: 0}}}
    >
        <Form layout='vertical' ref={formRef} className={styles['form']} onFinish={handleSubmit}>
            <div className={styles['form-body']}>
                <Form.Item className='form-input' label="Site" name='site'
                           rules={[{required: true, message: `"Site" is required`}]}
                >
                    <Select
                        {...propsSelect}
                        showSearch
                        onSelect={handleSelect}
                        filterOption={handleSearch}
                        notFoundContent={null}
                        placeholder="Site"
                        options={sites.map((item: any) => ({
                            value: item.id,
                            label: <>
                                <span>{item.name}</span>
                                <span className='text-comment font-italic'>
                                    {item.company_name ? ` (${item.company_name})` : ''}
                                </span>
                            </>,
                            name: item.name || '',
                            company_name: item.company_name || ''
                        }))}
                    >
                    </Select>
                </Form.Item>
                <Form.Item className='form-input' label="Unit" name='units'
                           rules={[{required: true, message: `"Unit" is required`}]}
                >
                    <Input placeholder=""/>
                </Form.Item>
                <Form.Item
                    className='form-input' label="Site type" name='site_type'
                    rules={[{required: true, message: `"Site type" is required`}]}
                >
                    <Select options={fishTypes.map(item => ({value: item.id, label: item.name}))}/>
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item className='form-input' label="Site Latitude" name='latitude'
                                   rules={[{required: true, message: `"Site Latitude" is required`}]}
                        >
                            <Input placeholder=""/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='form-input' label="Site Longitude" name='longitude'
                                   rules={[{required: true, message: `"Site Longitude" is required`}]}
                        >
                            <Input placeholder=""/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item className='form-input' label="Region" name='regionId'
                                   rules={[{required: true, message: `"Region" is required`}]}
                        >
                            <Select
                                loading={loadingRegions}
                                options={regions.map(item => ({value: item.id, label: item.name}))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='form-input' label="Primary Factory" name='primaryFactoryId'>
                            <Select allowClear options={factories.map(item => ({value: item.id, label: item.name}))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='form-input' label="Contact number" name='address'>
                    <Input placeholder=""/>
                </Form.Item>
            </div>
            <div className={styles['form-footer']}>
                <Button
                    loading={loadingSubmit}
                    className='bt-primary h-40'
                    data-full='true'
                    htmlType="submit"
                >
                    {isCreate ? 'Add' : 'Update'}
                </Button>
            </div>
        </Form>
    </Modal>
};

export default connect(mapStateToProps, {
    addExternalSite: planOpActions.addExternalSite,
})(PopupEditExternalSite);
