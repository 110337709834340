import React, {useEffect, useRef} from 'react';
import styles from "../../style.module.scss";
import {OP_STATUS, OPERATION_TYPE} from "../../../../../../../../util/varibles/constants";
import {IActivityLog, IOperation, IPropsChildren} from "../../../../../../../../util/varibles/interface";
import {IPropsOp} from "../../index";
import {connect, useSelector} from "react-redux";
import {planOpActions} from "../../../../../../reducer";
import LoadingOperation from "./LoadingOperation";
import {EL_RULE} from "../../../../constants";
import {
    checkLimit,
    getElById,
    getStartFinishByOpMode,
    getWidthByClassName,
    removeClassName
} from "../../../../../../../../util/varibles/global";
import {
    callEventOperation,
    getOperation,
    setOperation,
    useOperation
} from "../../../../../../../../contexts/OperationContext";
import {callEventPlan, getPlan, setPlan, usePlan} from "../../../../../../../../contexts/PlanOperationContext/Plan";
import {createKeyActivityLog, handleScroll, HEIGHT_PER_DAY, updateTime} from '../../../constants';
import {calcWidth, checkStatus, getHeightOfOp, getNewTime} from "../constants";
import BannerOperation from "./BannerOperation";
import PopupMerge from "../../../../../../Popup/OpMerge";
import {LAYOUT_CALENDER} from "../../../../../../constants";
import stylesVessel from "../../../../Vessels/style.module.scss";
import {notification} from "antd";
import TimeLine from "../TimeLine";
import {openPopup} from "../../../../../../../../components/Popup/Component/WrapperPopup";
import {selectPlan} from "../../../../../../../../util/store/selectors";
import {store} from "../../../../../../../../util/store/store";
import stylesTime from '../TimeLine/style.module.scss';

interface ISizeOperation extends IPropsChildren {
    event?: any,
    operationType: OPERATION_TYPE,
    data: IOperation
}

export const SizeOperation: React.FC<ISizeOperation> = ({children, event, operationType, data}) => {
    const get = getOperation();
    const [level] = useOperation(state => state.level)
    const [width = 0] = useOperation(state => state.width)
    const [modeDrag] = useOperation(state => state.modeDrag);
    const [operation_mode] = usePlan(state => state.opMode);
    const [startPoint] = usePlan(state => state.startPoint);

    const [minHeight] = useOperation(state => state.minHeight);
    const [rootKey] = useOperation(state => state.rootKey);
    const [activityLogs] = useOperation(state => state.activityLogs);
    const [left] = useOperation(state => state.left);
    const [index] = useOperation(state => state.vesselIndex);

    const activityLog = activityLogs[rootKey] || data.activity_log;

    const {start, finish} = getStartFinishByOpMode[operation_mode](activityLog, data.operation.status);

    const height = (finish - start) / 86400000 * HEIGHT_PER_DAY;
    const top = `${(start - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;


    const adjust = level.ids.length > 0 ? calcWidth[`${level.main}`](level, width, index) : {};

    const isMin = height <= minHeight;
    const isHover = isMin && modeDrag !== 'move';
    const isHide = height < 28;

    const style = {
        '--min-height': minHeight + 'px',
        maxHeight: height + 'px',
        top,
        width: `calc(${width}% - 20px)`,
        left: `calc(${width * index}% + 10px)`,
        ...adjust
    };

    if (left)
        style.left = left + 'px'

    const {isOwn = false} = get();

    return <>
        <div
            {...event}
            className={styles.operation}
            data-rule={EL_RULE.OPERATION}
            style={style}
            data-is-own={isOwn}
            data-operation-type={operationType}
            data-mode-drag={modeDrag}
            data-hover={isHover}
            data-mini={isHide}
        >
            {children}
        </div>
        <TimeLine {...{
            isOwn,
            width,
            data,
            adjust,
            activityLog,
        }} />
    </>
}

let dragController: AbortController = new AbortController();
let intervalController: AbortController = new AbortController();
let intervalMove: ReturnType<typeof setInterval>;

interface IPropsWrapperOp extends IPropsChildren, IPropsOp {
    status: OP_STATUS,
    data: { activityLogRoot?: IActivityLog[] } & IOperation,
    operationType: OPERATION_TYPE,
    isOwn: boolean,

    event?: {
        onMouseOver?(e: React.MouseEvent): void
        onMouseLeave?(e: React.MouseEvent): void
    }

    edit?(): void,

    setFocusId(payload: string): void
}

const WrapperOp: React.FC<IPropsWrapperOp> = (props) => {
    const {operationType, data, event, edit} = props;
    const {operation, message, action_type, status: validationStatus} = props.data;
    const {id, status, parallel} = operation;
    const opFocusId = useSelector(selectPlan.opFocusId);

    const {setTarget} = callEventPlan();
    const {getActivityLog, resetState} = callEventOperation();
    const {openInfo} = callEventOperation();
    const setOp = setOperation();
    const getOp = getOperation();
    const setContainerOfOp = setPlan();
    const getContainerOfOp = getPlan();

    const rootRef = useRef<HTMLDivElement>(null);
    const pageRef = useRef<HTMLElement>();

    useEffect(() => {
        pageRef.current = getElById('container-plan');
        if (opFocusId === id && pageRef.current)
            pageRef.current.addEventListener("mousedown", handleClickOutside);

        setOp({
            minHeight: getHeightOfOp({element: rootRef.current}),
            isTimeline: id === opFocusId,
            rootRef: () => rootRef.current as HTMLDivElement
        })

        return () => {
            intervalController.abort();
            if (intervalMove)
                window.clearInterval(intervalMove);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            if (pageRef.current)
                pageRef.current.removeEventListener("mousedown", handleClickOutside);

            setContainerOfOp({isDrag: ''});
        }
    }, [id, setOp, setContainerOfOp]);

    const handleMouseDown = (e: any) => {
        const {isOwn = false} = getOp();
        if (edit && setTarget)
            setTarget({type: EL_RULE.OPERATION, data, isOwn, edit}, e);

        if (e.button === 2 || !rootRef.current)
            return

        e.preventDefault();
        e.stopPropagation();

        if (pageRef.current)
            pageRef.current.addEventListener("mousedown", handleClickOutside);

        intervalController.abort();

        setOp({vesselWidth: getWidthByClassName(stylesVessel.vessel)})
        const {calendarRef} = getContainerOfOp();
        if (calendarRef && calendarRef())
            calendarRef().dataset.modeDrag = `${operationType}`;

        setContainerOfOp({isDrag: 'true'});
        document.addEventListener('mouseup', handleMouseUp);
        const isStatusValid = checkStatus(status);
        const state: any = {modeDrag: 'down', isTimeline: true};
        const {loading} = getOp();
        if (isStatusValid && isOwn && !loading) {
            dragController = new AbortController();
            document.addEventListener('mousemove', handleMouseMove);
            const {offsetTop, offsetWidth} = rootRef.current;
            const {left, top} = rootRef.current.getBoundingClientRect();
            state.targetX = e.pageX - left;
            state.targetY = offsetTop + (e.pageY - top);
            state.maxWidth = (calendarRef && calendarRef())?.scrollWidth || 0 - offsetWidth;
            state.scrollHeight = (calendarRef && calendarRef())?.scrollHeight;

            const vessels = selectPlan.vessels(store.getState())
            state.vessels = vessels.filter(({isShow}) => isShow);
        }

        document.body.setAttribute('data-is-prevent-select', 'true');
        setOp(state)
    }

    const handleMouseMove = (e: any) => {
        window.clearInterval(intervalMove);
        if (!rootRef.current)
            return;

        const {pageX, pageY, target} = e;
        const {id: positionId = null, className = ''} = target || {};
        const isOperation = className.indexOf(styles.operation) !== -1;

        if (isOperation && positionId !== id) {
            rootRef.current.style.display = 'none'
            const {isTimeline, modeDrag} = getOp();
            if (isTimeline || modeDrag !== 'move')
                setOp({isTimeline: false, modeDrag: 'move'});
            return;
        }

        const state = {isTimeline: true, modeDrag: 'move'};
        rootRef.current.style.display = 'block';

        if (positionId !== LAYOUT_CALENDER) {
            resetState({isTimeline: true, modeDrag: 'move'});
            return;
        }

        const {targetX = 0, maxWidth = 0, activityLogs} = getOp();
        const {newStartTime, currentKey, key, vessel, isAction, x} = getNewTime({get: getOp, data, e});

        if (newStartTime === activityLogs[key][0].est_start_time) {
            setOp(state);
            return;
        }

        if (isAction) {
            getActivityLog({time: newStartTime, vessel, key, abort: dragController})
        }

        intervalMove = handleScroll({
            id: operation.id,
            target: rootRef.current,
            pageX,
            pageY,
            maxWidth,
            key,
            activityLogs,
            action: (activityLogs: any) => setOp({activityLogs}),
            clearInterval: () => window.clearInterval(intervalMove)
        });

        const targetLeft = checkLimit(10, maxWidth, x - targetX);
        setOp({
            ...state,
            left: targetLeft,
            rootKey: key,
            currentKey,
            activityLogs: {...activityLogs, [key]: updateTime(activityLogs[key], 0, newStartTime)}
        });
    }

    const handleMouseUp = (e: any) => {
        if (!rootRef.current)
            return;

        const {calendarRef} = getContainerOfOp();
        if (calendarRef && calendarRef())
            calendarRef().dataset.modeDrag = '';

        setContainerOfOp({isDrag: ''});
        document.body.setAttribute('data-is-prevent-select', 'false');
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        const {modeDrag, rootKey} = getOp();

        if (modeDrag !== 'move') {
            rootRef.current.style.display = 'block';
            openInfo()
        } else {
            if (intervalMove)
                window.clearInterval(intervalMove);

            setOp({modeDrag: ''});
            if (dragController)
                dragController.abort();

            const {pageX, pageY, target} = e;
            const {id: positionId = null, className = ''} = target || {}
            const isOperation = className.indexOf(styles.operation) !== -1;
            if (isOperation) {
                handleMerge(e);
                return;
            } else if (positionId !== LAYOUT_CALENDER) {
                resetState({isTimeline: true});
                return;
            }

            const {newStartTime, currentKey, vessel, vesselIndex, error} = getNewTime({get: getOp, data, e});
            if (error.length > 0) {
                if (error !== '-')
                    notification.error({message: 'Error', description: error});
                resetState({});
                return;
            }

            let available_time_id: any = '-', contract_id: any = '-';
            if (!vessel.isOwn) {
                available_time_id = operation.available_time_id;
                contract_id = operation.contract_id;
            }
            rootRef.current.style.display = 'none';
            let location = document.elementFromPoint(pageX, pageY) as HTMLElement;
            if (location) {
                const {rule, availableTimeId, contractId} = location.dataset;
                if (rule === EL_RULE.AVAILABLE_TIME)
                    available_time_id = availableTimeId
                else if (rule === EL_RULE.CONTRACT)
                    contract_id = contractId;
            }
            rootRef.current.style.display = 'block';

            const valueNew = {
                operation: {
                    ...operation,
                    contract_id,
                    available_time_id,
                    vessel_id: vessel.id,
                },
                vessel
            }

            if (operationType !== OPERATION_TYPE.EVENT && rootKey !== currentKey) {
                setOp({left: undefined, vesselIndex: vesselIndex})
                getActivityLog({
                    time: newStartTime,
                    vessel,
                    success: (ops) => {
                        const key = createKeyActivityLog(ops[id].operation, vessel);
                        const activityLogNew = updateTime(ops[id].activity_log, 0, newStartTime);
                        const op = {
                            ...valueNew,
                            operation: ops[id].operation,
                            activity_log: activityLogNew,
                            activityLogRoot: {key, activity_log: activityLogNew},
                        };
                        op.operation.duration = activityLogNew[activityLogNew.length - 1].est_start_time - activityLogNew[0].est_start_time;

                        updateOpEffect(op)
                    },
                })
            } else {
                const {activityLogs} = getOp();
                const activity_log = updateTime(activityLogs[currentKey] || [], 0, newStartTime);
                const op = {...valueNew, activity_log, vessel};
                setOp({left: undefined, modeDrag: ''});
                updateOpEffect(op)
            }
        }
    }

    const updateOpEffect = (new_operation: IOperation) => {
        const {onDragOp} = getContainerOfOp();
        onDragOp(new_operation);
    }

    const handleClickOutside = (e: any) => {
        if (e.target.closest(`.${stylesTime.timeline}`))
            return;

        if (rootRef.current && !rootRef.current.contains(e.target)
            && !e.target.closest('.ant-dropdown')) {

            setOp({isTimeline: false});
            if (opFocusId === id)
                props.setFocusId('');

            document.removeEventListener("mousedown", handleClickOutside);
        }
    }

    const handleMerge = (e: any) => {
        const {target} = e;
        const {id} = target;
        removeClassName(target, styles['merge-hover']);
        const cancel = () => {
            resetState({isTimeline: true});
        }

        if (id === operation.id) {
            cancel();
            return;
        }

        const modalMerge = openPopup(<PopupMerge {...{
            idTarget: id,
            opDrag: data,
            onOk: () => {
                if (rootRef.current)
                    rootRef.current.style.display = 'block';
                setOp({isTimeline: true})
            },
            onClose: () => {
                modalMerge.remove();
                if (rootRef.current)
                    rootRef.current.style.display = 'block';
                cancel();
            }
        }}/>)
    }

    const common = {
        ...event || {},
        id,
        ref: rootRef,
        'data-status': status,
        'data-operation-id': id,
        'data-editing': !!action_type,
        'data-operation-status': validationStatus,
        'data-parallel': parallel,
        onMouseDown: handleMouseDown,
    };

    return <SizeOperation operationType={operationType} event={common} data={data}>
        <BannerOperation operation_code={operation.operation_code} status={status} operation_type={operationType}/>
        <LoadingOperation id={id} message={message}/>
        {props.children}
    </SizeOperation>;
};

export default connect(() => ({}), {
    setFocusId: planOpActions.setFocusId,
    dragOperation: planOpActions.dragOperation
})(WrapperOp);

