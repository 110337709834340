import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {Drawer} from "antd";
import styles from './style.module.scss';
import {propsModal} from "../../../util/varibles/constants";
import {IActivityLog, IOperation, IRoute} from "../../../util/varibles/interface";
import {useDispatch} from "react-redux";
import Header from "../Component/Header";
import Duration from "./Duration";
import Footer from "../Component/Footer";
import {RouteService} from "../../../util/services/route";
import {showErrorResponse} from "../../../util/varibles/global";
import {destinationKeys, sourceKeys} from "../../../pages/PlanOperationPage/util";
import {planOpActions} from "../../../pages/PlanOperationPage/reducer";
import {OpService} from "../../../util/services/operation";
import {WorldMapProvider} from "../../../contexts/WorldMapContext";
import Route from "./Route";
import {MAP_ELEMENT} from "../../../contexts/WorldMapContext/constants";
import {FactoryMarker, IPropsMarker, SiteMarker} from "../../../contexts/WorldMapContext/Marker";

export type TRouteExtend = {
    activity_log?: IActivityLog[],
    activityLogRoot?: IActivityLog[],
    points: { lat: number, lng: number }[]
    error?: any,
    index: number
} & IRoute

export interface ILocationEditDuration {
    name: string,
    nameText: string,
    node?: ReactNode
}

interface IProps {
    data: IOperation
    isSave?: boolean

    onSave(data: IOperation): void

    onClose(): void
}

let controller = new AbortController();
const views: { [id: string]: React.FC<IPropsMarker> } = {
    [MAP_ELEMENT.SITE]: SiteMarker,
    [MAP_ELEMENT.FACTORY]: FactoryMarker,
}

const PopupEditDuration: React.FC<IProps> = (props) => {
    const {data, isSave, onSave, onClose} = props;
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingRoute, setLoadingRoute] = useState(false);
    const [loadingDuration, setLoadingDuration] = useState(false);
    const [routeId, setRouteId] = useState('');
    const [routes, setRoutes] = useState<{ [id: string]: TRouteExtend }>({});
    const [locations, setLocations] = useState<ILocationEditDuration[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const {operation_type, router_id = ''} = data.operation;
        const {
            key: sourceKey = '',
            name: sourceKeyName = '',
            map_id: sourceKeyMap = '',
            nameText: sourceNameText = ''
        } = sourceKeys[operation_type];
        const {
            key: destinationKey = '',
            name: destinationKeyName = '',
            map_id: destinationKeyMap = '',
            nameText: destinationNameText = ''
        } = destinationKeys[operation_type];
        const {
            [sourceKey]: sourceId,
            [sourceKeyName]: sourceName,
            [destinationKey]: destinationId,
            [destinationKeyName]: destinationName,
        }: any = data.operation;

        setLoadingRoute(true);
        const locationA: ILocationEditDuration = {name: sourceName, nameText: sourceNameText};
        const locationB: ILocationEditDuration = {name: destinationName, nameText: destinationNameText};
        setLocations([locationA, locationB])
        RouteService.getByLocation(sourceId, destinationId)
            .then(response => {
                const list = response.reduce((rs: any, item, index: number) => {
                    const {id, points} = item;
                    const selected = id === router_id;
                    const value = {
                        ...item,
                        index,
                        points: points.map(point => ({lat: point.latitude, lng: point.longitude}))
                    }
                    if (selected)
                        rs[id] = {...value, activity_log: data.activity_log, activityLogRoot: data.activity_log}
                    else {
                        rs[id] = value
                    }
                    return rs;
                }, {})
                setRoutes(list)
                setRouteId(router_id);
                const {points = []} = list[router_id] || {};
                const SourceView = views[sourceKeyMap];
                const DestinationView = views[destinationKeyMap];
                setLocations([
                    {
                        ...locationA,
                        node: <SourceView
                            data={{name: sourceName, position: {latitude: points[0].lat, longitude: points[0].lng}}}
                        />
                    },
                    {
                        ...locationB,
                        node: <DestinationView data={{
                            name: sourceName,
                            position: {
                                latitude: points[points.length - 1].lat,
                                longitude: points[points.length - 1].lng
                            }
                        }}/>
                    }
                ])

                setLoadingRoute(false);
            })
            .catch(error => {
                setLoadingRoute(false);
                showErrorResponse('Get routes failed', error);
            })

        return () => {
            if (controller)
                controller.abort();
            onClose();
        };
    }, [data]);

    const handleClose = useCallback(() => {
        setVisible(false);
        onClose();
    }, [onClose])

    const handleChangeActivityLog = (activity_log: IActivityLog[]) => {
        setRoutes({
            ...routes,
            [routeId]: {...routes[routeId], activity_log}
        })
    };

    const handleChangeRoute = (key: string) => {
        const route = routes[key];
        setRouteId(key);
        const {activity_log = []} = route;

        if (activity_log.length === 0 && !loadingDuration) {
            setLoadingDuration(true);
            const {id} = data.operation;
            dispatch(planOpActions.getActivityLog({
                source: [{...data, operation: {...data.operation, router_id: key}}],
                properties: {
                    [id]: {start: data.activity_log[0].est_start_time}
                },
                success: (ops: any) => {
                    setLoadingDuration(false);
                    const {activity_log} = ops[id];
                    setRoutes({...routes, [key]: {...route, activity_log, activityLogRoot: activity_log}})
                },
                failure: (error: any) => {
                    setLoadingDuration(false);
                    showErrorResponse('Get duration failed', error);
                }
            }))
        }
    }

    const handleSave = useCallback(() => {
        const {activity_log = []} = routes[routeId];
        const newValue = {
            ...data,
            operation: {...data.operation, router_id: routeId},
            activity_log
        };
        if (isSave) {
            setLoading(true);
            OpService.updateActivityLog({router_id: routeId, activity_log}, data.operation.id)
                .then(() => {
                    setLoading(false);
                    onSave(newValue);
                    handleClose();
                })
                .catch(error => {
                    showErrorResponse('Update failed', error);
                    setLoading(false);
                })
        } else {
            onSave(newValue)
            handleClose();
        }
    }, [routes, routeId, data, isSave, onSave, handleClose]);

    return <Drawer
        {...propsModal}
        className={styles.popup}
        title={<Header title='Route & Duration' onClose={handleClose}/>}
        placement="right"
        width='100%'
        open={visible}
        onClose={handleClose}
    >
        <WorldMapProvider>
            <div className={styles.content}>
                <Route key='route' {...{
                    routes,
                    routeId,
                    locations,
                    loading: loadingRoute || loadingDuration,
                    setRoute: handleChangeRoute
                }}/>
                <div className={styles.duration}>
                    <Duration key='duration' {...{
                        data,
                        route: routes[routeId],
                        locations,
                        loading: loadingRoute || loadingDuration,
                        updateActivityLog: handleChangeActivityLog
                    }}/>
                    <Footer
                        className={styles.footer}
                        cancel={{click: handleClose}}
                        ok={{
                            loading: loading || loadingRoute || loadingDuration,
                            click: handleSave
                        }}
                    />
                </div>
            </div>
        </WorldMapProvider>
    </Drawer>
};

export default PopupEditDuration;
